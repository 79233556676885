import React from "react";
import classNames from "classnames";
import queryString from "query-string";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { Progress, Button } from "reactstrap";
// import { default as Title } from "components/Title";
import { default as ResultAnswers } from "components/ResultAnswers";

import AMES247Loading from "components/Error/Loading";
import LogAssi from "./LogAssi";
import NotData from "components/Error/NotData";
import { default as functions } from "components/functions";

import * as ActionTypes from "./actions/types";
import { resultAudio } from "components/functions";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      log: {},
      activeLog: "",
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
      results: [],
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.results !== state.results) {
      return {
        results: props.results,
      };
    }
    return null;
  };

  componentDidMount = () => {
    this.getResultAssignment();
    resultAudio(3);
  };

  getResultAssignment = () => {
    let { getResult, match, location } = this.props;
    let { loggedInUserSGK } = this.state;
    let studentId = loggedInUserSGK.studentId;
    let { assignmentId, sessionId } = match.params;
    let { takeExamtime } = queryString.parse(location.search);
    if (!assignmentId || !sessionId || !takeExamtime || !studentId) return;
    getResult(studentId, sessionId, assignmentId, takeExamtime);
  };

  goBack = () => {
    let { match } = this.props;
    let { classId, sessionId } = match.params;
    let pathname = `/sgk/classes/${classId}/session/${sessionId}/assignments`;

    return (
      <Button
        // htmlType="submit"
        size="sm"
        color="primary"
        onClick={() => this.props.history.push(pathname)}
      >
        <span className="btn-inner--icon mr-2">
          <i className="ni ni-bold-left" />
        </span>
        <span className="btn-inner--text">Quay lại</span>
      </Button>
      // <Link className={"ames__btn"} to={{ pathname }}>
      //   {`Quay lại`}
      // </Link>
    );
  };

  goAssignmentNew = () => {
    let newAsm = null;
    let { match, assignments } = this.props;
    let { classId, sessionId, assignmentId } = match.params;
    if (assignments.data) {
      let { data } = assignments;
      if (data.length > 0) {
        let iAsm = data.findIndex((a) => a.id === assignmentId);
        if (iAsm > -1) {
          iAsm++;
          if (iAsm < data.length) {
            newAsm = data[iAsm];
          } else {
            return null;
          }
        }
      } else {
        return;
      }
    }

    const takeExamTime = functions.uuid();

    let search = `?type=${newAsm.questionType}&asrId=${newAsm.asrId}&takeExamTime=${takeExamTime}`;
    let pathname = `/sgk/classes/${classId}/session/${sessionId}/assignment/${newAsm.id}/questions${search}`;
    return (
      <Button
        size="sm"
        // htmlType="submit"
        color="primary"
        onClick={() => this.props.history.push(pathname)}
      >
        <span className="btn-inner--icon mr-2">
          <i className="ni ni-bold-right" />
        </span>
        <span className="btn-inner--text">Tiếp theo</span>
      </Button>
      // <Link to={{ pathname, search }} className={"ames__btn"}>
      //   {`Tiếp tục`}
      // </Link>
    );
  };

  render = () => {
    let { transmitted, loading, location } = this.props;
    let { results } = this.state;

    let { length: qTotal, type } = queryString.parse(window.location.search);
    const isHide =
      type === "ListenAndRepeat" ||
      type === "RepeatTheSentence" ||
      type === "RepeatTheWords";
    let aTotal = transmitted.length;
    let aCorrect = transmitted.filter((a) => a.isCorrect === true).length;
    let aIncorrect = aTotal - aCorrect;
    let progress = (aTotal * 100) / qTotal;
    let percent = (aCorrect * 100) / qTotal;

    let _ = {
      progress,
      percent,
      aCorrect,
      aIncorrect,
      aTotal,
      qTotal,
      results,
    };

    if (loading) {
      return <AMES247Loading />;
    }
    if (results?.length === 0) {
      return <NotData />;
    }
    return (
      <React.Fragment>
        <div
          style={{ paddingTop: 20 }}
          className={classNames(["ames__result-progress"])}
        >
          {/* PROCCESS */}
          <div className="progress-wrapper">
            <div className="progress-info">
              <div className="progress-label">
                <span>Tiến độ hoàn thành</span>
              </div>
              <div className="progress-percentage">
                <span>100%</span>
              </div>
            </div>
            <Progress max="100" value="100" color="success" />
          </div>
          {!isHide ? (
            <>
              <h3 className={classNames(["result"])}>Kết quả</h3>
              <ResultAnswers results={this.state.results} />
            </>
          ) : (
            <div style={{ height: 30 }}></div>
          )}
        </div>
        <div className={classNames(["text-align_center"])}>
          {this.goBack()}
          {this.goAssignmentNew()}
        </div>
        <p style={{ margin: "25px" }}></p>
        <Row>
          <Col span={20} offset={2}>
            <LogAssi results={results} location={location} />
          </Col>
        </Row>
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => ({
  transmitted: state.saveAnswerReducer,
  assignments: state.assignmentReducer,
  results: state.resultReducer.results,
  loading: state.resultReducer.loading,
  // selectedClass: state.ReduxReducer.selectedClass,
});

const mapDispatchToProps = (dispatch) => ({
  getResult: (studentId, sessionId, assignmentId, takeExamtime) =>
    dispatch({
      type: ActionTypes.GET_RESULT,
      studentId,
      sessionId,
      assignmentId,
      takeExamTime: takeExamtime,
    }),
  // selectClass: item => dispatch({ type: ActionTypes.QUESTION_SELECT_CLASS, selectedClass: item }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
