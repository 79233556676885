import React, { Component } from 'react';
import classNames from "classnames";

import * as ActionTypes from "../actions/types";
import { connect } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import * as __typeText from "modules/QuestionModule/text";
import { Row, Col } from "antd";
import { default as functions } from "components/functions";
import { default as NotData } from 'components/Error/NotData';
import Drop from './Drop'

const update = require("immutability-helper");

class Type15 extends Component {

  constructor() {
    super();

    this.arrayQuestionText = [];

    this.arraysound = [];
    this.arrayimage = [];
    this.arraytext = [];
    this.arrayquestion = [];
    this.Arrow = [];

    this.state = {
      styleImage: {
        width: null
      },
      dotFirst: undefined,
      dotSecord: undefined,
      answer: [],
      question: {},
      questions: [],
      nameFirst: '',
      nameSecond: '',
      arraytext: [],
      arrayimage: [],
      arraysound: [],
      next: false,
      cards: this.arrayimage,
      timeStart: null,
      loggedInUserSGK : JSON.parse(localStorage.getItem("loggedInUserSGK")),
    };

    this.i = 0;
    this.j = 4;

    this.disabledNext = true;
    this.refFirst = [];
    this.refSecond = [];
    this.questionIndex = 0;
  }

  moveCard = (dragIndex, hoverIndex) => {
    if (this.state.next === false) {
      const { cards } = this.state;
      const dragCard = cards[dragIndex];

      this.setState(
        update(this.state, {
          cards: {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
          }
        })
      );
    }
  };

  getFourQuestion = (questions, index) => {
    let { i, j } = this;

    if (this.props.questions.length < 4) {
      j = this.props.questions.length;
    }
    if (index === this.arrayquestion.length - 1) {
      for (i; i < j; i++) {
        this.arrayquestion[index].ques.push(questions[i])
      }
    }
    else {
      for (i; i < j; i++) {
        this.arrayquestion[index].ques.push(questions[i])
      }
    }
    return this.arrayquestion;
  }

  componentDidMount() {
    if (this.props.questions.length < 4) {
      this.arrayquestion.push({ ques: [] })
    }
    else {
      let maxquestion = this.props.questions.length / 4;
      for (let i = 0; i < maxquestion; i++) {
        this.arrayquestion.push({ ques: [] })
      }
    }
    let questions = this.getFourQuestion(this.props.questions, this.questionIndex)
    this.setState({
      questions,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });

    for (let i = 0; i < questions[this.questionIndex].ques.length; i++) {
      this.arraytext.push({
        text: questions[0].ques[i].text,
        imageUrl: questions[0].ques[i].imageUrl,
      });
      this.arrayimage.push({
        text: questions[0].ques[i].text,
        imageUrl: questions[0].ques[i].imageUrl,
      });
      this.arraysound.push(questions[0].ques[i]);
      this.Arrow.push({ color: "#002140" });
    }

    functions.getRandomArray(this.arraytext);
    functions.getRandomArray(this.arrayimage);
    functions.getRandomArray(this.arraysound);
    this.setState({ arraytext: this.arraytext, arrayimage: this.arrayimage, arraysound: this.arraysound })
  }

  onLoadImage = ({ target: img }) => {
    if (img.offsetWidth > 400) {
      this.setState({
        styleImage: {
          width: "380px"
        }
      });
    }
  };

  onNext = async () => {

    // postAnswerToAPI()
    await this._postAnswerToAPI();
    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);

  };

  checkAnswer = arrayAnswer => {
    let { saveAnswers } = this.props;
    arrayAnswer.map((item, index) => {
      if (item === true) {
        const payload = { key: (this.questionIndex * arrayAnswer.length + index), isCorrect: true, score: 100, type: "" }
        saveAnswers(payload);
      } else {
        const payload = { key: (this.questionIndex * arrayAnswer.length + index), isCorrect: false, score: 0, type: "" }
        saveAnswers(payload);
      }
    })
  };

  checkCompletedOrNo = () => {
    let { allProps, takeExamTime } = this.props;
    let questions = this.arrayquestion;
    if (this.questionIndex + 1 === questions.length) {
    
      let { assignmentId, sessionId,classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);


      allProps.history.push(`/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`);
      return true;
    }
    return false;
  };

  _postAnswerToAPI = async () => {
    let { question, choosedAnswer,loggedInUserSGK } = this.state;

    let { allProps } = this.props;
    const studentId = loggedInUserSGK.id
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: question.ques[0].id,
      questionGuid: "", //empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type15),
      notes: "",
      takeExamTime: this.props.takeExamTime,
      studentChoice: JSON.stringify({
        score: 100,
        pairs: {
          left: this.arraytext,
          right: this.arraytext,
        }
      }),
      duration,
    };
    const { data } = await functions
      .postAnswerToAPI(answerModel)
      .then(v => { return v })
      .catch(err => {
        console.log(err);
      });
  };

  moveOnToAnotherQuestion = ix => {
    this.setState({
      next: false,
      choosedAnswer: undefined,
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });

    if (ix === this.arrayquestion.length - 1) {
      let ExcessQuestion = this.props.questions.length % 4;
      if (ExcessQuestion > 0) {
        this.i += 4;
        this.j += ExcessQuestion;
      }
      else {
        this.i += 4;
        this.j += 4;
      }
    }
    else {
      this.i += 4;
      this.j += 4;
    }

    let questions = this.getFourQuestion(this.props.questions, ix)
    this.arraytext = [];
    this.arrayimage = [];
    this.arraysound = [];
    this.arrayQuestionText = [];
    this.Arrow = [];
    this.setState({ answer: [] })

    let ExcessQuestion = this.props.questions.length % 4;
    if (ExcessQuestion > 0) {
      if (ix === this.arrayquestion.length - 1) {
        for (let i = 0; i < ExcessQuestion; i++) {
          this.arraytext.push({
            text: questions[ix].ques[i].text,
            imageUrl: questions[ix].ques[i].imageUrl,
          });
          this.arrayimage.push({
            text: questions[ix].ques[i].text,
            imageUrl: questions[ix].ques[i].imageUrl,
          });
          this.arraysound.push(questions[ix].ques[i]);
          this.Arrow.push({ color: "#002140" })
        }
      }
      else {
        for (let i = 0; i <= 3; i++) {
          this.arraytext.push({
            text: questions[ix].ques[i].text,
            imageUrl: questions[ix].ques[i].imageUrl,
          });
          this.arrayimage.push({
            text: questions[ix].ques[i].text,
            imageUrl: questions[ix].ques[i].imageUrl,
          });
          this.arraysound.push(questions[ix].ques[i]);
          this.Arrow.push({ color: "#002140" })
        }
      }

    }
    else {
      for (let i = 0; i <= 3; i++) {
        this.arraytext.push({
          text: questions[ix].ques[i].text,
          imageUrl: questions[ix].ques[i].imageUrl,
        });
        this.arrayimage.push({
          text: questions[ix].ques[i].text,
          imageUrl: questions[ix].ques[i].imageUrl,
        });
        this.arraysound.push(questions[ix].ques[i]);
        this.Arrow.push({ color: "#002140" })
      }
    }


    functions.getRandomArray(this.arraytext);
    functions.getRandomArray(this.arrayimage);
    functions.getRandomArray(this.arraysound);
    this.setState({ cards: this.arrayimage })
    this.setState({ arraytext: this.arraytext, arrayimage: this.arrayimage, arraysound: this.arraysound })
  };

  handleCheckAnswer = (text) => {
    const { cards: prevCards } = this.state;
    const cards = prevCards.filter(x => x.text !== text);
    if (cards.length > 0) {
      this.setState({ cards });
    } else {
      this.onNext();
    }
  }


  render() {

    let { answer, arraytext, arrayimage, arraysound, cards } = this.state;

    if (this.props.questions.length === 0) {
      return <NotData />;
    }

    return (
      <Row className={classNames(["question-type1"])} style={{ display: "block" }}>
        <Col   >
          <div style={{display:'flex',justifyContent:'center' ,alignItems:'center'}}>
          <Drop
            arrMove={cards}
            arrText={arraytext}
            handleCheckAnswer={this.handleCheckAnswer}
          />
          </div>
        </Col>

      </Row>
    );
  }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Type15);