import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import * as __typeText from "../text";
import { default as functions } from "components/functions";
import queryString from "query-string";
import moment from "moment";
import { Row } from "antd";
import * as ActionTypes from "../actions/types";
import AMES247Loading from "components/Error/Loading";
import AMES247Button from "components/Button";
import NotData from "components/Error/NotData";
import openNotificationWithIcon from "components/Notification";

const A_Z = "abcdefghijklmnopqrstuvwxyz";

class Type18 extends React.Component {
  constructor(props) {
    super(props);
    this.resultAnswer = "";
    this.correctText = "";

    let alphabet = [],
      characters = [];

    this.state = {
      loading: true,
      question: undefined,
      questions: props.questions ? props.questions : [],
      styleImage: {
        width: null,
      },
      characters,
      alphabet,
      correctText: this.correctText,
      timeStart: null,
    };

    this.show = "";
    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
  }

  getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  getCharacters = (data) => {
    // { id: 1, selectedId: 0, character: 't', fixed: true, status: 'on' },
    let characters = [];
    for (let i = 0; i < data.length; i++) {
      characters.push({
        id: i,
        selectedId: 0,
        character: data[i],
        fixed: true,
        status: "on",
      });
    }

    // random hide
    let maxRandom = this.getRandomInt(2, characters.length - 1);
    for (let i = 0; i < maxRandom; i++) {
      let randomIndex = this.getRandomInt(0, characters.length - 1);
      while (
        characters[randomIndex].status == "off" &&
        characters[randomIndex].fixed == false
      ) {
        randomIndex = this.getRandomInt(0, characters.length - 1);
      }
      characters[randomIndex].status = "off";
      characters[randomIndex].fixed = false;
    }

    let alphabet = this.getAlphabet(characters.slice());

    characters = characters.map((item, index) => {
      if (item.status == "off" && item.fixed == false) {
        return { ...item, character: "" };
      }
      return item;
    });

    return { characters, alphabet };
  };

  getAlphabet = (data) => {
    let alphabet = [];

    // get invisible words
    for (const iterator of data) {
      if (iterator.status == "off" && iterator.fixed == false) {
        alphabet.push({ ...iterator, status: "on" });
      }
    }

    //get more characters
    if (this.props.type != "CompleteWord_A") {
      for (let i = alphabet.length; i < data.length; i++) {
        let randomIndex = this.getRandomInt(0, A_Z.length - 1);
        alphabet.push({ character: A_Z[randomIndex], status: "on" });
      }
    }
    // random
    alphabet.forEach(() => {
      let firstRandomIndex = this.getRandomInt(0, alphabet.length - 1);
      let secondRandomIndex = this.getRandomInt(0, alphabet.length - 1);
      let tmp = { ...alphabet[firstRandomIndex] };
      alphabet[firstRandomIndex] = alphabet[secondRandomIndex];
      alphabet[secondRandomIndex] = tmp;
    });
    // sort index
    alphabet.forEach((_, index) => {
      alphabet[index].id = index;
    });
    return alphabet;
  };

  removeCharacter(item) {
    if (item.fixed === false) {
      const characters = this.state.characters.slice();
      const index = characters.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        // UPDATE ALPHABET
        const alphabet = this.state.alphabet.slice();
        const indexAlphabet = alphabet.findIndex(
          (x) => x.id === characters[index].selectedId
        );
        if (indexAlphabet >= 0) {
          alphabet[indexAlphabet].status = "on";
          this.setState({ alphabet, alphabet });
        }

        // UPDATE CHARACTERS
        characters[index].status = "off";
        characters[index].character = "";
        characters[index].selectedId = 0;
        this.setState({ characters });
        this.show = this.state.characters.map((x) => x.character).join("");
      }
    }
  }

  addCharacter(item) {
    // if (
    //   this.state.characters.filter(x => x.character !== "").length ===
    //   this.correctText.length
    // ) {
    //   return;
    // }

    const alphabet = this.state.alphabet.slice();
    const indexAlphabet = alphabet.findIndex((x) => x.id === item.id);
    if (indexAlphabet >= 0) {
      alphabet[indexAlphabet].status = "off";
      this.setState({ alphabet, alphabet });

      var characters = this.state.characters.slice();
      const index = characters.findIndex((x) => x.status === "off");
      if (index >= 0) {
        characters[index].status = "on";
        characters[index].character = item.character;
        characters[index].selectedId = item.id;
        this.setState({ characters, characters });
        this.show = this.state.characters.map((x) => x.character).join("");
      }
    }
  }

  componentWillMount = () => {
    this.setState({
      question: this.state.questions[0],
      loading: false,
      timeStart: moment().format(),
    });

    let tmpData = this.getCharacters(this.state.questions[0].text);
    let { alphabet, characters } = tmpData;
    this.setState({ characters, alphabet });
  };

  onLoadImage = ({ target: img }) => {
    if (img.offsetWidth > 490) {
      this.setState({
        styleImage: {
          width: "100%",
        },
      });
    }
  };

  onNext = () => {
    this.resultAnswer = this.state.characters.map((x) => x.character).join("");

    // check answer true or false
    this.resultAnswer === this.state.question.text
      ? this.checkAnswer(true)
      : this.checkAnswer(false);

    // post answer to api
    this._postAnswerToAPI({ text: this.state.characters });

    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
    if (this.questionIndex !== 0) {
      this.setState({ disabledBack: false });
    }
  };

  checkAnswer = (isCorrect) => {
    let { saveAnswers } = this.props;
    if (isCorrect === true) {
      const payload = {
        key: this.questionIndex,
        isCorrect,
        score: 100,
        type: "",
      }
      saveAnswers(payload);
      openNotificationWithIcon(
        "success",
        "Chính xác",
        "Chúc mừng bạn đã trả lời đúng"
      );
    } else {
      const payload = { key: this.questionIndex, isCorrect, score: 0, type: "" }
      saveAnswers(payload);
      openNotificationWithIcon(
        "danger",
        "Không chính xác",
        "Vui lòng kiểm tra lại kết quả"
      );
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  moveOnToAnotherQuestion = (ix) => {
    this.setState({
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });

    let tmpData = this.getCharacters(this.state.questions[ix].text);
    let { alphabet, characters } = tmpData;
    this.setState({ characters, alphabet });
    this.show = "";
  };

  _postAnswerToAPI = ({ text }) => {
    let { question } = this.state;
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: question.id,
      questionGuid: "", // empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type18),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({ text }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { loading, question, alphabet, characters, disabled } = this.state;

    if (this.props.questions.length === 0) {
      return <NotData />;
    }
    this.show = characters.map((x) => x.character).join("");
    let isDisabled =
      characters.filter((x) => x.character !== "").length !==
      question.text.length;

    var char = characters.map((item, index) => {
      return (
        <span
          key={index}
          className={
            item.fixed === false
              ? item.character == ""
                ? classNames(["question-type__btnMove-question"])
                : classNames(["question-type__btnMove-question1"])
              : classNames(["question-type__btnMove"])
          }
          style={item.fixed === false ? { cursor: "pointer" } : { cursor: "" }}
          onClick={() => {
            if (item.status === "on") {
              this.removeCharacter(item);
            }
          }}
        >
          {item.character}
        </span>
      );
    });

    var alp = alphabet.map((item, index) => {
      return item.status === "on" ? (
        <span
          key={index}
          className={classNames(["question-type__btnMove"])}
          style={{ cursor: "pointer" }}
          onClick={
            isDisabled === true
              ? () => {
                  if (item.status == "on") {
                    this.addCharacter(item);
                  }
                }
              : () => {}
          }
        >
          {item.character}
        </span>
      ) : (
        <span />
      );
    });

    if (loading) {
      return <AMES247Loading />;
    } else {
      return (
        <React.Fragment>
          <Row className={classNames(["question-type1"])}>
            <img
              src={question.imageUrl}
              style={this.state.styleImage}
              onLoad={this.onLoadImage}
              className={classNames(["question-type__image mt-15 mb-15"])}
            />
            <p className={classNames(["question-type__word"])}>
              {question.description}
            </p>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Row className={classNames(["mt-15 mb-15"])}>{char}</Row>
              <p className={classNames(["question-type__show"])}>{this.show}</p>
              <hr style={{ border: "1px solid #b1b7d2", width: 300 }} />
              <Row className={classNames(["mb-15 mt-15"])}>{alp}</Row>
            </Row>
          </Row>
          <Row className={classNames(["question-type__actions"])}>
            <AMES247Button
              value={"Submit"}
              disabled={isDisabled}
              onClick={this.onNext}
            />
          </Row>
        </React.Fragment>
      );
    }
  }
}

Type18.propTypes = {};

const mapStateToProps = (state) => ({
  exerciseCountdowns: state.AMES247SaveAnswerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type18);
