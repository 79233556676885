/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  NavbarBrand,
} from "reactstrap";
import { Link } from "react-router-dom";
class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.tony-woo.com?ref=adpr-auth-footer"
                  >
                    SGK Tiếng Anh
                  </a>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink href="#">SGK</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">About Us</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">Blog</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">License</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
