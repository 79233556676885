import React from 'react'
import { DragSource } from 'react-dnd'
import classNames from "classnames";
const style = {
  border: '3px solid gray',
  backgroundColor: 'white',
  // padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
  width: 200,
  height: 200,
}
export const Box = ({ name, isDropped, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0.4 : 1
  return connectDragSource(
    <div style={{ ...style, opacity }}>
      <img
        alt='...'
        src={name}
        className={classNames(["question-type__boxImage"])}
        style={{ margin: 0, width: 194, height: 194 }}
      />
    </div>,
  )
}
export default DragSource(
  (props) => props.type,
  {
    beginDrag: (props) => ({ name: props.name }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
