export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const FETCH_QUESTIONS_REQUEST = "FETCH_QUESTIONS_REQUEST";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";

export const FETCH_QUESTIONS_TYPE35 = "FETCH_QUESTIONS_TYPE35";
export const FETCH_QUESTIONS_TYPE35_REQUEST = "FETCH_QUESTIONS_TYPE35_REQUEST";
export const FETCH_QUESTIONS_TYPE35_SUCCESS = "FETCH_QUESTIONS_TYPE35_SUCCESS";
export const FETCH_QUESTIONS_TYPE35_FAILURE = "FETCH_QUESTIONS_TYPE35_FAILURE";

export const FETCH_QUESTIONS_NEW = "FETCH_QUESTIONS_NEW";
export const FETCH_QUESTIONS_MIX_REQUEST = "FETCH_QUESTIONS_MIX_REQUEST";
export const FETCH_QUESTIONS_MIX_SUCCESS = "FETCH_QUESTIONS_MIX_SUCCESS";
export const FETCH_QUESTIONS_MIX_FAILURE = "FETCH_QUESTIONS_MIX_FAILURE";

export const FETCH_LOGS = "FETCH_LOGS";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_FAILURE = "FETCH_LOGS_FAILURE";

export const SAVE_ANSWER = "SAVE_ANSWER";
export const SAVE_ANSWER_SUCCESS = "SAVE_ANSWER_SUCCESS";
export const SAVE_ANSWER_FAILURE = "SAVE_ANSWER_FAILURE";

export const POST_MEDIA_ANSWER = "POST_MEDIA_ANSWER";
export const POST_MEDIA_ANSWER_SUCCESS = "POST_MEDIA_ANSWER_SUCCESS";
export const POST_MEDIA_ANSWER_FAILURE = "POST_MEDIA_ANSWER_FAILURE";

export const RESET_SAVE_ANSWER = "RESET_SAVE_ANSWER";

export const GET_AUDIO = "GET_AUDIO";
export const GET_AUDIO_SUCCESS = "GET_AUDIO_SUCCESS";
export const GET_AUDIO_FAILURE = "GET_AUDIO_FAILURE";

export const GET_VIDEO = "GET_VIDEO";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_FAILURE = "GET_VIDEO_FAILURE";
