import * as actionTypes from "../actions/types";

const initialState = {
  loading: false,
  data: undefined,
  status: undefined,
  message: undefined,
  error: false,
  loadingAudio: false,
  loadingVideo: false,
  audio: [],
  video: [],
};

const questionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_QUESTIONS_REQUEST:
      return { ...state, error: false, loading: true };

    case actionTypes.FETCH_QUESTIONS_SUCCESS:
      return { ...state, error: false, loading: false, data: payload };

    case actionTypes.FETCH_QUESTIONS_FAILURE:
      return { ...state, error: true, loading: false, data: payload };
   
    case actionTypes.GET_AUDIO:
      return { ...state, loadingAudio: true };
    case actionTypes.GET_AUDIO_SUCCESS:
     
      return { ...state, loadingAudio: false, audio: payload };
    case actionTypes.GET_AUDIO_FAILURE:
      return { ...state, loadingAudio: true };

    case actionTypes.GET_VIDEO:
      return { ...state, loadingVideo: true };
    case actionTypes.GET_VIDEO_SUCCESS:
      
      return { ...state, loadingVideo: false, video: payload };
    case actionTypes.GET_VIDEO_FAILURE:
      return { ...state, loadingVideo: true };
    default:
      return state;
  }
};
export default questionReducer;
