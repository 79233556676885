import * as actionTypes from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  loggedInUserSGK: null,
  loadingResetPass: false,
  registerInfo: null,
};

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.POST_LOGIN_REQUEST:
      return { ...state, loading: true };

    case actionTypes.POST_LOGIN_SUCCESS:
    
      return {
        ...state,
        loading: false,
        loggedInUserSGK: payload,
        error: null,
      };

    case actionTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedInUserSGK: null,
        error: payload.error,
      };

    case actionTypes.POST_LOGOUT:
      return {
        ...state,
        loading: false,
        error: null,
        loggedInUserSGK: null,
      };

    case actionTypes.POST_RESET_PASS_REQUEST: {
      return {
        ...state,
        loadingResetPass: true,
      };
    }

    case actionTypes.POST_RESET_PASS_SUCCESS: {
      return {
        ...state,
        loadingResetPass: false,
        error: null,
      };
    }

    case actionTypes.POST_RESET_PASS_FAILURE: {
      return {
        ...state,
        loadingResetPass: false,
      };
    }

    case actionTypes.POST_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUserSGK: payload,
        error: null,
      };

    case actionTypes.POST_REGISTER_REQUEST:
      return { ...state, loading: true };

    case actionTypes.POST_REGISTER_SUCCESS:
   
      return {
        ...state,
        loading: false,
        registerInfo: payload,
        error: null,
      };

    case actionTypes.POST_REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        registerInfo: null,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default loginReducer;
