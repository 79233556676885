import React from 'react';
import moment from 'moment';
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ListGroupItem, ListGroup, Row, Col } from 'reactstrap';

import './style.css';

const mock = [
  {
    id: 1,
    title: 'Tony Woo',
    subTitle: "Let's meet at Starbucks at 11:30. Wdyt?",
    time: moment(),
  },
  {
    id: 2,
    title: 'John Carrick',
    subTitle: 'A new issue has been reported.',
    time: moment(),
  },
  {
    id: 3,
    title: 'Jack Lee',
    subTitle: 'Your posts have been liked a lot.',
    time: moment(),
  },
  {
    id: 4,
    title: 'Peter Jackson',
    subTitle: "Let's meet at Starbucks at 11:30. Wdyt?",
    time: moment(),
  },
];

function Notification() {
  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className='nav-link' color='' tag='a' style={{ cursor: 'pointer' }}>
        <i className='ni ni-bell-55' />
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-xl py-0 overflow-hidden' right>
        <div className='px-3 py-3'>
          <h6 className='text-sm text-muted m-0'>
            You have <strong className='text-info'>13</strong> notifications.
          </h6>
        </div>

        {mock.map((data) => {
          return (
            <ListGroupItem key={data.id} className='list-group-item-action' href='#pablo' onClick={(e) => e.preventDefault()} tag='a'>
              <Row className='align-items-center'>
                <Col className='col-auto'>
                  <img alt='...' className='avatar rounded-circle' src={require(`assets/img/theme/team-${data.id}.jpg`)} />
                </Col>
                <div className='col ml--2'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <h4 className='mb-0 text-sm'>{data.title}</h4>
                    </div>
                    <div className='text-right text-muted'>
                      <small>2 hrs ago</small>
                    </div>
                  </div>
                  <p className='text-sm mb-0'>{data.subTitle}</p>
                </div>
              </Row>
            </ListGroupItem>
          );
        })}

        <DropdownItem className='text-center text-info font-weight-bold py-3' href='#pablo' onClick={(e) => e.preventDefault()}>
          View all
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Notification;
