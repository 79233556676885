import React from "react";
import imgNotData from "assets/No data.gif";
import { Row, Col } from "antd";

const NotData = (props) => (
  // <div style={{ width: '30%', height: '30%', justifyContent: 'center', display: 'flex', alignItems: 'center',backgroundColor:'red' }}>
  //   <img src={imgNotData} alt={'not data'} />
  //   {/* <h1>NO DATA</h1> */}
  // </div>

  <div
    style={{
     
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
    }}
  >
    <span>
      <img src={imgNotData} alt={"not data"} />
    </span>
  </div>
);

export default NotData;
