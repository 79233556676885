import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";


import * as __typeText from "../text";
import moment from "moment";
import queryString from "query-string";
import { Rate, Row, Col } from "antd";
import * as ActionTypes from "../actions/types";
import { default as functions } from "components/functions";
import Listen from "components/Listening";
import AMES247Loading from "components/Error/Loading";
import Recorder from "components/Recording/react_mic";
import CountdownTimer from "components/countdownTimer";
import  openNotificationWithIcon  from "components/Notification";
import { default as NotData } from 'components/Error/NotData';
import Wheel from 'components/Wheel';

import "./Type2.css";

class Type02 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: null,
      resultRecord: undefined,
      questions: props.questions,
      timeStart: null,
      listData: [],
    };

    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
    this.refRecorder = React.createRef();
    this.refCountdownTimer = React.createRef();
  }

  Random(array) {
    var m = array.length,
      t,
      i;
    // Chừng nào vẫn còn phần tử chưa được xáo trộn thì vẫn tiếp tục
    while (m) {
      // Lấy ra 1 phần tử
      i = Math.floor(Math.random() * m--);
      // Sau đó xáo trộn nó
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  UNSAFE_componentWillMount = () => {
    const { questions } = this.props;
    let arr = [];
    for (let i = 0; i < questions.length; i++) {
      arr.push(i);
    }
    const listRandom = this.Random(arr);
    const listData = questions.map((item, index) => {
  
      return {
        value: arr[index],
        label: item.imageUrl
      };
    })
    this.setState({
      timeStart: moment().format(),
      listData
    });
  };

  onRecording = () => {
    this.setState({ disabledNext: true }, () => {
      if (typeof this.props.startRecord === "function") {
        this.props.startRecord();
      }
      this.refCountdownTimer.current.startTimer();
    });
  };

  onStopRecording = result => {
    this.setState({ resultRecord: result, disabledNext: false });
  };

  onStopTimer = () => {
    if (typeof this.refCountdownTimer.current.stopTimer == "function") {
      this.refCountdownTimer.current.stopTimer();
    }
  }

  onNext = (type, _) => {
    const questionParent = this.state.question;

    // check answer true or false
    this.checkAnswer(type);

    this._postAnswerToAPI(this.state.resultRecord, questionParent)

    if (this.checkCompletedOrNo()) return;

    this.questionIndex++;
    this.setState({ question: null })
  };

  checkAnswer = async type => {
    let { saveAnswers } = this.props;

    if (
      type === "NEXT" &&
      this.state.resultRecord.score > functions.satisfactoryResults
    ) {
      saveAnswers({ key: this.questionIndex, isCorrect: true, score: this.state.resultRecord.score, type: "record" });
      openNotificationWithIcon("success", "CORRECT");
    } else {
      saveAnswers({ key: this.questionIndex, isCorrect: false, score: this.state.resultRecord.score, type: "record" });
      openNotificationWithIcon("error", "INCORRECT");
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);


      allProps.history.push(`/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`);
      return true;
    }
    return false;
  };

  moveOnToAnotherQuestion = ix => {
    this.setState({ question: this.state.questions[ix], timeStart: moment().format() });
  };


  _postAnswerToAPI = (resultRecord, questionParent) => {
    const { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    const answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: questionParent.id,
      questionGuid: "", // empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type02),
      notes: "A",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        score: resultRecord.score || 0,
        wordShows: resultRecord.wordShows || [],
        recordUrl: resultRecord.recordUrl || ""
      }),
      duration
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((_) => {
        this.setState({ resultRecord: undefined });
      })
      .catch(err => {
        console.log(err);
      });
  };

  spinSuccess = (item) => {
    const { questions } = this.props;
    this.setState({ question: questions[item] })
  }

  render() {
    let { question, resultRecord, listData } = this.state;
    const { loggedInUserSGK } = this.props;
    const { questions } = this.props;
    const recordParams = question ? {
      questionId: question.id,
      questionText: question.text,
      studentId: loggedInUserSGK.studentId,
      takeExamTime: this.takeExamTime,
    } : null;
    if (this.props.questions.length === 0) {
      return <NotData />;
    }

    if (!questions) {
      return <AMES247Loading />;
    } else {
      return (
        <React.Fragment>
          <Row style={{paddingTop:50}} >
            <Col className={classNames(["question-type__left"])} xs={24} sm={24} md={24} lg={24} xl={14}>
              <Wheel items={listData} onSelectItem={(item) => this.spinSuccess(item)} isDisable={question && question.id}/>
            </Col>
            <Col
             style={{paddingTop: 90}}
              span={5}
            >
              {question && (
                <>
                
                  <Listen audioURL={question?.soundUrl} onAuto={true} />

                  <p className={classNames(["question-type__word"])}>
                    {question?.text}
                  </p>
                  {question?.phonetic !== null ?
                    <p className={classNames(["question-type__kindOfWord"])}>
                      <i>{`/${question?.phonetic}/`}</i>
                    </p>
                    : <div></div>
                  }
                  <p className={classNames(["question-type__kindOfWord"])}>
                    {`( ${question?.wordType} )`}
                  </p>
                  <Recorder
                    ref={this.refRecorder}
                    __custom
                    __className={"question-type__recordType02"}
                    __onRecording={this.onRecording}
                    __onStopRecording={this.onStopRecording}
                    recordParams={recordParams}
                    onStopTimer={this.onStopTimer}
                  >
                    <div className={classNames(["mt-15"])}>
                      <CountdownTimer
                        seconds={15}
                        ref={this.refCountdownTimer}
                        onStopRecording={this.onStopRecording}
                      >
                        <span>Recorder in: </span>
                      </CountdownTimer>
                    </div>
                    <br />
                    {resultRecord && (
                      <div>
                        {resultRecord.wordShows.map((t, i) => (
                          <span
                            key={i}
                            style={{ color: t.color }}
                            className={classNames(["question-type__textReply"])}
                          >{`${t.word} `}</span>
                        ))}
                        <br />
                        <div className="record--result">
                          {resultRecord && (
                            <div className={""}>
                              <Listen
                                custom
                                className={"record--content record--result__listen"}
                                audioURL={resultRecord.recordUrl}
                              >
                                 <i className="fas fa-volume-up"></i>
                              </Listen>
                            </div>
                          )}
                          <div
                            className={classNames([
                              "record--result__rate",
                              "record--content"
                            ])}
                          >
                            <Rate
                              allowHalf
                              disabled
                              value={functions.getStarRecord(resultRecord.score)}
                            />
                          </div>
                          <div
                            className={classNames([
                              "record--result__btn-score",
                              "record--content"
                            ])}
                          >
                            <strong>{`${parseInt(
                              resultRecord.score
                            )}%`}</strong>
                          </div>
                          <div
                            className={classNames([
                              "record--result__btn-next",
                              "record--content"
                            ])}
                            onClick={this.onNext.bind(this, "NEXT")}
                          >
                            <i className="fas fa-arrow-circle-right" />
                          </div>
                        </div>
                      </div>
                    )}
                  </Recorder>
                </>
              )}

            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Type02);
