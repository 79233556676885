import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import queryString from "query-string";
import classNames from "classnames";
import * as textQuestionTypes from "./text";
import * as compQuestionTypes from "./render";
import { Row, Col, Button, Badge, Progress } from "antd";

import { resetAnswerAction } from "./actions/saveAnswerAction";

import { default as functions } from "components/functions";
import { default as Loading } from "components/Error/Loading";
import { default as NotData } from "components/Error/NotData";

import * as ActionTypes from "./actions/types";



class QuestionTypeComponent extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.queryString = queryString.parse(location.search);
    this.state = {
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
    };
    //this.takeExamTime = functions.uuid();
  }

  componentWillMount = () => {
    this.getQuestions();
  };

  getQuestions = () => {
    let { match, location, resetAnswerAction } = this.props;
    let { loggedInUserSGK } = this.state;
    const query = queryString.parse(location.search);
    let { asrId, takeExamTime, type } = query;
    if (loggedInUserSGK) {
      const userId = loggedInUserSGK.studentId;
      let { sessionId, assignmentId } = match.params;
      // this.props.onFetchQuestions({ sessionId, assignmentId, takeExamTime: takeExamTime, userId, asrId });
  
        this.props.fetchQuestions(
          sessionId,
          assignmentId,
          takeExamTime,
          userId,
          asrId,
        );
      
      resetAnswerAction();
    } else {
      window.location.href = `/auth/login`;
    }
  };

  renderExerciseCountdowns = () => {
    let { results, exerciseCountdowns } = this.props;
    if (results.data === undefined) {
      return null;
    }
    let s = 0;
    exerciseCountdowns.forEach((item) => {
      s = item.score + s;
    });
    let { questions = [] } = results.data,
      numberTotal = exerciseCountdowns.length,
      numberCorrect =
        exerciseCountdowns.length > 0
          ? exerciseCountdowns.filter((result) => result.isCorrect).length
          : 0,
      progressTotal = (numberTotal * 100) / questions.length;
    let score = 0;
    if (exerciseCountdowns.length > 0) {
      if (exerciseCountdowns[0].type === "record") {
        score = s / exerciseCountdowns.length;
      } else {
        score = s / questions.length;
      }
    }

    return (
      <div style={{ width: "80%",paddingLeft:30,  }}>
        <table>
          <tbody>
            <tr>
              <td>TỔNG CÂU HỎI</td>
              <td style={{ zIndex: -1 }}>
                <Badge
                  count={`${numberTotal + 1}/${questions.length}`}
                  style={{ backgroundColor: "#09386d" }}
                  title="Số câu hiện tại/tổng"
                />
              </td>

              <td style={{ paddingLeft: 10 }}>ĐIỂM</td>
              <td style={{ zIndex: -1 }}>
                <Badge
                  count={`${parseInt(score)}/100`}
                  style={{ backgroundColor: "#ee6f41" }}
                  title="Điểm Ttrung binh các câu đã hoàn thành"
                />
              </td>
              <td style={{ paddingLeft: 10 }}>ĐÃ TRẢ LỜI ĐÚNG</td>
              <td style={{ zIndex: -1 }}>
                <Badge
                  count={`${numberCorrect}/${questions.length}`}
                  style={{ backgroundColor: "#52c41a" }}
                  title="Đạt nếu điểm >= 50%"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Progress strokeColor='#11cdef' percent={parseInt(progressTotal)} />
      </div>
    );
  };

  renderExtra = () => {
    let { results, match } = this.props;
    if (results.data === undefined) return;
    let { questions } = results.data;
    let { assignmentId, sessionId, classId } = match.params;
    let { type, takeExamTime } = this.queryString;

    let pathname = `/sgk/classes/${classId}/sessions/${sessionId}/assignments/${assignmentId}/results`;
    let search = `?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`;
    return (
      <Button>
        <Link to={{ pathname, search }}>
          {window.innerWidth < 570 ? "END" : "END SESSION"}
        </Link>
      </Button>
    );
  };

  renderQuestionType = () => {
    let { results, location } = this.props;
    let { loggedInUserSGK } = this.state;
    const query = queryString.parse(location.search);
    if (results.data === undefined) return;
    let { questions = [] } = results.data;
    let { type } = query;

    let { takeExamTime } = query;

    if (questions.length === 0) return <NotData />;
    switch (type) {
      case textQuestionTypes.Type02: {
        return (
          <compQuestionTypes.Type02
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type03: {
        return (
          <compQuestionTypes.Type03
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type05: {
        return (
          <compQuestionTypes.Type05
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type07: {
        return (
          <compQuestionTypes.Type07
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type12: {
        // let quesGetExamples = functions.getExampleByTitle(
        //     { unit, course, rootName },
        //     questions
        //   ),
        //   quesExamples = functions.getExampleSound(
        //     { unit, course, rootName },
        //     quesGetExamples
        //   );
        return (
          <compQuestionTypes.Type12
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type13: {
        return (
          <compQuestionTypes.Type13
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type14: {
        return (
          <compQuestionTypes.Type14
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type15: {
        return (
          <compQuestionTypes.Type15
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type18: {
        return (
          <compQuestionTypes.Type18
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      case textQuestionTypes.Type20: {
        return (
          <compQuestionTypes.Type20
            questions={questions}
            takeExamTime={takeExamTime}
            allProps={this.props}
            loggedInUserSGK={loggedInUserSGK}
          />
        );
      }

      default:
        return <NotData />;
    }
  };

  render = () => {

    let { results, match } = this.props;
    if (results.loading) {
      return <Loading />;
    } else {
      return (
        <React.Fragment>
          <div style={{ display: "flex", marginBottom: 20 }}></div>
          <div  id={"question"}>
          
              <div  className={classNames(["question__answer"])}>
                {this.renderExerciseCountdowns()}
                {/* {this.renderExtra()} */}
              </div>
           
            <Row
              id={"question__content"}
              gutter={24}
              className={classNames(["text-align_center"])}
            >
              <Col span={24} className={classNames(["mt-10"])}>
                {this.renderQuestionType()}
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
    }
  };
}

QuestionTypeComponent.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  results: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,

  location: PropTypes.instanceOf(Object).isRequired,
  
  
  exerciseCountdowns: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  results: state.questionReducer,
  exerciseCountdowns: state.saveAnswerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuestions: (sessionId, assignmentId, takeExamTime, userId, asrId) =>
    dispatch({
      type: ActionTypes.FETCH_QUESTIONS,
      sessionId,
      assignmentId,
      takeExamTime,
      userId,
      asrId,
    }),
  resetAnswerAction: () => dispatch(resetAnswerAction()),
});

const QuestionTypeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionTypeComponent);

export default QuestionTypeContainer;
