
import { ames247Axios } from "configs/api";

export function* getClassFromApi(userId) {
  const { data } = yield ames247Axios.get(`/api/getcourses/${userId}`);
  if (data.message === "OK") {
    return data.results;
  };

  if (data.message === "Error") {
    return "Error"
  }
}
