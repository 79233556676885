import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import * as __typeText from "../text";
import { default as functions } from "components/functions";

import queryString from "query-string";
import moment from "moment";
import { Rate } from "antd";
import {
  Row,
  Col,
  Card,
  CardTitle,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Button
} from "reactstrap";

import Listen from "components/Listening";
import * as ActionTypes from "../actions/types";
import AMES247Loading from "components/Error/Loading";
import AMES247Button from "components/Button";
import Recorder from "components/Recording/react_mic";
import CountdownTimer from "components/countdownTimer";
import openNotificationWithIcon from "components/Notification";
import { DictionaryText } from "components/Dictionary";
import NotData from "components/Error/NotData";

class Type20 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      resultRecord: undefined,
      resultRecordWithAzure: undefined,
      question: {},
      questions: [],
      change: false,
      text_answer: "",
      styleImage: {
        width: null,
      },
      timeStart: null,
    };
    this.isCorrect = false;
    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
    this.questionGuid = functions.uuid();
    //this.recordUrlFromApi = null;
    this.refRecorder = React.createRef();
    this.refCountdownTimer = React.createRef();
  }

  onLoadImage = ({ target: img }) => {
    if (img.offsetWidth > 490) {
      this.setState({
        styleImage: {
          width: "450px",
        },
      });
    }
  };

  componentDidMount() {
    let questions = this.props.questions;

    this.setState({
      questions,
      loading: false,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });
  }

  onRecording = () => {
    this.setState({ disabledNext: true }, () => {
      if (typeof this.props.startRecord === "function") {
        this.props.startRecord();
      }
      this.refCountdownTimer.current.startTimer();
    });
  };

  onStopRecording = (result) => {
    this.setState({ resultRecord: result, disabledNext: false });
  };

  onStopTimer = () => {
    if (typeof this.refCountdownTimer.current.stopTimer === "function") {
      this.refCountdownTimer.current.stopTimer();
    }
  };

  onNext_answer = (answer, isCorrect) => {
    this.setState({ change: true, text_answer: answer });
    this.isCorrect = isCorrect;
    let type = "choose";
    // check answer true or false
    this.checkAnswer(type);
    // postAnswerToAPI()
    //this._postAnswerToAPI({ text: answer });

    //if (this.checkCompletedOrNo()) return;

    // nextQuestion
    // this.questionIndex++;
    // this.moveOnToAnotherQuestion(this.questionIndex);
  };

  onNext_record = () => {
    this.setState({ change: false });
    let { question, text_answer } = this.state;
    let CorrectText = "";
    JSON.parse(question.answers).map((answer) => {
      if (answer.isCorrect === true) {
        CorrectText = answer.answer;
      }
    });

    const questionParent = question;
    let type = "record";
    // check answer true or false
    this.checkAnswer(type);

    this._postAnswerToAPI(this.state.resultRecord, questionParent, {
      correctText: CorrectText,
      correctAudioUrl: question.correctAudioUrl,
      text: text_answer,
    });

    if (this.checkCompletedOrNo()) return;

    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
  };

  checkAnswer = (type) => {
    let { saveAnswers } = this.props;
    let isCorrect = this.isCorrect;

    if (type === "choose") {
      if (isCorrect === true) {
        let payLoadRecord = {
          key: this.questionIndex,
          isCorrect: true,
          score: 100,
          type: "record",
        };
        saveAnswers(payLoadRecord);
        openNotificationWithIcon(
          "success",
          "Chính xác",
          "Chúc mừng bạn đã trả lời đúng"
        );
      } else {
        let payLoadRecord = {
          key: this.questionIndex,
          isCorrect: false,
          score: 0,
          type: "record",
        };
        saveAnswers(payLoadRecord);
        openNotificationWithIcon(
          "danger",
          "Không chính xác",
          "Vui lòng kiểm tra lại kết quả"
        );
      }
    } else {
      if (this.state.resultRecord.score > functions.satisfactoryResults) {
        let payLoadRecord = {
          key: this.questionIndex,
          isCorrect: true,
          score: this.state.resultRecord.score,
          type: "record",
        };
        saveAnswers(payLoadRecord);
        openNotificationWithIcon(
          "success",
          "Chính xác",
          "Chúc mừng bạn đã trả lời đúng"
        );
      } else {
        let payLoadRecord = {
          key: this.questionIndex,
          isCorrect: false,
          score: this.state.resultRecord.score,
          type: "record",
        };
        saveAnswers(payLoadRecord);
        openNotificationWithIcon(
          "danger",
          "Không chính xác",
          "Vui lòng kiểm tra lại kết quả"
        );
      }
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  _postAnswerToAPI = (
    resultRecord,
    questionParent,
    { correctText, correctAudioUrl, text }
  ) => {
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: 0,
      questionGuid: questionParent.id,
      answerType: functions.getAnswerType(__typeText.Type20),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        correctText,
        correctAudioUrl,
        text,
        recordResult: {
          score: resultRecord.score || 0,
          recordUrl: resultRecord.recordUrl || "",
        },
      }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((_) => {
        this.setState({ resultRecord: undefined });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  moveOnToAnotherQuestion = (ix) => {
    this.setState({
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });
  };

  render = () => {
    let { loading, question, resultRecord, styleImage } = this.state;
    // console.log("render -> question", question)
    const { loggedInUserSGK } = this.props;
    const recordParams = {
      questionId: question.id,
      questionText: question.correctAnswerText,
      studentId: loggedInUserSGK.studentId,
      takeExamTime: this.takeExamTime,
    };
    if (this.props.questions.length === 0) {
      return <NotData />;
    }

    if (loading) {
      return <AMES247Loading />;
    } else {
      return (
        <React.Fragment>
          {this.state.change === false ? (
            <>
              <Row className="justify-content-md-center text-center mt-3">
                <Col lg="8">
                  <Card
                  // className="border"
                  >
                    <ListGroup>
                      <ListGroupItem
                        style={{
                          // backgroundColor: "#F5365C",
                          borderWidth: 0,
                          fontSize: 20,
                          fontWeight: "500",
                        }}
                        className="active bg-gradient-danger text-center"
                      >
                        <DictionaryText text={question.questionText} />
                      </ListGroupItem>
                      <ListGroupItem>
                        {JSON.parse(question.answers).map((answer) => {
                          return (
                            <AMES247Button
                              key={answer.id}
                              value={answer.answer}
                              style={{
                                fontWeight: "700",
                                width: "70%",
                                margin: 10,
                              }}
                              onClick={() =>
                                this.onNext_answer(
                                  answer.answer,
                                  answer.isCorrect
                                )
                              }
                            />
                          );
                        })}
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
              // //////////////////////////////////////////////////////////////////////
              // //////////////////////////////////////////////////////////////////////
              // //////////////////////////////////////////////////////////////////////
              // //////////////////////////////////////////////////////////////////////

              <Card className='text-center'>
              <Row>
                <Col

                  xs={24}
                  sm={24}
                  lg={12}
                  md={12}
                >
                  <Card className="bg-primary text-white text-center p-4">
                    <CardTitle>
                      <Listen
                        audioURL={question.correctAudioUrl}
                        onAuto={true}
                      />
                    </CardTitle>
                    <blockquote className="blockquote mb-0">
                      <p style={{ fontSize: 20 }}>
                        <DictionaryText text={question.correctAnswerText} />
                      </p>
                      <footer className="blockquote-footer">
                        <small>Nhấp vào để tra cứu nghĩa từng từ </small>
                      </footer>
                    </blockquote>
                  </Card>
                  {/* <h2 className={classNames(["question-type__grammar_text"])}></h2> */}
                </Col>

                <Col
                  // style={{ backgroundColor: "#EBECF1" }}

                  xs={24}
                  sm={24}
                  lg={12}
                  md={12}
                >

                  <Recorder
                    ref={this.refRecorder}
                    __custom
                    __className={'question-type__recordType02'}
                    __onRecording={this.onRecording}
                    __onStopRecording={this.onStopRecording}
                    recordParams={recordParams}
                    onStopTimer={this.onStopTimer}
                  >
                    <div className={classNames(['mt-15'])}>
                      <CountdownTimer
                        seconds={15}
                        ref={this.refCountdownTimer}
                        onStopRecording={this.onStopRecording}
                      >
                        <span>Recorder in: </span>
                      </CountdownTimer>
                    </div>
                    <br />
                    {resultRecord && (
                      <div>
                        {resultRecord.wordShows.map((item, i) => (
                          <span key={i} style={{ color: item.color }} className={classNames(['question-type__textReply'])}>{item.word} </span>
                        ))}
                        <br />
                        <Row className="justify-content-md-center">
                          <Col lg="5">
                            <ListGroup>
                              <ListGroupItem className="d-flex justify-content-between align-items-center">
                                <div>
                                  <Listen custom className={'record--content record--result__listen'} audioURL={resultRecord.recordUrl}>
                                    <Button color="info" id="tooltipRepeat">
                                      <i style={{ fontSize: 15 }} className="fas fa-volume-up" />
                                    </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target="tooltipRepeat"
                                    >
                                      Nhấn để nghe lại
                                  </UncontrolledTooltip>
                                  </Listen>
                                </div>
                                <Rate allowHalf disabled value={functions.getStarRecord(resultRecord.score)} />
                                <strong>{`${parseInt(resultRecord.score)}%`}</strong>
                                <div>
                                  <Button color="info" onClick={this.onNext_record} id='tooltipNextButton'>
                                    <i style={{ fontSize: 15 }} className="fas fa-arrow-circle-right" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    placement="top"
                                    target="tooltipNextButton"
                                  >
                                    Tiếp tục
                                   </UncontrolledTooltip>
                                </div>
                              </ListGroupItem>
                            </ListGroup>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Recorder>
                </Col>
              </Row>
            </Card>
            )}
        </React.Fragment>
      );
    }
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type20);
