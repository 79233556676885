import React from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";

import "./style.css";

const profile = (props) => {
  const onPressLogout = (e) => {
    localStorage.clear();
    props.history.push("/");
  };
  const onPressCalendar = (e) => {
    props.history.push("/sgk/calendar");
  };
  return (
    <UncontrolledDropdown nav>
      <DropdownToggle
        className="nav-link pr-0"
        color=""
        tag="a"
        style={{ cursor: "pointer" }}
      >
        <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle">
            <img
              alt="Avatar"
              src={require("assets/img/avatar.jpg")}
            />
          </span>
          <Media className="ml-2 d-none d-lg-block">
            <span className="mb-0 text-sm font-weight-bold">
              {props.userInfo?.fullName}
            </span>
          </Media>
        </Media>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">Welcome!</h6>
        </DropdownItem>
        <DropdownItem href="#" onClick={() => props.history.push('/sgk/profile')}>
          <i className="ni ni-single-02" />
          <span>Tài khoản</span>
        </DropdownItem>
        <DropdownItem href="#" onClick={(e) => e.preventDefault()}>
          <i className="ni ni-settings-gear-65" />
          <span>Cài đặt</span>
        </DropdownItem>
        <DropdownItem href="" onClick={onPressCalendar}>
          <i className="ni ni-calendar-grid-58" />
          <span>Lịch học</span>
        </DropdownItem>
        <DropdownItem href="#" onClick={(e) => e.preventDefault()}>
          <i className="ni ni-support-16" />
          <span>Hỗ trợ</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={onPressLogout}>
          <i className="ni ni-user-run" />
          <span>Đăng xuất</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default profile;
