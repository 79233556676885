import { fork, all } from 'redux-saga/effects';
import loginSaga from './modules/Login/sagas';
import classSaga  from './modules/ClassModule/sagas';
import sessionSaga  from './modules/SessionModule/sagas';
// import { default as sessionSaga } from './modules/SessionModule/sagas';
import assignmentSaga  from './modules/AssignmentModule/sagas';
import questionsSaga from './modules/QuestionModule/sagas/questionsSaga';
import saveAnswerSaga from './modules/QuestionModule/sagas/saveAnswerSaga';
import resultSaga  from './modules/ResultModule/sagas';
// import { default as logSaga } from './modules/LogModule/sagas';
// import { default as UpdateProfileSaga } from './modules/profileModule/ChangeProfile/sagas';

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(classSaga),
    fork(sessionSaga),
    fork(questionsSaga),
    fork(assignmentSaga),
    fork(saveAnswerSaga),
    fork(resultSaga),
    // fork(classSaga.watchFetchClass),
    // fork(sessionSaga.watchFetchSessions),
    // fork(assignmentSaga.watchFetchAssignments),
    // fork(questionsSaga.watchFetchQuestions),
    // fork(resultSaga.watchGetResult),
    // fork(saveAnswerSaga.watchSaveAnswers),
    // fork(logSaga.watchFetchLogs),
    // fork(UpdateProfileSaga.watchUpdateInfo),
  ]);
}
