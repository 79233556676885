import { put, takeLatest } from "redux-saga/effects";
import { getSessionsMyames } from "./apiMyames";
import * as actionTypes from "../actions/types";

function* fetchSessions(action) {
  try {
    yield put({ type: actionTypes.FETCH_SESSION_REQUEST });
    let { classId, studentId } = action;
    const receivedSessions = yield getSessionsMyames({ classId, studentId });
  
    if (receivedSessions === "Error") {
      yield put({
        type: actionTypes.FETCH_SESSION_FAILURE,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_SESSION_SUCCESS,
        payload: receivedSessions,
      });
    }

  } catch (error) {
    console.log("FETCH_SESSION_FAILURE", error);
    yield put({
      type: actionTypes.FETCH_SESSION_FAILURE,
    });
  }
}

export default function* sessionSaga() {
    yield takeLatest(actionTypes.FETCH_SESSION, fetchSessions);
};


