/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import Highlighter from "react-highlight-words";
import {
  Form,
  Table,
  Card,
  Input,
  Typography,
  DatePicker,
  Select,
  Space,
  Popconfirm,
} from "antd";
import { PhoneOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "reactstrap";
import { layout, tailLayout } from "packages/formLayouts";
import { query } from "helpers/QueryHelper";
import { dynamicApiAxios } from "configs/api";
import NotData from "components/Error/NotData";
import Notifications from "components/Notification";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
const { Column } = Table;
const { RangePicker } = DatePicker;

export default function CreateClassPage(props) {
  // REDUX
  const loggedInUserSGK = JSON.parse(localStorage.getItem("loggedInUserSGK"));

  // REFS
  const form = React.useRef(null);
  // STATE

  //Edit Form
  const EditableContext = React.createContext();

  const EditableRow = ({ index, ...props }) => {
    const [formEdit] = Form.useForm();
    return (
      <Form form={formEdit} component={false}>
        <EditableContext.Provider value={formEdit}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = React.useState(false);
    const inputRef = React.useRef();
    const formEdit = React.useContext(EditableContext);
    React.useEffect(() => {
      if (editing) {
        // eslint-disable-next-line no-unused-expressions
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      formEdit.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await formEdit.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        // console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  // Create class//

  const [state, setState] = React.useState({
    classes: [],
    courses: [],
    searchText: "",
  });

  const getCourses = React.useCallback(() => {
    query("p_SGK_Courses_OPTIONS", {}, "Sgk").then((result) => {
      setState((prevState) => ({ ...prevState, courses: result }));
    });
  }, []);

  const getClass = React.useCallback(() => {
    query(
      "p_SGK_TEACHER_Classes_Get_ByTeacherId",
      { TeacherId: loggedInUserSGK?.userId },
      "Sgk"
    ).then((result) => {
      result.map((item) => {
        for (let i = 0; i < state.courses.length; i++) {
          const courseIndex = item.courseId.includes(state.courses[i].id);
          if (courseIndex) {
            item.courseName = state.courses[i].name;
          }
        }
      });
      setState((prevState) => ({ ...prevState, classes: result }));
    });
  }, [state.courses]);

  const searchInput = React.useRef();

  const handleSearch = React.useCallback((selectedKeys, confirm) => {
    confirm();
    setState((prevState) => ({
      ...prevState,
      searchText: selectedKeys[0],
      // selectedRowKeysLeft: dataIndex,
    }));
  }, []);

  const handleReset = (clearFilters) => {
    clearFilters();
    setState((prevState) => ({
      ...prevState,
      searchText: "",
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Tìm kiếm theo tên"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      state.selectedRowKeysLeft === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Submit
  const onFinish = async (values) => {
    const parameters = {
      TeacherId: loggedInUserSGK.userId,
      ClassName: values.className,
      StartDate: values.timeRange[0].format("YYYY-MM-DD hh:mm:ss"),
      FinishDate: values.timeRange[1].format("YYYY-MM-DD hh:mm:ss"),
      Code: "",
      Note: "",
      CourseId: values.courses,
    };

    try {
      await dynamicApiAxios.execute.post("", {
        sqlCommand: "p_SGK_TEACHER_Classes_Insert_ByTeacher",
        parameters,
      });

      Notifications("success", "Thông báo", "Thêm thành công");
      getClass();
    } catch (error) {
      Notifications("danger", "Lỗi", "Có lỗi xảy ra vui lòng thử lại");
    }
  };

  // Validate Failed
  const onFinishFailed = (errorInfo) => {
    Notifications("danger", "Lỗi", "Có lỗi xảy ra vui lòng thử lại");
  };

  React.useEffect(() => {
    getCourses();
  }, []);

  React.useEffect(() => {
    getClass();
  }, [state.courses]);

  const viewDetail = React.useCallback((text) => {
    console.log("text", text);
    props.history.push(`/sgk/teacher/class/${text}/add-student`);
  }, []);

  const courseList = React.useCallback(() => {
    return state.courses.map((item) => (
      <Select.Option value={item.id}>{item.name}</Select.Option>
    ));
  }, [state.courses]);

  const confirmDelete = (classId) => {
    query("p_SGK_Classes_Delete", { classId }, "Sgk").then(() => {
      Notifications("success", "Thông báo", "Xóa thành công");
      getClass();
    });
  };

  const checkRole = loggedInUserSGK?.userRoles?.includes("Student");

  return checkRole ? (
    <NotData />
  ) : (
    <React.Fragment>
      <Container style={{ backgroundColor: "#11CDEF" }} fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
              <a onClick={() => props.history.push("/sgk/trang-chu")}>
                <h6 className="h2 text-white d-inline-block mb-0">Trang chủ</h6>{" "}
              </a>
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark "
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i class="fas fa-chalkboard-teacher" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem className="active">
                  {/* <a
                      onClick={() => this.props.history.push("/sgk/teacher/create-class")}
                    > */}
                  Danh sách lớp
                  {/* </a> */}
                </BreadcrumbItem>
                {/* <BreadcrumbItem
                    aria-current="page"
                    className="active text-black"
                  >
                    Thêm học sinh
                  </BreadcrumbItem> */}
              </Breadcrumb>
            </Col>
            {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    onClick={() => this.props.history.push("/sgk/trang-chu")}
                    size="sm"
                  >
                    Quay lại
                  </Button>
                </Col> */}
          </Row>
        </div>
      </Container>
      <Card
        title="GIÁO VIÊN - Tạo lớp học"
        bordered={false}
        style={{ width: "100%" }}
      >
        {/* --------------------------------------------------------------------------------------------- */}
        {/* FORM */}
        {/* --------------------------------------------------------------------------------------------- */}
        {
          <div>
            <Form
              {...layout}
              ref={form}
              name="dynamic-report-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Chọn khóa học
                  </span>
                }
                hasFeedback
                name="courses"
                rules={[{ required: true, message: "Vui lòng chọn khóa học!" }]}
              >
                <Select
                  style={{
                    borderRadius: 5,
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  showSearch
                  prefix={<PhoneOutlined />}
                >
                  {courseList()}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Tên lớp
                  </span>
                }
                name="className"
                rules={[{ required: true, message: "Vui lòng nhập lớp" }]}
              >
                <Input
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Chọn thời gian
                  </span>
                }
                name="timeRange"
                rules={[{ required: true, message: "Vui lòng chọn thời gian" }]}
              >
                {/* <DatePicker format='DD-MM-YYYY' onChange={onChangeStartDay} /> */}
                <RangePicker
                  style={{
                    borderRadius: 5,
                  }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              {/* <Form.Item label={<span style={{ fontWeight: '500', color: 'rgba(0, 0, 0, 0.75)' }}>Kết thúc</span>} name='finishDay' rules={[{ required: true, message: 'Bạn chưa nhập mã lớp' }]}>
                <DatePicker format='DD-MM-YYYY' onChange={onChangeFinishDay} />
              </Form.Item> */}

              {/* Submit */}
              <Form.Item {...tailLayout}>
                <Button
                  // size="default"
                  type="primary"
                  htmlType="submit"
                  color="info"
                >
                  <span className="btn-inner--icon mr-2">
                    <i class="fas fa-edit" />
                  </span>
                  <span className="btn-inner--text">Tạo</span>
                </Button>
              </Form.Item>
            </Form>
          </div>
        }
      </Card>

      {/* --------------------------------------------------------------------------------------------- */}
      {/* TABLE */}
      {/* --------------------------------------------------------------------------------------------- */}
      {state.classes.length !== 0 && (
        <div style={{ marginTop: 24, padding: 12 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography.Text style={{ fontWeight: "500" }}>
              Danh sách lớp:
            </Typography.Text>
          </div>

          <Table
            rowKey="id"
            size="small"
            dataSource={state.classes}
            bordered
            components={components}
            rowClassName={() => "editable-row"}
            // loading={loading}
            pagination
            scrollToFirstRowOnChange
          >
            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Mã lớp
                </span>
              )}
              dataIndex="code"
              width="3%"
              align="center"
              key="code"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />
            <Column
              align="center"
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Tên lớp
                </span>
              )}
              dataIndex="className"
              key="className"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
              {...getColumnSearchProps("className")}
            />
            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Bắt đầu
                </span>
              )}
              dataIndex="startDate"
              align="center"
              width="10%"
              key="startDate"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />
            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Kết thúc
                </span>
              )}
              dataIndex="finishDate"
              width="10%"
              align="center"
              key="finishDate"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />
            <Column
              align="center"
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Khóa học
                </span>
              )}
              dataIndex="courseName"
              key="courseName"
              width="10%"
              editable
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />
            <Column
              align="center"
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Ghi chú
                </span>
              )}
              dataIndex="note"
              key="note"
              editable
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />
            <Column
              align="center"
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  #
                </span>
              )}
              dataIndex="id"
              key="id"
              width="20%"
              render={(text) => {
                return (
                  <Row>
                    <Col>
                      <Space>
                        <Button
                          size="sm"
                          className="btn-icon"
                          color="info"
                          type="button"
                          onClick={() => viewDetail(text)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i class="fas fa-info-circle" />
                          </span>
                          <span className="btn-inner--text">Chi tiết</span>
                        </Button>

                        <Popconfirm
                          onConfirm={() => confirmDelete(text)}
                          title="Bạn có chắc chắn muốn xóa lớp này"
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button size="sm" className="btn-icon" type="button">
                            <span className="btn-inner--icon mr-1">
                              <i class="fas fa-trash-alt" />
                            </span>
                            <span className="btn-inner--text">Xóa</span>
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Col>
                  </Row>
                );
              }}
            />
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}
