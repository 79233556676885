/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import Highlighter from "react-highlight-words";
import { Form, Table, Card, Input, Typography, Space, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "reactstrap";
import { layout, tailLayout } from "packages/formLayouts";
import { query, queryFirst } from "helpers/QueryHelper";
// import { dynamicApiAxios } from "configs/api";
// import NotData from "components/Error/NotData";
import Notifications from "components/Notification";
import moment from "moment";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";

const { Column } = Table;

export default function StudentJoinClass(props) {
  // REDUX
  const loggedInUserSGK = JSON.parse(localStorage.getItem("loggedInUserSGK"));

  // REFS
  const form = React.useRef(null);
  // STATE

  // Create class//

  const [state, setState] = React.useState({
    classes: [],
    courses: [],
    searchText: "",
  });

  const getClass = React.useCallback(() => {
    query(
      "p_SGK_STUDENT_Classes_Search",
      { name: "", studentId: loggedInUserSGK.userId },
      "Sgk"
    ).then((result) => {
      console.log(result);
      setState((prevState) => ({ ...prevState, classes: result }));
    });
  }, []);

  React.useEffect(() => {
    getClass();
  }, []);

  const searchInput = React.useRef();

  const handleSearch = React.useCallback((selectedKeys, confirm) => {
    confirm();
    setState((prevState) => ({
      ...prevState,
      searchText: selectedKeys[0],
      // selectedRowKeysLeft: dataIndex,
    }));
  }, []);

  const handleReset = (clearFilters) => {
    clearFilters();
    setState((prevState) => ({
      ...prevState,
      searchText: "",
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Tìm kiếm theo tên"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      state.selectedRowKeysLeft === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Submit

  const onFinish = async (values) => {
    const parameters = { studentId: loggedInUserSGK.userId, ...values };

    const result = await queryFirst(
      "p_SGK_STUDENT_JoinToClass",
      parameters,
      "Sgk"
    );
    if (result && result.error) {
      Notifications(
        "danger",
        "Lỗi",
        `Không tồn tại mã lớp ${values.classCode}`
      );
    } else {
      Notifications(
        "success",
        "Thông báo",
        `Tham gia lớp ${values.classCode} thành công`
      );
      getClass();
    }
  };

  const onFinishFailed = (errorInfo) => {
    Notifications("danger", "Lỗi", "Có lỗi xảy ra vui lòng thử lại");
  };

  const confirmDelete = (classId) => {
    query("p_SGK_Classes_Delete", { classId }, "Sgk").then(() => {
      Notifications("success", "Thông báo", "Xóa thành công");
      getClass();
    });
  };

  // const checkRole = loggedInUserSGK?.userRoles?.includes("Student");

  return (
    <React.Fragment>
      <Container style={{ backgroundColor: "#11CDEF" }} fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
              <a onClick={() => props.history.push("/sgk/trang-chu")}>
                <h6 className="h2 text-white d-inline-block mb-0">Trang chủ</h6>{" "}
              </a>
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark "
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i class="fas fa-chalkboard-teacher" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem className="active">Tham gia lớp</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
      <Card
        title="Học sinh - Tham gia lớp"
        bordered={false}
        style={{ width: "100%" }}
      >
        {/* --------------------------------------------------------------------------------------------- */}
        {/* FORM */}
        {/* --------------------------------------------------------------------------------------------- */}
        
          <div>
            <Form
              {...layout}
              ref={form}
              name="dynamic-report-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Mã lớp học
                  </span>
                }
                name="classCode"
                rules={[
                  {
                    whitespace: true,
                    message: "Vui lòng nhập mã lớp",
                  },
                  { required: true, message: "Vui lòng nhập mã lớp" },
                ]}
              >
                <Input
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  // size="default"
                  type="primary"
                  htmlType="submit"
                  color="info"
                >
                  <span className="btn-inner--icon mr-2">
                    <i class="fas fa-edit" />
                  </span>
                  <span className="btn-inner--text">Tham gia lớp</span>
                </Button>
              </Form.Item>
            </Form>
          </div>
        
      </Card>

      {/* --------------------------------------------------------------------------------------------- */}
      {/* TABLE */}
      {/* --------------------------------------------------------------------------------------------- */}
      {state.classes !== 0 && (
        <Card>
          <div style={{ marginTop: 24, padding: 12 }}>
            <div style={{ marginBottom: 8 }}>
              <Typography.Text style={{ fontWeight: "500" }}>
                Danh sách lớp đã tham gia:
              </Typography.Text>
            </div>

            <Table
              rowKey="id"
              size="small"
              dataSource={state.classes}
              bordered
              // components={components}
              // rowClassName={() => "editable-row"}
              // loading={loading}
              pagination
              scrollToFirstRowOnChange
            >
              <Column
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Mã lớp
                  </span>
                )}
                dataIndex="classCode"
                width="3%"
                align="center"
                key="code"
                render={(text) => {
                  return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
                }}
              />
              <Column
                align="center"
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Tên lớp
                  </span>
                )}
                dataIndex="className"
                key="className"
                render={(text) => {
                  return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
                }}
                {...getColumnSearchProps("className")}
              />

              <Column
                align="center"
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Tên giáo viên
                  </span>
                )}
                dataIndex="teacherName"
                key="teacherName"
                editable
                render={(text) => {
                  return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
                }}
              />

              <Column
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Bắt đầu
                  </span>
                )}
                dataIndex="startDate"
                align="center"
                width="10%"
                key="startDate"
                render={(text) => {
                  return (
                    <span style={{ whiteSpace: "nowrap" }}>
                      {moment(text).format("DD/MM/yyyy")}
                    </span>
                  );
                }}
              />
              <Column
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Kết thúc
                  </span>
                )}
                dataIndex="finishDate"
                width="10%"
                align="center"
                key="finishDate"
                render={(text) => {
                  return (
                    <span style={{ whiteSpace: "nowrap" }}>
                      {moment(text).format("DD/MM/yyyy")}
                    </span>
                  );
                }}
              />
              <Column
                align="center"
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    Tên trường
                  </span>
                )}
                dataIndex="schoolName"
                key="schoolName"
                width="10%"
                editable
                render={(schoolName) => {
                  return (
                    <span style={{ whiteSpace: "nowrap" }}>{schoolName}</span>
                  );
                }}
              />

              <Column
                align="center"
                title={() => (
                  <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                    #
                  </span>
                )}
                dataIndex="id"
                key="id"
                width="15%"
                render={(text) => {
                  return (
                    <Row>
                      <Col>
                        <Space>
                          <Popconfirm
                            onConfirm={() => confirmDelete(text)}
                            title="Bạn có chắc chắn muốn rời lớp này"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              size="sm"
                              className="btn-icon"
                              type="button"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i class="fas fa-user-minus"></i>
                              </span>
                              <span className="btn-inner--text">Rời lớp</span>
                            </Button>
                          </Popconfirm>
                        </Space>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Table>
          </div>
        </Card>
      )}
    </React.Fragment>
  );
}
