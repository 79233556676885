import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "components/Error/Loading";
import * as ActionTypes from "../actions/types";
import Iframe from "react-iframe";
import {
  ListGroupItem,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  ListGroup,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      indexVideo: 0,
      listVideo: [],
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loading !== state.loading) {
      return {
        listVideo: props.listVideo,
        loading: props.loading,
      };
    }
    return null;
  };

  componentDidMount = () => {
    const { getVideo, match } = this.props;
    const sessionId = match.params.sessionId;
    getVideo(sessionId);
  };

  playVideo = (index) => {
    this.setState({ indexVideo: index });
  };

  renderListVideo = () => {
    const { listVideo } = this.state;
    const list = listVideo.map((item, index) => {
      const color = ["warning", "success", "danger", "info", "default"];
      const randomIndex = Math.floor(Math.random(color) * 5);
      const name = `checklist-item checklist-item-${color[randomIndex]}`;
      return (
        <ListGroupItem
          key={index}
          onClick={() => this.playVideo(index)}
          className="checklist-entry flex-column align-items-start py-4 px-4 card-lift--hover"
        >
          <div className={name}>
            <div className="checklist-info">
              <h5 className="checklist-title mb-0">{item.name}</h5>
              <small>{item.fileType}</small>
            </div>
            <div>
              <div className="custom-control custom-checkbox custom-checkbox-warning">
                <i className="fas fa-play-circle" />
              </div>
            </div>
          </div>
        </ListGroupItem>
      );
    });
    return list;
  };

  render() {
    const { listVideo, loading, indexVideo } = this.state;
    const { match } = this.props;
    const classId = match.params.classId;
    const linkBackSession = `/sgk/classes/${classId}/sessions`;
    const iframe = listVideo[indexVideo]?.url;
    if (loading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <Container className="header bg-info pb-6" fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                <h6 className="h2 text-white d-inline-block mb-0">SESSION</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-book-open" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      onClick={() => this.props.history.push("/sgk/trang-chu")}
                    >
                      Classes
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={() => this.props.history.push(linkBackSession)}>
                      Sessions
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Video
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    onClick={() => this.props.history.push("/sgk/trang-chu")}
                    size="sm"
                  >
                    Quay lại
                  </Button>
                </Col> */}
            </Row>
          </div>
        </Container>

        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <Iframe
                  url={iframe}
                  id="myId"
                  allowFullScreen
                  frameBorder="0"
                  height="400vh"
                />
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Danh sách video</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup data-toggle="checklist" flush>
                    {this.renderListVideo()}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  listVideo: state.questionReducer.video,
  loading: state.questionReducer.loadingVideo,
  // userInfo: state.loginReducer
});

const mapDispatchToProps = (dispatch) => ({
  getVideo: (sessionId) => dispatch({ type: ActionTypes.GET_VIDEO, sessionId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
