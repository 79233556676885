import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand } from "reactstrap";

const logoUrl = require("assets/img/brand/Logo sgk-02.png");

export const Logo = () => {
  return (
    <div>
      <NavbarBrand  tag={Link} to="/">
        <img style={{ marginLeft: "30%" }} alt="Logo" src={logoUrl} />
      </NavbarBrand>
    </div>
  );
};
