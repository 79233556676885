import { put, takeLatest } from "redux-saga/effects";
import { getClassFromApi } from "./api";
import * as actionTypes from "../actions/types";

function* fetchClass(action) {
  try {
    yield put({ type: actionTypes.FETCH_CLASS_REQUEST });
    const receivedClass = yield getClassFromApi(action.userId);
    yield put({
      type: actionTypes.FETCH_CLASS_SUCCESS,
      payload: receivedClass
    });
    
  } catch (error) {
    console.log("FETCH_CLASS_FAILURE", error);
    yield put({
      type: actionTypes.FETCH_CLASS_FAILURE,
      payload: error
    });
  }
}

export default function* classSaga() {
  yield takeLatest(actionTypes.FETCH_CLASS, fetchClass);
}
