import { ames247Axios } from "configs/api";
import { default as functions } from "components/functions";

export function* getQuestionsFromApi({ sessionId, assignmentId, takeExamTime, userId, asrId }) {
  const res = yield ames247Axios.get(`/api/GetQuestionOfSessionV5/${sessionId}/${assignmentId}/${takeExamTime}/${userId}/${asrId}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }

}

export function* postMediaAnswerToApi(body) {
  let __response = null;
  let reader = new FileReader();
  reader.readAsDataURL(body.blobFile);
  reader.onload = yield function () {
    const DATA = new FormData();

    DATA.append("input", reader.result);
    DATA.append("extensionInput", "wav");
    DATA.append("readingText", "cloud");
    DATA.append("studentID", "11147806");
    DATA.append("takeExamTime", functions.uuid());

    try {
      let response =  fetch(
        "https://ames.edu.vn/ames/api/amesapi/SaveFileAndCalculateScore",
        {
          body: DATA,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          method: "POST"
        }
      );

      __response = response;
    } catch (error) {
      console.log("response error", error);
    }
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };

  return __response;
}

export function* getAudioApi(sessionId) {
  const res = yield ames247Axios.get(`/api/GetListenAudioFiles/${sessionId}`);
  if (res.status === 200) {
    return res.data;
  }
  return [];
}

export function* getVideoApi(sessionId) {
  const res = yield ames247Axios.get(`/api/GetVideoFiles/${sessionId}`);
  if (res.status === 200) {
    return res.data.results;
  }
  return [];
}