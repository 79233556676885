import React from "react";
import classNames from "classnames";

import * as __typeText from "../text";
import { default as functions } from "components/functions";
import moment from "moment";

import queryString from "query-string";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import * as ActionTypes from "../actions/types";
import  AMES247Button  from "components/Button";
import  openNotificationWithIcon  from "components/Notification";
import Listen from "components/Listening";
import AMES247Loading from "components/Error/Loading";
import NotData  from 'components/Error/NotData';

class Type03 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      question: {},
      questions: [],
      disabledBack: true,
      disabledNext: false,
      timeStart: null,
    };
    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;


  }

  componentDidMount() {
    let questions = functions.randomTextAnswersFromAPI(this.props.questions);
    questions = functions.randomFourAnswersOneWay(questions);
    this.setState({
      questions,
      loading: false,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });
  }

  onNext = (answer, _) => {
    // check answer true or false
    this.checkAnswer(answer);
    // postAnswerToAPI()
    this._postAnswerToAPI(answer);

    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
    if (this.questionIndex !== 0) {
      this.setState({ disabledBack: false });
    }
  };

  checkAnswer = answer => {
    let { saveAnswers } = this.props;
    let { question } = this.state;
    if (answer.id === question.id) {
      saveAnswers({ key: this.questionIndex, isCorrect: true, score: 100, type: "" });
      openNotificationWithIcon("success", "CORRECT");
    } else {
      saveAnswers({ key: this.questionIndex, isCorrect: false, score: 0, type: "" });
      openNotificationWithIcon("error", "INCORRECT");
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);
      

      allProps.history.push(`/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`);
      return true;
    }
    return false;
  };

  _postAnswerToAPI = answer => {
    let { question } = this.state;
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: question.id,
      questionGuid: "", //empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type03),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        id: answer ? answer.id : question.id,
        text: answer ? answer.text : ""
      }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then(v => {})
      .catch(err => {
        console.log(err);
      });
  };

  moveOnToAnotherQuestion = ix => {
    this.setState({
      choosedAnswer: undefined,
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });
  };

  render() {
    let { loading, question, choosedAnswer } = this.state;
    if(this.props.questions.length === 0)
    {
      return <NotData />;
    }
    if (loading) {
      return <AMES247Loading />;
    } else {
      return (
        <React.Fragment>
          <Row className={classNames(["question-type"])}>
            <Col
              className={classNames(["question-type__left"])}
              xs={24}
              sm={24}
              lg={12}
              md={12}
            >
              <Listen audioURL={question.soundUrl} onAuto={true}/>
            </Col>

            <Col
              className={classNames(["question-type__info"])}
              xs={24}
              sm={24}
              lg={12}
              md={12}
            >
              {question.answers.map((answer, index) => (
                <AMES247Button
                  key={index}
                  value={answer.text}
                  style={{ width: "100%", margin: 5 }}
                  onClick={this.onNext.bind(this, answer)}
                />
              ))}
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Type03);
