import { combineReducers } from "redux";
// import { siderReducer } from './layouts/Sider/action';
// import { loadingReducer } from './components/Loading/action';
// import { answersReducer } from './modules/ResultModule/actions';
import loginReducer from "./modules/Login/reducers";
import classReducer from "./modules/ClassModule/reducers";
import sessionReducer from "./modules/SessionModule/reducers";
// import { default as sessionReducer } from './modules/SessionModule/reducers';
import assignmentReducer from "./modules/AssignmentModule/reducers";
import questionReducer from "./modules/QuestionModule/reducers/questionsReducer";
import resultReducer from './modules/ResultModule/reducers';
import saveAnswerReducer from "./modules/QuestionModule/reducers/saveAnswerReducer";
// import { default as logReducer } from './modules/LogModule/reducers';
// import UpdateInfoReducer from './modules/profileModule/ChangeProfile/reducers';
// import { default as ReduxReducer } from './modules/ReduxModule/reducers';

const rootReducer = combineReducers({
  // loadingReducer,
  // siderReducer,
  loginReducer,
  classReducer,
  sessionReducer,
  assignmentReducer,
  questionReducer,
  resultReducer,
  saveAnswerReducer,
  // logReducer,
  // answersReducer,
  // UpdateInfoReducer,
  // ReduxReducer,
});

export default rootReducer;
