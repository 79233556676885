/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import * as ActionTypes from "../actions/types";
// reactstrap components
import {
  // Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Form, Input, Select, Button } from "antd";
import {
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
} from "@ant-design/icons";
// core components
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthHeader from "components/Headers/AuthHeader.js";
import { dynamicApiAxios } from "configs/api";
import { useDispatch, useSelector } from "react-redux";

const FormItem = Form.Item;
const RegisterStudent = (props) => {
  const history = props.history;

  const dispatch = useDispatch();
  const handleSubmit = (values) => {
  
    dispatch({ type: ActionTypes.POST_REGISTER_REQUEST, values, history });
  };

  const [form] = Form.useForm();

  const [state, setState] = React.useState({
    cities: [],
    districts: [],
    schools: [],
  });

  const loading = useSelector((rootState) => rootState.loginReducer.loading);

  React.useEffect(() => {
    const getData = async () => {
      const cityResponse = await dynamicApiAxios.query.post("", {
        sqlCommand: "[dbo].[p_SGK_Cities_OPTIONS]",
        parameters: {},
      });
      setState((prevState) => ({
        ...prevState,
        cities: cityResponse.data.items,
      }));
    };
    // return () => {
    // componentwillUnmount
    // };
    getData();
  }, []);

  const onChangeCityValue = React.useCallback(async (cityId) => {
    // form.resetFields();
    form.setFieldsValue({
      districtId: null,
      schoolId: null,
    });
    setState((prevState) => ({ ...prevState, districts: [], schools: [] }));
    const districtResponse = await dynamicApiAxios.query.post("", {
      sqlCommand: "[dbo].[p_SGK_District_By_City_OPTIONS]",
      parameters: { cityId },
    });
    setState((prevState) => ({
      ...prevState,
      districts: districtResponse.data.items,
    }));
  }, []);

  const onChangeDistrictValue = React.useCallback(async (districtId) => {
    form.setFieldsValue({
      schoolId: null,
    });
    const schoolResponse = await dynamicApiAxios.query.post("", {
      sqlCommand: "[dbo].[p_SGK_Schools_By_District_OPTIONS]",
      parameters: { districtId },
    });
    setState((prevState) => ({
      ...prevState,
      schools: schoolResponse.data.items,
    }));
  }, []);

  const cityList = React.useMemo(() => {
    return state.cities.map((item) => (
      <Select.Option value={item.id}>{item.name}</Select.Option>
    ));
  }, [state.cities]);

  const districtList = React.useMemo(() => {
    return state.districts.map((item) => (
      <Select.Option value={item.id}>{item.name}</Select.Option>
    ));
  }, [state.districts]);

  const schoolList = React.useMemo(() => {
    return state.schools.map((item) => (
      <Select.Option value={item.id}>{item.name}</Select.Option>
    ));
  }, [state.schools]);
  return (
    <>
      <AuthHeader
      {...props}
        title="Giáo viên đăng ký"
        // lead="Giáo viên đăng ký tài khoản để sử dụng"
      />
      <Container className="section section-lg pt-lg-0 mt--9">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form
                  autoComplete="off"
                  form={form}
                  onFinish={handleSubmit}
                  style={{ minWidth: 100 }}
                >
                  <FormItem
                    hasFeedback
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Vui lòng nhập đúng định dạng E-mail!",
                      },
                      { required: true, message: "Vui lòng nhập E-mail!" },
                    ]}
                    maxLength={250}
                  >
                    <Input
                      style={{
                       
                        borderRadius: 5,
                      }}
                      allowClear
                      size="large"
                      prefix={
                        <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="E-mail (*)"
                    />
                  </FormItem>

                  <FormItem
                    hasFeedback
                    name="fullname"
                    rules={[
                      {
                        whitespace: true,
                        message: "Vui lòng nhập Họ và tên",
                      },
                      { required: true, message: "Vui lòng nhập Họ và tên" },
                    ]}
                  >
                    <Input
                      style={{
                       
                        borderRadius: 5,
                      }}
                      allowClear
                      size="large"
                      prefix={
                        <FormOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Họ tên (*)"
                    />
                  </FormItem>

                  <FormItem
                    hasFeedback
                    name="phone"
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (!isNaN(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Vui lòng chỉ nhập số!");
                        },
                      }),
                      { min: 10, message: "Tối thiểu 10 ký tự!" },
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                       
                        borderRadius: 5,
                      }}
                      allowClear
                      size="large"
                      prefix={
                        <PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Số điện thoại (*)"
                    />
                  </FormItem>

                  <FormItem
                    hasFeedback
                    name="password"
                    rules={[
                      { min: 6, message: "Tối thiểu 6 ký tự!" },
                      {
                        whitespace: true,
                        message: "Vui lòng nhập mật khẩu!",
                      },
                      { required: true, message: "Vui lòng nhập mật khẩu!" },
                    ]}
                  >
                    <Input.Password
                      style={{
                       
                        borderRadius: 5,
                      }}
                      allowClear
                      size="large"
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Mật khẩu (*)"
                    />
                  </FormItem>

                  <FormItem
                    dependencies={["password"]}
                    hasFeedback
                    name="repassword"
                    rules={[
                      {
                        whitespace: true,
                        message: "Vui lòng xác nhận lại nhập mật khẩu!",
                      },
                      {
                        required: true,
                        message: "Vui lòng xác nhận lại nhập mật khẩu!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Mật khẩu không trùng khớp!");
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{                     
                        borderRadius: 5,
                      }}
                      allowClear
                      size="large"
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Nhập lại mật khẩu (*)"
                    />
                  </FormItem>

                  <FormItem
                    name="cityId"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn Tỉnh/Thành phố!",
                      },
                    ]}
                  >
                    <Select
                      style={{                   
                        borderRadius: 5,
                      }}
                      size="large"
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      showSearch
                      prefix={<PhoneOutlined />}
                      placeholder="Chọn Tỉnh/Thành phố (*)"
                      onChange={onChangeCityValue}
                    >
                      {cityList}
                    </Select>
                  </FormItem>

                  <FormItem
                    name="districtId"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn Quận/Huyện!",
                      },
                    ]}
                  >
                    <Select
                      style={{                     
                        borderRadius: 5,
                      }}
                      size="large"
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      showSearch
                      prefix={<PhoneOutlined />}
                      placeholder="Chọn Quận/Huyện (*)"
                      onChange={onChangeDistrictValue}
                    >
                      {districtList}
                    </Select>
                  </FormItem>

                  <FormItem
                    name="schoolId"
                    prefix={<PhoneOutlined />}
                    rules={[
                      { required: true, message: "Vui lòng chọn trường!" },
                    ]}
                  >
                    <Select
                      style={{                 
                        borderRadius: 5,
                      }}
                      size="large"
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      showSearch
                      prefix={<PhoneOutlined />}
                      placeholder="Chọn Trường (*)"
                    >
                      {schoolList}
                    </Select>
                  </FormItem>

                  <FormItem>
                    {/* <a href='' style={{ float: 'right' }}>
                  Forgot password
                </a> */}
                    <Button
                      className="mt-4"
                      color="info"
                      type="button"
                      htmlType="submit"
                      loading={loading}
                      style={{
                        width: "100%",
                        padding: 10,
                        height: "auto",
                        margin: "0px 0px 10px 0px",
                        backgroundColor: "#11CDEF",
                        fontSize: 15,
                        color: "white",
                        fontWeight: "500",
                        borderRadius: 5,
                      }}
                    >
                      Đăng ký
                    </Button>
                  </FormItem>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterStudent;
