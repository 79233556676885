import React from "react";
import { Table, Form, Rate, Select, Card } from "antd";
import { layout, tailLayout } from "packages/formLayouts";
import Notifications from "components/Notification";

import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Progress,
} from "reactstrap";
import { query } from "helpers/QueryHelper";

const FormItem = Form.Item;
function ResultClassTeacher(props) {
  const [form] = Form.useForm();
  const [state, setState] = React.useState({
    classList: [],
    classResult: [],
  });

  const loggedInUserSGK = React.useCallback(() => {
    return JSON.parse(localStorage.getItem("loggedInUserSGK"));
  }, []);

  React.useEffect(() => {
    getClass();
  }, []);

  const getClass = React.useCallback(() => {
    query(
      "p_SGK_TEACHER_Classes_Get_ByTeacherId",
      { TeacherId: loggedInUserSGK?.userId },
      "Sgk"
    ).then((result) => {
      setState((prevState) => ({ ...prevState, classList: result }));
    });
  }, []);

  const renderClass = React.useCallback(() => {
    return state.classList.map((item) => (
      <Select.Option value={item.id}>
        {item.className} - {item.schoolName}
      </Select.Option>
    ));
  }, [state.classList]);

  const onFinish = React.useCallback((value) => {
    query("p_SGK_TEACHER_View_Class_Details", value, "Sgk").then((result) => {
      Notifications("success", "Thông báo", "Tìm kiếm thành công");
      result.forEach((item, index) => {
        item.STT = index + 1;
      });
      setState((prevState) => ({ ...prevState, classResult: result }));
    });
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      key: "STT",
      width: "2%",
      align: "center",
      fixed: "left",
    },
    {
      title: "Họ và tên",
      dataIndex: "userFullName",
      key: "userFullName",
      width: "10%",
      fixed: "left",
    },
    // {
    //   title: "#",
    //   key: "operation",
    //   align: "center",
    //   fixed: "right",
    //   width: "10%",
    //   render: () => (
    //     <Button size="sm" type="primary" htmlType="submit" color="info">
    //       <span className="btn-inner--text">Thêm nhận xét</span>
    //     </Button>
    //   ),
    // },
  ];

  const columnsItem = React.useCallback(() => {
    const list = [];
    if (state.classResult.length === 0) return columns;

    for (const key in state.classResult[0]) {
      const lessonNo = list.length + 1;
      if (key.startsWith("lesson") && key.endsWith("PERCENT")) {
        list.push({
          title: `Lesson ${lessonNo}`,
          children: [
            {
              title: "Hoàn thành",
              dataIndex: `lesson ${lessonNo}-PERCENT`,
              key: "percent",
              align: "center",
              width: "5%",
              render: (item) => (
                <div className="progress-wrapper">
                  <div className="progress-info">
                    <div className="progress-label">
                      <span>Hoàn thành</span>
                    </div>
                    <div className="progress-percentage">
                      <span>{item}%</span>
                    </div>
                  </div>
                  <Progress max="100" value={item} color="default" />
                </div>
              ),
            },
            {
              title: "Đánh giá",
              dataIndex: `lesson ${lessonNo}-STAR`,
              key: "star",
              align: "center",
              render: (item) => <Rate disabled allowHalf value={item / 20} />,
            },
          ],
        });
      }
    }

    const newcolumns = columns.concat(list);

    return newcolumns;
  }, [state.classResult]);

  return (
    <>
      <Container style={{ backgroundColor: "#11CDEF" }} fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
              <a>
                <h6
                  onClick={() => props.history.push("/sgk/trang-chu")}
                  className="h2 text-white d-inline-block mb-0"
                >
                  Trang chủ
                </h6>
              </a>
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark "
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-chalkboard-teacher" />
                  </a>
                </BreadcrumbItem>

                <BreadcrumbItem
                  aria-current="page"
                  className="active text-black"
                >
                  Kết quả làm bài
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>

      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Row gutter={16}>
        <Col span={8} offset={8}>
          <Card title="GIÁO VIÊN - Xem kết quả làm bài" bordered={false}>
            {/* --------------------------------------------------------------------------------------------- */}
            {/* FORM */}
            {/* --------------------------------------------------------------------------------------------- */}

            <Form
              ref={form}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
            >
              <FormItem
                {...layout}
                name="ClassId"
                label={
                  <span
                    style={{
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.75)",
                    }}
                  >
                    Chọn Lớp
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn lớp!",
                  },
                ]}
              >
                <Select
                  style={{
                    borderRadius: 5,
                  }}
                  size="large"
                  allowClear
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  showSearch
                  // onChange={onChangeClass}
                >
                  {renderClass()}
                </Select>
              </FormItem>

              <Form.Item {...tailLayout}>
                <Button
                  size="large"
                  type="submit"
                  // htmlType="submit"
                  color="info"
                >
                  <span className="btn-inner--icon mr-2">
                    <i className="fas fa-edit" />
                  </span>
                  <span className="btn-inner--text">Xem</span>
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {state.classResult.length !== 0 && (
        <Card>
          <Table
            title={() => (
              <span style={{ fontSize: 15, fontWeight: "500" }}>
                Kết quả làm bài
              </span>
            )}
            columns={columnsItem()}
            dataSource={state.classResult}
            bordered
            size="middle"
            scroll={{ x: 3000, y: 350 }}
          />
        </Card>
      )}
    </>
  );
}
export default ResultClassTeacher;
