/*!

=========================================================
* WOO Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.tony-woo.com/product/woo-dashboard-pro-react
* Copyright 2020 Tony Woo (https://www.tony-woo.com)

* Coded by Tony Woo

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Collapse, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

import Notification from "./Notification";
import QuickLinks from "./QuickLinks";
import Profile from "./Profile";
import { SearchForm } from "./SearchForm";
import NotificationAlert from "react-notification-alert";
import { notificationAlert } from "variables/common";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("loggedInUserSGK")),
    };
  }

  render() {
    const { userInfo } = this.state;
    if (!userInfo) {
      this.props.history.push("/auth/login");
    } 
    return (
      <>
       <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              {/* SEARCh FORM */}
              <SearchForm theme={this.props.theme} />

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>

                <NavItem className="d-sm-none">
                  <NavLink
                    onClick={() => {
                      document.body.classList.add("g-navbar-search-showing");
                      setTimeout(function () {
                        document.body.classList.remove(
                          "g-navbar-search-showing"
                        );
                        document.body.classList.add("g-navbar-search-show");
                      }, 150);
                      setTimeout(function () {
                        document.body.classList.add("g-navbar-search-shown");
                      }, 300);
                    }}
                  >
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>

                {/* NOTIFICATION */}
                {/* <Notification /> */}

                {/* QUICK LINKS */}
                {/* <QuickLinks /> */}
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                {/* PROFILE */}

                <Profile {...this.props} userInfo={userInfo} />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
