/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Alternative from "views/pages/dashboards/Alternative.js";
// import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
// import Cards from "views/pages/components/Cards.js";
// import Charts from "views/pages/Charts.js";
// import Components from "views/pages/forms/Components.js";
import Dashboard from "modules/ClassModule/components/ClassComponent";
import Sessions from "modules/SessionModule/components/SessionComponent";
import Assignments from "modules/AssignmentModule/components/AssignmentComponent";
import Questions from "modules/QuestionModule";
import Results from "modules/ResultModule";
import Video from "modules/QuestionModule/Video";
import Audio from "modules/QuestionModule/Audio";
import CreateClass from "modules/TeacherModule/CreateClass";
import AddStudent from "modules/TeacherModule/AddStudentsToClass";
import ResultClass from "modules/TeacherModule/ResultClass";
import ScoreResult from "modules/ParentModule/ScoreResult";
import StudentJoinClass from "modules/StudentModule/JoinClass";
// import Elements from "views/pages/forms/Elements.js";
// import Google from "views/pages/maps/Google.js";
// import Grid from "views/pages/components/Grid.js";
// import Icons from "views/pages/components/Icons.js";
// import Lock from "views/pages/examples/Lock.js";
// import Login from "views/pages/examples/Login.js";
// import Notifications from "views/pages/components/Notifications.js";
import Login from "./modules/Login/components/FormLogin";
import ActiveCourses from "components/Error/ActiveCode";
import RegisterStudent from "./modules/Login/components/RegisterStudent";
import RegisterTeacher from "./modules/Login/components/RegisterTeacher";
import Profile from "modules/profileModule/ChangeProfile/components/Profile";
// import ReactBSTables from "views/pages/tables/ReactBSTables.js";
// import Register from "views/pages/examples/Register.js";
// import RTLSupport from "views/pages/examples/RTLSupport.js";
// import Sortable from "views/pages/tables/Sortable.js";
// import Tables from "views/pages/tables/Tables.js";
// import Timeline from "views/pages/examples/Timeline.js";
// import Typography from "views/pages/components/Typography.js";
// import Validation from "views/pages/forms/Validation.js";
// import Vector from "views/pages/maps/Vector.js";
// import Widgets from "views/pages/Widgets.js";

const routes = [
  {
    path: "/trang-chu",
    name: "Trang chủ",
    miniName: "D",
    component: Dashboard,
    icon: "fas fa-home text-info",
    layout: "/sgk",
    exact: true,
  },

  {
    path: "/classes/:classId/sessions",
    name: "session",
    miniName: "S",
    icon: "ni ni-ungroup text-orange",
    component: Sessions,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/classes/:classId/session/:sessionId/assignments",
    name: "Assignments",
    miniName: "C",
    icon: "ni ni-ungroup text-orange",
    component: Assignments,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/classes/:classId/session/:sessionId/assignment/:assignmentId/questions",
    name: "questions",
    miniName: "Q",
    icon: "ni ni-ungroup text-orange",
    component: Questions,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/classes/:classId/session/:sessionId/assignment/:assignmentId/results",
    name: "results",
    miniName: "Q",
    icon: "ni ni-ungroup text-orange",
    component: Results,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/classes/:classId/session/:sessionId/video",
    name: "Video",
    miniName: "C",
    icon: "ni ni-ungroup text-orange",
    component: Video,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/classes/:classId/session/:sessionId/audio",
    name: "Audio",
    miniName: "C",
    icon: "ni ni-ungroup text-orange",
    component: Audio,
    layout: "/sgk",
    exact: true,
  },
  //Teacher
  {
    path: "/teacher/create-class",
    name: "Create-Class",
    miniName: "C",
    icon: "ni ni-ungroup text-orange",
    component: CreateClass,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/teacher/class/:classId/add-student",
    name: "Thêm học sinh",
    miniName: "C",
    icon: "ni ni-hat-3",
    component: AddStudent,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/teacher/Result-Student-In-Class",
    name: "Result-Student-In-Class",
    miniName: "C",
    icon: "ni ni-ungroup text-orange",
    component: ResultClass,
    layout: "/sgk",
    exact: true,
  },
  //Parent
  //Student StudentJoinClass
  {
    path: "/student/Result",
    name: "Kết quả học tập",
    miniName: "R",
    icon: "ni ni-single-copy-04 text-orange",
    component: ScoreResult,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/student/Join-Class",
    name: "Tham gia lớp học",
    miniName: "R",
    icon: "fas fa-user-graduate text-danger",
    component: StudentJoinClass,
    layout: "/sgk",
    exact: true,
  },
  //Active classes
  {
    path: "/activeCourses",
    name: "Kích hoạt khóa học",
    miniName: "C",
    icon: "ni ni-active-40 text-info",
    component: ActiveCourses,
    layout: "/sgk",
    exact: true,
  },
  {
    path: "/calendar",
    name: "Lịch học",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/sgk",
  },

  {
    path: "/profile",
    name: "Profile",
    miniName: "P",
    icon: "ni ni-circle-08 text-primary",
    component: Profile,
    layout: "/sgk",
  },
  // {
  //   collapse: true,
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   views: [
  //     {
  //       path: "/trang-chu",
  //       name: "Dashboard",
  //       miniName: "D",
  //       component: Dashboard,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/alternative-dashboard",
  //       name: "Alternative",
  //       miniName: "A",
  //       component: Alternative,
  //       layout: "/sgk"
  //     }
  //   ]
  // },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-info",
    state: "examplesCollapse",
    views: [
      {
        path: "/RegisterStudent",
        name: "RegisterStudent",
        miniName: "P",
        component: RegisterStudent,
        layout: "/auth",
      },
      {
        path: "/RegisterTeacher",
        name: "RegisterTeacher",
        miniName: "P",
        component: RegisterTeacher,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      // {
      //   path: "/register",
      //   name: "Register",
      //   miniName: "R",
      //   component: Register,
      //   layout: "/auth"
      // },
      // {
      //   path: "/lock",
      //   name: "Lock",
      //   miniName: "L",
      //   component: Lock,
      //   layout: "/auth"
      // },
      //     {
      //       path: "/timeline",
      //       name: "Timeline",
      //       miniName: "T",
      //       component: Timeline,
      //       layout: "/sgk"
      //     },
      //     {
      //       path: "/profile",
      //       name: "Profile",
      //       miniName: "P",
      //       component: Profile,
      //       layout: "/sgk"
      //     },
      //     {
      //       path: "/rtl-support",
      //       name: "RTL Support",
      //       miniName: "RS",
      //       component: RTLSupport,
      //       layout: "/rtl"
      //     }
    ],
  },
  // class

  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       miniName: "B",
  //       component: Buttons,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       miniName: "C",
  //       component: Cards,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       miniName: "G",
  //       component: Grid,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       miniName: "N",
  //       component: Notifications,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       miniName: "I",
  //       component: Icons,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       miniName: "T",
  //       component: Typography,
  //       layout: "/sgk"
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       miniName: "M",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/"
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/"
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       miniName: "E",
  //       component: Elements,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       miniName: "V",
  //       component: Validation,
  //       layout: "/sgk"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       miniName: "T",
  //       component: Tables,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       miniName: "S",
  //       component: Sortable,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       miniName: "RBT",
  //       component: ReactBSTables,
  //       layout: "/sgk"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/sgk"
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/sgk"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/sgk"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/sgk"
  // },
 
];

export default routes;
