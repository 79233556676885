import React from "react";
import { useSelector } from "react-redux";
import { Result, Steps, Input, Form, Space, Row, Col} from "antd";
import { CodepenCircleOutlined, BarcodeOutlined } from "@ant-design/icons";
import axios from "axios";
import { Button } from "reactstrap";
import openNotificationWithIcon from "components/Notification";

const { Step } = Steps;
const FormItem = Form.Item;
const styleBox = {
  boxShadow: "0 5px 10px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
  borderRadius: 5,
  marginTop: "2%",
  width: 120,
  height: 30,
};

const ActiveCode = (props) => {
  const [state, setState] = React.useState({
    loading: false,
    isDisabled: false,
    status: "process",
    wait: "wait",
  });
  const loggedInUserSGK = JSON.parse(localStorage.getItem("loggedInUserSGK"));

  const handleSubmit = async (values) => {
    const userId = loggedInUserSGK.userId;
    const License = `${values.code1}-${values.code2}-${values.code3}`;
    postCheckCodeApi(userId, License);
    //
  };

  const postCheckCodeApi = async (userId, LicenceKey) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      isDisabled: true,
    }));
    const body = {
      userId,
      LicenceKey,
    };
    await axios
      .post(
        "https://cloud.softech.vn/mobile/SGK/api/AddCourseByLicenceKey",
        body
      )
      .then((response) => {
        if (response.data.message === "OK") {
          setState((prevState) => ({
            ...prevState,
            status: "finish",
            wait: "finish",
          }));
          openNotificationWithIcon(
            "success",
            "Thông báo",
            response.data.description
          );
          setTimeout(() => {
            // props.history.push(`${JRoutes.HOME}`);
            window.location.reload(true);
          }, 2000);
          // props.history.push(`${JRoutes.HOME}classes`);
        }
        if (response.data.message === "ERROR") {
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              isDisabled: false,
            }));
            openNotificationWithIcon(
              "danger",
              "Thông báo",
              response.data.description
            );
            // props.history.push(`${JRoutes.HOME}`);
          }, 1000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          openNotificationWithIcon(
            "danger",
            "Thông báo",
            "Có lỗi xảy ra vui lòng thử lại"
          );
          setState((prevState) => ({
            ...prevState,
            loading: false,
            isDisabled: false,
          }));
        }, 1000);

        console.log(error);
      })
      .finally(function () {});
  };

  const [form] = Form.useForm();
 
  return (
   <Row justify='center'>
     <Col>
        {/* <Steps>
          <Step status='finish' title='Đăng ký' description='Đã hoàn thành' />
          <Step status={state.status} title='Active khóa học' icon={<CodepenCircleOutlined />} />
          <Step status={state.wait} title='Hoàn thành' icon={<SmileOutlined />} />
        </Steps> */}
        <Result
          icon={<CodepenCircleOutlined/>}
          // icon={<img style={{width:'50%',height:'50%'}} src={imgLogo} alt={'logo'} />}
          title="Nhập mã kích hoạt khóa học!"
          extra={
            <div>
              <Form
                autoComplete="off"
                form={form}
                onFinish={handleSubmit}
                style={{ minWidth: 300 }}
              >
                <Space>
                  <FormItem
                    name="code1"
                    rules={[{ required: true, message: " " }]}
                  >
                    <Input
                      id={1}
                      autoComplete="off"
                      disabled={state.isDisabled}
                      autoFocus
                      style={styleBox}
                      size="small"
                      maxLength={4}
                      prefix={
                        <BarcodeOutlined
                          style={{ borderRadius: 10, color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      // placeholder='Nhập mã kích hoạt (*)'
                    />
                  </FormItem>
                  <FormItem
                    name="code2"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mã kích hoạt!",
                      },
                    ]}
                  >
                    <Input
                      id={2}
                      autoComplete="off"
                      disabled={state.isDisabled}
                      // autoFocus
                      style={styleBox}
                      size="small"
                      maxLength={4}
                      // placeholder='Nhập mã kích hoạt (*)'
                    />
                  </FormItem>
                  <FormItem
                    name="code3"
                    rules={[{ required: true, message: " " }]}
                  >
                    <Input
                      id={3}
                      autoComplete="off"
                      disabled={state.isDisabled}
                      // autoFocus
                      style={styleBox}
                      size="small"
                      maxLength={4}
                      // placeholder='Nhập mã kích hoạt (*)'
                    />
                  </FormItem>
                </Space>
                <p>
                  <Button
                    disabled={state.loading}
                 
                    type="submit"
                    // htmlType="submit"
                    color="info"
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="fas fa-edit" />
                    </span>
                    <span className="btn-inner--text">Kích hoạt</span>
                  </Button>
                </p>
              </Form>
            </div>
          }
        />
        </Col>
     </Row>
  );
};

export default ActiveCode;
