import React from "react";
// import moment from "moment";
// import classNames from "classnames";
import { Row, Col, List, Avatar, Rate } from "antd";
// import { default as LogModitem } from "../LogModule/components/LogModitem";
import { default as functions } from "components/functions";
import { ListGroup, ListGroupItem } from "reactstrap";
const LogAssi = React.memo(
  class LogContent extends React.Component {
    constructor(props) {
      super(props);
      this.refLogModitem = React.createRef();
      this.state = { item: {} };
    }


    onShowLog = (item) => {
      this.setState({ item }, () => {
        this.refLogModitem.current.setState({ visible: true });
      });
    };

    notShowLog = () => { };

    renderTypeMatching = (item, index) => {
      const no = index + 1;
      return (
        <>
          <ListGroupItem>
            <Row gutter={24} style={{ width: "100%" }}>
              <Col span={4}>
                <Avatar style={{ backgroundColor: '#5E76E5' }}>{no < 10 ? `0${no}` : no}</Avatar>
              </Col>
              <Col span={18}>
                <span>Câu số: {no < 10 ? `0${no}` : no}</span><br/>
                <Rate
                  disabled
                  value={functions.getStarRecord(item.score)}
                  allowHalf
                />
              </Col>
              <Col span={2} style={{ marginTop: 6 }}>
                <span>{parseInt(item.score)}%</span>
              </Col>
            </Row>
          </ListGroupItem>
        </>
      );
    }

    renderTitle = (item, questionType) => {

      switch (questionType) {
        case "RepeatTheSentence": {
          return (
            <div>{JSON.parse(item.studentChoices).originitemSentence}</div>
          );
        }
        case "GRAMMAR": {
          return <div>{item.grammarQuestion[0].correctAnswerText}</div>;
        }
        case "ConversationOnePerson": {
          return <div></div>;
        }
        case "MatchingWordWithPicture": {
          return <div></div>;
        }
        case "OneTextMultiOptionOneCorrect": {
          return <div></div>;
        }
        case "MatchingWordWithSound": {
          return <div></div>;
        }
        case "MatchingSoundWithPicture": {
          return <div></div>;
        }
        default: {
          return <div>{item.vocabularyQuestion[0].text}</div>;
        }
      }
    };

    renderItem = (resultsList) => {
      return resultsList.map((item, index) => {
        const no = index + 1;
        const isMatching = item.answerType === "MATCHING";
        if (isMatching) {
          return this.renderTypeMatching(item, index);
        }
        return (
          <>
            <ListGroupItem>
              <Row gutter={24} style={{ width: "100%" }}>
                <Col span={4}>
                  <Avatar style={{ backgroundColor: '#5E76E5' }}>{no < 10 ? `0${no}` : no}</Avatar>
                </Col>
                <Col span={18}>
                  {this.renderTitle(item, item.answerType)}
                  <Rate
                    disabled
                    value={functions.getStarRecord(item.score)}
                    allowHalf
                  />
                </Col>
                <Col span={2} style={{ marginTop: 6 }}>
                  <span>{parseInt(item.score)}%</span>
                </Col>
              </Row>
            </ListGroupItem>
          </>
        );
      });
    };

    render = () => {
      let { results, location } = this.props;
      const resultsList = (results && JSON.parse(results[0].resultLogs)) || [];

      return (
        <React.Fragment>
          <ListGroup>
            <ListGroupItem style={{ backgroundColor: '#5E76E5', borderWidth: 0, fontSize: 20, fontWeight: "500" }} className="active text-center">ĐÁNH GIÁ CHI TIẾT</ListGroupItem>
            {this.renderItem(resultsList)}
          </ListGroup>

          {/* 
                |--------------------------------------------------
                | Moditem show detai result
                |--------------------------------------------------
                */}
          {/* <LogModitem
            type_log={"logAssi"}
            logAnswer={item}
            location={location}
            ref={this.refLogModitem}
          /> */}
        </React.Fragment>
      );
    };
  }
);

export default LogAssi;
