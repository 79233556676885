import React from "react";

// import { default as Loading } from 'src/components/Loading';
import * as ActionTypes from "../actions/types";
import ActiveCode from "components/Error/ActiveCode";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  CardImg,
  CardText,
  Row,
  Col,
  Alert,
  CardFooter
} from "reactstrap";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "components/Error/Loading";
import CardTask from "./CardTask";

class ClassComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
      data: [],
      loading: false,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading,
        data: props.data,
      };
    }

    return null;
  };

  componentDidMount = () => {
    this.getClasses();
  };

  getClasses = async () => {
    const { loggedInUserSGK } = this.state;
    if (loggedInUserSGK) {
      this.props.fetchClass(loggedInUserSGK.userId);
      // this.setState({loading: true})
    }
  };

  renderItem = () => {
    const { data } = this.state;

    return data.map((item, index) => {
      const link = `/sgk/classes/${item.amesCourseId}/sessions`;
      return (
        <Link to={link}>
          <Card style={{ maxWidth: 350 }}>
            <CardImg
              style={{ maxHeight: 400 }}
              alt="..."
              src={item.imageUrl}
              top
            />

            <CardBody>
              <CardTitle className="h2 mb-0">{item.courseName}</CardTitle>
              <small className="text-muted">
                Cấu trúc xây dựng bài học thông minh: chú trọng vào cả 4 kĩ năng
                Nghe, Nói, Đọc, Viết
              </small>
              <CardText className="mt-4">
                {/* Argon is a great free UI package based on Bootstrap 4 that
                includes the most important components and features. */}
              </CardText>
              <Button
                className="btn-icon"
                color="primary"
                // size="lr"
                type="button"
              >
                <span className="btn-inner--icon mr-0">
                  <i className="fas fa-info-circle" />
                </span>
                <span className="btn-inner--text">Chi tiết</span>
              </Button>
            </CardBody>
          </Card>
        </Link>
      );
    });
  };

  renderItemTeacher = () => {
    const imgSrc = require('../../.././assets/img/01.jpg');
      return (
        <a href='https://sachdientu.softech.cloud/' target='_blank'>
          <Card style={{ maxWidth: 350 }}>
            <CardImg
              style={{ maxHeight: 400 }}
              alt="..."
              src={imgSrc}
              top
            />

            <CardBody>
              <CardTitle className="h2 mb-0">Tiếng Anh English Plus 1</CardTitle>
              <small className="text-muted">
                Cấu trúc xây dựng bài học thông minh: chú trọng vào cả 4 kĩ năng
                Nghe, Nói, Đọc, Viết
              </small>
              <CardText className="mt-4">
                {/* Argon is a great free UI package based on Bootstrap 4 that
                includes the most important components and features. */}
              </CardText>
              <CardFooter className='text-left'>
              <Button
                className="btn-icon"
                color="primary"
                // size="lr"
                type="button"
              >
                <span className="btn-inner--icon mr-0">
                  <i className="fas fa-info-circle" />
                </span>
                <span className="btn-inner--text">Chi tiết</span>
              </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </a>
      );
  };

  render() {
    const { data, loading, loggedInUserSGK } = this.state;

    if (loading) {
      return <Loading {...this.props} />;
    }

    if (data.length === 0) {
      return loggedInUserSGK.userRoles.includes("Student") ? (
        <ActiveCode {...this.props} />
      ) : (
        <>
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h5 className="h2 d-inline-block mb-0">SÁCH GIÁO KHOA</h5>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Trang chủ
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
            <Row>
              <CardTask
                title="Giáo viên tạo lớp"
                colorTask="info"
                colorIcon="red"
                icon="fas fa-user-edit"
                linkTo="/sgk/teacher/create-class"
                {...this.props}
              />

              <CardTask
                title="Kết quả làm bài"
                colorTask="danger"
                colorIcon="green"
                icon="fas fa-poll"
                linkTo="/sgk/teacher/Result-Student-In-Class"
                {...this.props}
              />
            </Row>
            <div className="card-deck">{this.renderItemTeacher()}</div>
          </Container>
        </>
      );
    }

    return (
      <>
        <div
          style={{ backgroundColor: "#f1f2f6" }}
          className="header pb-6"
        ></div>
        <Container
          style={{ backgroundColor: "#f1f2f6" }}
          className="mt--7"
          fluid
        >
          {/* <Breadcrumb listClassName="breadcrumb-dark bg-dark">
            <BreadcrumbItem aria-current="page" className="active">
              Home
            </BreadcrumbItem>
          </Breadcrumb> */}

          <Row className="mt-5">
            <CardTask
              title="Active khóa học"
              colorTask="info"
              colorIcon="success"
              icon="fas fa-hand-point-up"
              linkTo="/sgk/activeCourses"
              {...this.props}
            />
            <CardTask
              title="Kết quả học tập"
              colorTask="danger"
              icon="fas fa-poll"
              colorIcon="warning"
              linkTo="/sgk/student/Result"
              {...this.props}
            />

            <CardTask
              title="Tham gia lớp học"
              colorTask="primary"
              icon="fas fa-users"
              colorIcon="warning"
              linkTo="/sgk/student/Join-Class"
              {...this.props}
            />
          </Row>

          <Row>
        <Col>
          <Card className='bg-info'>
            <CardBody className="text-white" tag="h2">
              <Link to="/ames">
                <h6 className="h2 text-white d-inline-block mb-0">HOME PAGE</h6>
              </Link>
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-book-open" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Danh sách khóa học đã kích hoạt
                </BreadcrumbItem>
              </Breadcrumb>
            </CardBody>
          </Card>
        </Col>
      </Row>

          {/* CLASSS */}
          <div className="card-deck">{this.renderItem()}</div>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.classReducer.data,
  loading: state.classReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({
  fetchClass: (userId) => dispatch({ type: ActionTypes.FETCH_CLASS, userId }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClassComponent);
