
const BASE_ICON = {
  LISTEN: require("assets/icon/listen_32x32.png"),
  LOOK: require("assets/icon/look_2.png"),
  MATCH: require("assets/icon/match_32x32.png"),
  NEWWORD: require("assets/icon/newword.png"),
  SPEAK: require("assets/icon/speak_2_32x32.png"),
  WRITE: require("assets/icon/write.png")
};

function AssignmentIcons(questionType) {
  switch (questionType) {
    case "ListenAndRepeat":
      return [BASE_ICON.LISTEN, BASE_ICON.SPEAK];

    case "OneCorrectQuestionText":
      return [BASE_ICON.LISTEN, BASE_ICON.MATCH];
   
    case "OneTextMultiOptionOneCorrect":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];

    case "RepeatTheWords":
      return [BASE_ICON.LOOK, BASE_ICON.LISTEN, BASE_ICON.SPEAK];
  
    case "RepeatTheSentence":
      return [BASE_ICON.LISTEN, BASE_ICON.LOOK, BASE_ICON.SPEAK];
    case "ScrambleWord":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];
    case "MakeASentence":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];
    case "MatchingWordWithPicture":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];
    case "CompleteWord":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];
   
    case "Grammar":
      return [BASE_ICON.LOOK, BASE_ICON.MATCH];

    default:
      return null;
  }
}

export default AssignmentIcons;
