/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Tabs, Form, Input } from "antd";
import CrypteJs from "crypto-js";
import axios from "axios";
import openNotificationWithIcon from "components/Notification";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

const FormItem = Form.Item;
const { TabPane } = Tabs;

const Profile = () => {
  const loggedInUserSGK = JSON.parse(localStorage.getItem("loggedInUserSGK"));

  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    // console.log("handleSubmit -> values", values);
    //////////
    const { userId } = loggedInUserSGK;
    // console.log("handleSubmit -> loggedInUserSGK", loggedInUserSGK);
    const { oldPassword, password } = values;
    const encryptOldPassword = CrypteJs.MD5(oldPassword).toString();
    const encryptNewPassword = CrypteJs.MD5(password).toString();

    const body = {
      userId,
      Password: encryptOldPassword,
      NewPassword: encryptNewPassword,
    };
    // console.log("handleSubmit -> body", body)
    await axios
      .post(
        "https://cloud.softech.cloud/mobile/SGK/api/ChangePassword",
        body
      )
      .then((response) => {
        // console.log("handleSubmit -> response", response);
        if (response.data.message === "OK") {
          openNotificationWithIcon(
            "success",
            "Thông báo",
            response.data.description
          );

          // props.history.push(`${JRoutes.HOME}classes`);
        }
        if (response.data.message === "ERROR") {
          openNotificationWithIcon(
            "danger",
            "Thông báo",
            response.data.description
          );
          // props.history.push(`${JRoutes.HOME}`);
        }
      })
      .catch((error) => {
        openNotificationWithIcon(
          "danger",
          "Thông báo",
          "Có lỗi xảy ra vui lòng thử lại"
        );

        console.log(error);
      })
      .finally(function () {});
  };

  return (
    <>
      <ProfileHeader loggedInUserSGK={loggedInUserSGK} />
      <Container className="mt--5" fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="10">
            <Tabs
              type="line"
              tabBarStyle={{
                backgroundColor: "white",
                borderRadius: 12,
                padding: "10px 40px",
              }}
              animated
              tabPosition="top"
              defaultActiveKey="1"
              size="large"
              style={{ marginBottom: 32 }}
            >
              <TabPane tab="Tài khoản" key="1">
                <Card className="mt-5">
                  <CardHeader>
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <label htmlFor="upload-button">
                            <img
                              alt="..."
                              className="rounded-circle"
                              style={{ backgroundSize: "cover" }}
                              src={require("assets/img/avatar.jpg")}
                            />
                          </label>
                          <input
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Cập nhật thông tin</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Cập nhật
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Thông tin người dùng
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Tên đăng nhập
                              </label>
                              <Input
                                style={{ borderRadius: 5 }}
                                size="large"
                                defaultValue={loggedInUserSGK.fullName}
                                id="input-username"
                                placeholder="Username"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <Input
                                style={{ borderRadius: 5 }}
                                size="large"
                                defaultValue={loggedInUserSGK.email}
                                id="input-email"
                                placeholder="jesse@example.com"
                                type="email"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Họ tên
                              </label>
                              <Input
                                style={{ borderRadius: 5 }}
                                size="large"
                                defaultValue={loggedInUserSGK.fullName}
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Số điện thoại
                              </label>
                              <Input
                                style={{ borderRadius: 5 }}
                                size="large"
                                defaultValue={loggedInUserSGK.phone}
                                id="input-last-name"
                                placeholder="Last name"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {/* <hr className="my-4" /> */}
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>
              {/* //////////////////////////////////////////////////////////////////////////// */}
              {/* //////////////////////////////////////////////////////////////////////////// */}
              {/* //////////////////////////////////////////////////////////////////////////// */}
              <TabPane tab="Mật khẩu" key="2">
                <Card className="mt-1">
                  <CardBody>
                    <Form form={form} onFinish={handleSubmit}>
                      <h6 className="heading-small text-muted mb-4">
                        Đổi mật khẩu
                      </h6>
                      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Mật khẩu cũ
                        </label>
                        <FormItem
                          hasFeedback
                          name="oldPassword"
                          rules={[
                            { min: 6, message: "Tối thiểu 6 ký tự!" },
                            {
                              whitespace: true,
                              message: "Vui lòng nhập mật khẩu cũ!",
                            },
                            {
                              required: true,
                              message: "Vui lòng nhập mật khẩu cũ!",
                            },
                          ]}
                        >
                          <Input.Password
                            style={{
                              borderRadius: 5,
                            }}
                            allowClear
                            size="large"
                          />
                        </FormItem>
                      </FormGroup>
                      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Mật khẩu mới
                        </label>
                        <FormItem
                          hasFeedback
                          name="password"
                          rules={[
                            { min: 6, message: "Tối thiểu 6 ký tự!" },
                            {
                              whitespace: true,
                              message: "Vui lòng nhập mật khẩu!",
                            },
                            {
                              required: true,
                              message: "Vui lòng nhập mật khẩu!",
                            },
                          ]}
                        >
                          <Input.Password
                            style={{
                              borderRadius: 5,
                            }}
                            allowClear
                            size="large"
                          />
                        </FormItem>
                      </FormGroup>
                      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Xác nhận khẩu mới
                        </label>
                        <FormItem
                          dependencies={["password"]}
                          hasFeedback
                          name="rePassword"
                          rules={[
                            {
                              whitespace: true,
                              message: "Vui lòng xác nhận lại nhập mật khẩu!",
                            },
                            {
                              required: true,
                              message: "Vui lòng xác nhận lại nhập mật khẩu!",
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Mật khẩu không trùng khớp!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            style={{
                              borderRadius: 3,
                            }}
                            allowClear
                            size="large"
                          />
                        </FormItem>
                      </FormGroup>

                      <Button type="submit" color="primary">
                        <span className="btn-inner--icon mr-2">
                          <i className="fas fa-edit" />
                        </span>
                        <span className="btn-inner--text">Cập nhật</span>
                      </Button>

                      {/* <hr className="my-4" /> */}
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
