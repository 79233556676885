import React from "react";
import { Card, CardBody, CardTitle, Progress, Row , Col} from "reactstrap";

const CardTask = (props) => {
  return (
    <>
      <Col tag='a' xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
        <Card  onClick={() => props.history.push(props.linkTo)} className={`bg-gradient-${props.colorTask} shadow border-0`}>
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0 text-white"
                >
                  {props.title}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0 text-white">
                  8/24
                </span>
                <Progress
                  className="progress-xs mt-3 mb-0"
                  max="100"
                  value="30"
                  color="success"
                />
              </div>
              <Col className="col-auto">
                <div className={`icon icon-shape bg-gradient-${props.colorIcon} text-white rounded-circle shadow`}>
                  <i className={props.icon} />
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-sm">
              <a
                className="text-nowrap text-white font-weight-600"
                onClick={() => props.history.push(props.linkTo)}
              >
                Xem chi tiết
              </a>
            </p>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CardTask;
