import React, { Component } from "react";

import { connect } from "react-redux";

import Loading from "components/Error/Loading";
import NotData from "components/Error/NotData";
import * as ActionTypes from "../actions/types";
import {
  ListGroupItem,
  CardBody,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Card, Avatar } from "antd";
const { Meta } = Card;

class Audio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      listAudio: [],
      classItem: null,
      indexAudio: 0,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading,
        listAudio: props.listAudio,
      };
    }
    return null;
  };

  componentDidMount = () => {
    const { getAudio, match } = this.props;
    const { sessionId } = match.params;
    getAudio(sessionId);
  };

  playAudio = (index) => {
    this.setState({ indexAudio: index });
  };
  renderListAudio = () => {
    const { listAudio } = this.state;
    const list = listAudio.map((item, index) => {
      const color = ["warning", "success", "danger", "info", "default"];
      const randomIndex = Math.floor(Math.random(color) * 5);
      const name = `checklist-item checklist-item-${color[randomIndex]}`;
      return (
        <ListGroupItem
          key={index}
          onClick={() => this.playAudio(index)}
          className="checklist-entry flex-column align-items-start py-4 px-4 card-lift--hover"
        >
          <div className={name}>
            <div className="checklist-info">
              <h5 className="checklist-title mb-0">{item.name}</h5>
              <small>{item.fileType}</small>
            </div>
            <div>
              <div className="custom-control custom-checkbox custom-checkbox-warning">
                <i className="fas fa-play-circle" />
              </div>
            </div>
          </div>
        </ListGroupItem>
      );
    });
    return list;
  };

  render() {
    const { listAudio, loading, indexAudio } = this.state;
    const { match } = this.props;
    const { classId } = match.params;
    const linkBackSession = `/sgk/classes/${classId}/sessions`;

    if (loading) {
      return <Loading />;
    }

    if (!loading && listAudio.length === 0) {
      return <NotData />;
    }

    return (
      <React.Fragment>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                  <h6 className="h2 text-white d-inline-block mb-0">SESSION</h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-book-open" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        onClick={() =>
                          this.props.history.push("/sgk/trang-chu")
                        }
                      >
                        Classes
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        onClick={() => this.props.history.push(linkBackSession)}
                      >
                        Sessions
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      Audio
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container >
          <Row>
            <Col>
              <Card
                className="shadow border-1"
                style={{ width: "100%" }}
                cover={
                  <img
                    alt="example"
                    src={"https://c1.wallpaperflare.com/preview/73/599/584/headphones-music-ear-defender-yellow.jpg"}
                  />
                }
              >
                <Meta
                  avatar={
                    <i className="fas fa-headphones"/>
                  }
                  title={
                    <>
                      <div style={{ color: "#5D6670" }}>
                        <h4 className="mb-0">
                          Track: {listAudio[indexAudio].name}
                        </h4>
                      </div>
                      
                    </>
                  }
                  description={
                    <>
                    
                      <div style={{ color: "#5D6670" }}>
                        <audio
                          style={{ maxWidth: 280 }}
                          controls
                          src={listAudio[indexAudio].url}
                          autoPlay={true}
                          preload="auto"
                          controlsList="nodownload"
                        ></audio>
                      </div>
                    </>
                  }
                />
              </Card>
            </Col>

            <Col>
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Danh sách Audio</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup data-toggle="checklist" flush>
                    {this.renderListAudio()}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  listAudio: state.questionReducer.audio,
  loading: state.questionReducer.loadingAudio,
  // userInfo: state.loginReducer
});

const mapDispatchToProps = (dispatch) => ({
  getAudio: (sessionId) => dispatch({ type: ActionTypes.GET_AUDIO, sessionId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audio);
