import * as actionTypes from '../actions/types';

const initialSessions = {
	loading: true,
	data: []
};

const sessionReducer = (state = initialSessions, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SESSION_REQUEST:
			return { ...state, loading: true, error: false };

		case actionTypes.FETCH_SESSION_SUCCESS: {
			if (action.payload.length === 0) {
				return { ...state, data: [], error: false, loading: false };
			}
			else {
				return { ...state, data: action.payload, error: false, loading: false };
			}
		}
		case actionTypes.FETCH_SESSION_FAILURE:
			return { ...state, loading: false, error: true, message: action.payload, data: [] };

		default:
			return state;
	}
};
export default sessionReducer;
