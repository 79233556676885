import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import * as __typeText from "../text";
import { default as functions } from "components/functions";
import queryString from "query-string";
import moment from "moment";
import "./styles.css";
import { Row } from "antd";

import AMES247Loading from "components/Error/Loading";
import { Button } from "reactstrap";
import * as ActionTypes from "../actions/types";
import NotData from "components/Error/NotData";
import { resultAudio } from "components/functions";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import openNotificationWithIcon from "components/Notification";

class Type14 extends React.Component {
  constructor(props) {
    super(props);
    this.resultAnswer = "";
    this.correctText = "";

    const alphabet = [];
    const characters = [];

    this.state = {
      show: "",
      loading: true,
      question: null,
      questions: [],
      styleImage: {
        width: 400,
      },
      time: 40,
      characters,
      alphabet,
      correctText: this.correctText,
      timeStart: null,
      timerKey: Date.now(),
      resetCountDown: [false, ""],
      arrayCharQuestionText: [],
    };

    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.questions !== state.questions) {
      return {
        questions: props.questions,
      };
    }
    return null;
  };

  componentDidMount = () => {
    const { questions } = this.state;
   
    if(typeof questions[this.questionIndex].examplesFormat !== 'string') return;
    const arrayCharQuestionText = JSON.parse(
      questions[this.questionIndex].examplesFormat
    );

    let tmpData = this.getCharacters(
      arrayCharQuestionText.sentences[this.questionIndex].text
    );
   
    let { alphabet, characters } = tmpData;
    this.setState({
      characters,
      alphabet,
      arrayCharQuestionText: arrayCharQuestionText.sentences,
      question: arrayCharQuestionText.sentences[this.questionIndex],
      loading: false,
      timeStart: moment().format(),
    });
  };

  onLoadImage = ({ target: img }) => {
    if (img.offsetWidth > 490) {
      this.setState({
        styleImage: {
          width: "15%",
        },
      });
    }
  };

  getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  getCharacters = (data) => {
    const dataSplit = data.split(" ");

    const alphabet = [];
    for (let i = 0; i < dataSplit.length; i++) {
      alphabet.push({
        id: i,
        selectedId: 0,
        character: dataSplit[i],
        status: "on",
      });
    }

    const characters = [];
    for (let i = 0; i < dataSplit.length; i++) {
      characters.push({
        id: i,
        selectedId: 0,
        character: "",
        status: "off",
      });
    }

    this.Random(alphabet);

    return { characters, alphabet };
  };

  removeCharacter = (item) => {
    const characters = this.state.characters.slice();
    const index = characters.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      // UPDATE ALPHABET
      const alphabet = this.state.alphabet.slice();
      const indexAlphabet = alphabet.findIndex(
        (x) => x.id === characters[index].selectedId
      );
      if (indexAlphabet >= 0) {
        alphabet[indexAlphabet].status = "on";
        this.setState({ alphabet, alphabet });
      }

      // UPDATE CHARACTERS
      characters[index].status = "off";
      characters[index].character = "";
      characters[index].selectedId = 0;
      this.setState({
        characters,
        show: this.state.characters.map((x) => x.character).join(" "),
      });
    }
  };

  addCharacter = (item) => {
    const alphabet = this.state.alphabet.slice();
    const indexAlphabet = alphabet.findIndex((x) => x.id === item.id);
    if (indexAlphabet >= 0) {
      alphabet[indexAlphabet].status = "off";

      var characters = this.state.characters.slice();
      const index = characters.findIndex((x) => x.status === "off");
      if (index >= 0) {
        characters[index].status = "on";
        characters[index].character = item.character;
        characters[index].selectedId = item.id;
        this.setState({
          alphabet,
          characters,
          show: this.state.characters.map((x) => x.character).join(" "),
        });
      }
    }
  };

  onNext = () => {
    // eslint-disable-next-line no-unused-expressions
    this.resultAudio?.pause();

    const { characters, question, arrayCharQuestionText } = this.state;

    this.resultAnswer = characters.map((x) => x.character).join(" ");

    // check answer true or false
    const isCorrect = this.resultAnswer === question.text;
    this.checkAnswer(isCorrect);

    // post answer to api
    this.postAnswerToAPI({
      text: this.resultAnswer,
      example: arrayCharQuestionText[0].text,
    });

    if (this.checkCompletedOrNo()) return;
    const stateModel = {
      time: 40,
      timerKey: Date.now(),
    };

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);

    this.setState(stateModel);
  };

  checkAnswer = (isCorrect) => {
    let { saveAnswers } = this.props;
    if (isCorrect === true) {
      const payload = {
        key: this.questionIndex,
        isCorrect: true,
        score: 100,
        type: "",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "success",
        "Chính xác",
        "Chúc mừng bạn đã trả lời đúng"
      );
      resultAudio(1);
    } else {
      const payload = {
        key: this.questionIndex,
        isCorrect: false,
        score: 0,
        type: "",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "danger",
        "Không chính xác",
        "Vui lòng kiểm tra lại kết quả"
      );
      resultAudio(2);
    }
  };

  checkCompletedOrNo = () => {
    let { allProps, takeExamTime } = this.props;
    const { questions } = this.state;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  moveOnToAnotherQuestion = (ix) => {
    const { questions } = this.state;

    const arrayCharQuestionText = JSON.parse(
      questions[this.questionIndex].examplesFormat
    );

    let tmpData = this.getCharacters(arrayCharQuestionText.sentences[0]?.text);

    let { alphabet, characters } = tmpData;
    this.setState({
      characters,
      alphabet,
      show: "",
      arrayCharQuestionText: arrayCharQuestionText.sentences,
      question: arrayCharQuestionText.sentences[0],
      timeStart: moment().format(),
    });
  };

  postAnswerToAPI = async ({ text, example }) => {
    let { questions } = this.state;
    let { allProps, loggedInUserSGK } = this.props;
    const duration = moment().diff(this.state.timeStart);

    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: questions[this.questionIndex].id,
      questionGuid: "", // empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type14),
      notes: "",
      duration,
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        text,
        example,
      }),
    };

    await functions
      .postAnswerToAPI(answerModel)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  Random = (array) => {
    let m = array.length;
    // Chừng nào vẫn còn phần tử chưa được xáo trộn thì vẫn tiếp tục
    while (m) {
      // Lấy ra 1 phần tử
      let i = Math.floor(Math.random() * m--);
      // Sau đó xáo trộn nó
      let t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  };

  renderTime = ({ remainingTime }) => {
    if (Math.ceil(remainingTime) === 5) {
      if (this.resultAudio?.paused ?? true) {
        console.log(1);
        this.resultAudio = resultAudio(4);
      }
    }
    if (Math.ceil(remainingTime) === 0) {
      this.timer = setTimeout(() => {
        this.onNext();
      }, 100);
    }
    if (Math.ceil(remainingTime) === 0) {
      return <div className="timer">Too lale...</div>;
    }

    return (
      <div className="timer">
        <div style={{fontSize: 12}} className="text">You can do in</div>
        <div style={{fontSize: 20}} className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  render() {
    const {
      loading,
      question,

      alphabet,
      characters,

      styleImage,
      show,
      time,
      timerKey,
      questions,
    } = this.state;
    // const { questions } = this.state;
    const splitQuestion = question?.text.split(" ");
    const isDisabled =
      characters.filter((x) => x.character !== "").length !==
      splitQuestion?.length;
    const char = characters.map((item, index) => {
      return (
        <span
          key={index}
          className={
            item.status === "on"
              ? classNames(["question-type__btnMove-question1"])
              : classNames(["question-type__btnMove_type13"])
          }
          style={item.status === "on" ? { cursor: "pointer" } : { cursor: "" }}
          onClick={() => {
            if (item.status === "on") {
              this.removeCharacter(item);
            }
          }}
        >
          {item.character}
        </span>
      );
    });

    var alp = alphabet.map((item, index) => {
      return item.status === "on" ? (
        <span
          key={index}
          className={classNames(["question-type__btnMove"])}
          style={{ cursor: "pointer" }}
          onClick={
            isDisabled === true
              ? () => {
                  if (item.status === "on") {
                    this.addCharacter(item);
                  }
                }
              : () => {}
          }
        >
          {item.character}
        </span>
      ) : (
        <span
          className={classNames(["question-type__btnMove_type13"])}
          style={{ background: "#97a4e6" }}
        />
      );
    });

    if (questions.length === 0) {
      return <NotData />;
    }

    if (loading) {
      return <AMES247Loading />;
    }
    return (
      <React.Fragment>
        <Row
          className={classNames(["question-type1"])}
          style={{ display: "block" }}
        >
          {/* <div style={{
              border: '5px solid green',
              borderRadius: '50%',
              width: 55,
              height: 55,
            }}>
              <div>
                40s
              </div>
            </div> */}

          <img
            alt=""
            src={questions[this.questionIndex].imageUrl}
            style={styleImage}
            onLoad={this.onLoadImage}
            className={classNames(["question-type__image mt-15 mb-15"])}
          />
          <div className="timer-wrapper">
          <CountdownCircleTimer
              size={120}
              key={timerKey}
              isPlaying
              strokeWidth='6'
              duration={time}
              colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
              onComplete={() => [true, 0]}
            >
              {this.renderTime}
            </CountdownCircleTimer>
          </div>
          <p className={classNames(["question-type__word"])}>
            {questions[this.questionIndex].description}
          </p>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Row className={classNames(["mt-15 mb-15"])}>{char}</Row>
            <p className={classNames(["question-type__show"])}>{show}</p>
            {/* <hr style={{ border: "1px solid #b1b7d2", width: 300 }} /> */}
            <Row className={classNames(["mb-15 mt-15"])}>{alp}</Row>
          </Row>
        </Row>
        <Row
          className={classNames(["question-type__actions"])}
          style={{ display: "block" }}
        >
          <Button
            // disabled={isDisabled}
            // size="lg"
            type="primary"
            onClick={this.onNext}
            color="info"
          >
            <span className="btn-inner--icon mr-2">
              <i className="fas fa-chevron-circle-right"></i>
            </span>
            <span className="btn-inner--text">Next</span>
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

Type14.propTypes = {
  questions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exerciseCountdowns: state.AMES247SaveAnswerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type14);
