/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import Highlighter from "react-highlight-words";
import { Form, Table, Card, Input, Select, Space, Progress, Rate } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { layout, tailLayout } from "packages/formLayouts";
// import { query } from "helpers/QueryHelper";
import { ames247Axios } from "configs/api";
import * as actionTypes from "../ClassModule/actions/types";
import NotData from "components/Error/NotData";
import Notifications from "components/Notification";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

const { Column } = Table;

export default function ScoreResult(props) {
  // REDUX
  const loggedInUserSGK = JSON.parse(localStorage.getItem("loggedInUserSGK"));
  // REFS
  const form = React.useRef(null);
  // STATE

  // Create class//
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    classes: [],
    sessions: [],
    resultScore :[],
    searchText: "",
    isVisible: false,
    commentTeacher: "",
  });

  const fetchClassList = React.useCallback(() => {
    const userId = loggedInUserSGK.userId;

    dispatch({ type: actionTypes.FETCH_CLASS, userId });  
  }, []);

  const getClassList = useSelector((rootReducer)=>  rootReducer.classReducer.data );

  React.useEffect(() => {
    fetchClassList();
  }, []);

  const classList = React.useCallback(() => {
    return getClassList.map((item, index) => (
      <Select.Option key={index} value={item.amesCourseId}>
        {item.courseName}
      </Select.Option>
    ));
  }, [getClassList]);
 

  const searchInput = React.useRef();

  const handleSearch = React.useCallback((selectedKeys, confirm) => {
    confirm();
    setState((prevState) => ({
      ...prevState,
      searchText: selectedKeys[0],
      // selectedRowKeysLeft: dataIndex,
    }));
  }, []);

  const handleReset = (clearFilters) => {
    clearFilters();
    setState((prevState) => ({
      ...prevState,
      searchText: "",
    }));
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Tìm kiếm theo tên"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      state.selectedRowKeysLeft === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Submit
  const onFinish = async (values) => {
   
    let { classId, sessionId } = values;
   
    const studentId = loggedInUserSGK.userId;
   
    const response = await ames247Axios.get(
      `/api/GetStudentAssignmentBySession/${studentId}/${classId}/${sessionId}`
    );

    response.data.results.forEach((item,index)=>{
      item.STT = index+1;
    })
    setState((prevState) => ({...prevState,resultScore:response.data.results}))
    Notifications("success", "Thông báo", "Tìm kiếm thành công");
  };

 
  const onFinishFailed = (errorInfo) => {
    Notifications("danger", "Lỗi", "Có lỗi xảy ra vui lòng thử lại");
  };

  const onChangeClass = React.useCallback(async (value) => {
    // eslint-disable-next-line no-unused-expressions
    form.current?.setFieldsValue({
      sessionId: null,
    });
    setState((prevState) => ({...prevState,resultScore:[]}))
    const courseId = value;
    const studentId = loggedInUserSGK.id;
    const res = await ames247Axios.get(
      `/api/GetClassSession/${courseId}/${studentId}`
    );
   

    if (res.data.message === "OK") {
      res.data.results.className = res.data.className;
      setState((prevState) => ({ ...prevState, sessions: res.data.results }));
    }

    if (res.data.message === "Error") {
      return "Error";
    }
  }, []);

  const sessionSelect = React.useCallback(() => {
    return state.sessions.map((item) => (
      <Select.Option value={item.id}>{item.className}</Select.Option>
    ));
  }, [state.sessions]);

  const modal = React.useCallback(() => {
    return (
      <Modal className="modal-dialog-centered" isOpen={state.isVisible}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Nhận xét giáo viên
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => viewDetail()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{state.commentTeacher}</div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => viewDetail()}
          >
            Đóng
          </Button>
        </div>
      </Modal>
    );
  }, [state.isVisible]);

  const viewDetail = (text) => {
   
    setState((prevState) => ({
      ...prevState,
      isVisible: !state.isVisible,
      commentTeacher: text,
    }));
  };

  const checkRole = loggedInUserSGK?.userRoles?.includes("Teacher");

  return checkRole ? (
    <NotData />
  ) : (
    <React.Fragment>
      <Container style={{ backgroundColor: "#11CDEF" }} fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
              <a onClick={() => props.history.push("/sgk/trang-chu")}>
                <h6 className="h2 text-white d-inline-block mb-0">Trang chủ</h6>{" "}
              </a>
              <Breadcrumb
                className="d-none d-md-inline-block ml-md-4"
                listClassName="breadcrumb-links breadcrumb-dark "
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-chalkboard-teacher" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem className="active">
                  Kết quả học tập
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
      <Card title="Kết quả học tập" bordered={false} style={{ width: "100%" }}>
        {/* --------------------------------------------------------------------------------------------- */}
        {/* FORM */}
        {/* --------------------------------------------------------------------------------------------- */}
        {
          <div>
            <Form
              {...layout}
              ref={form}
              name="dynamic-report-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Chọn khóa học
                  </span>
                }
                
                name="classId"
                rules={[{ required: true, message: "Vui lòng chọn khóa học!" }]}
              >
                <Select
                  style={{
                    borderRadius: 5,
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  onChange={(value) => onChangeClass(value)}
                  showSearch
                >
                  {classList()}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <span
                    style={{ fontWeight: "500", color: "rgba(0, 0, 0, 0.75)" }}
                  >
                    Chọn bài học
                  </span>
                }
                
                name="sessionId"
                rules={[{ required: true, message: "Vui lòng chọn khóa học!" }]}
              >
                <Select
                  style={{
                    borderRadius: 5,
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  showSearch
                >
                  {sessionSelect()}
                </Select>
              </Form.Item>

              {/* <Form.Item label={<span style={{ fontWeight: '500', color: 'rgba(0, 0, 0, 0.75)' }}>Kết thúc</span>} name='finishDay' rules={[{ required: true, message: 'Bạn chưa nhập mã lớp' }]}>
                <DatePicker format='DD-MM-YYYY' onChange={onChangeFinishDay} />
              </Form.Item> */}

              {/* Submit */}
              <Form.Item {...tailLayout}>
                <Button
                  // size="default"
                  type="submit"
                  // htmlType="submit"
                  color="info"
                >
                  <span className="btn-inner--icon mr-2">
                    <i className="fas fa-edit" />
                  </span>
                  <span className="btn-inner--text">Xem kết quả</span>
                </Button>
              </Form.Item>
            </Form>
          </div>
        }
      </Card>

      {/* --------------------------------------------------------------------------------------------- */}
      {/* TABLE */}
      {/* --------------------------------------------------------------------------------------------- */}
      {state.resultScore.length !== 0 && (
        <div style={{ marginTop: 24, padding: 12 }}>
          <Table
            title={() => (
              <span style={{ fontWeight: "500", fontSize: 18 }}>
                Kết quả làm bài
              </span>
            )}
            rowKey="id"
            size="small"
            dataSource={state.resultScore}
            bordered
            // components={components}
            rowClassName={() => "editable-row"}
            // loading={loading}
            pagination
            scrollToFirstRowOnChange
          >
            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  STT
                </span>
              )}
              dataIndex="STT"
              width="5%"
              align="center"
              key="STT"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
            />

            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Tên bài học
                </span>
              )}
              dataIndex="questionTitle"
              key="questionTitle"
              render={(text) => {
                return <span style={{ whiteSpace: "nowrap" }}>{text}</span>;
              }}
              {...getColumnSearchProps("questionTitle")}
            />

            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Đánh giá
                </span>
              )}
              dataIndex="star"
              width="15%"
              align="center"
              key="star"
              render={(text) => {
                return <Rate disabled allowHalf defaultValue={text * 0.05} />;
              }}
            />

            <Column
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  Hoàn thành
                </span>
              )}
              dataIndex="completedPercent"
              align="center"
              width="30%"
              key="completedPercent"
              render={(text) => {
                let startColor = "";
                let finishColor = "";
                if (text < 50) {
                  startColor = "#e74c3c";
                  finishColor = "#c0392b";
                } else if (text >= 50 && text <= 70) {
                  startColor = "#f1c40f";
                  finishColor = "#f39c12";
                } else {
                  startColor = "#108ee9";
                  finishColor = "#87d068";
                }

                return (
                  <Progress
                    strokeColor={{
                      from: startColor,
                      to: finishColor,
                    }}
                    percent={text}
                    status="active"
                  />
                );
              }}
            />

            <Column
              align="center"
              title={() => (
                <span style={{ whiteSpace: "nowrap", fontWeight: "500" }}>
                  #
                </span>
              )}
              dataIndex="description"
              key="description"
              width="15%"
              render={(text) => {
                return (
                  <Space>
                    <Button
                      size="sm"
                      className="btn-icon"
                      color="info"
                      type="button"
                      onClick={() => viewDetail(text)}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-info-circle" />
                      </span>
                      <span className="btn-inner--text">Nhận xét</span>
                    </Button>
                  </Space>
                );
              }}
            />
          </Table>
          {modal()}
        </div>
      )}
    </React.Fragment>
  );
}
