import * as actionTypes from "../actions/types";

// { key: 0, isCorrect: boolean}
const initialRepliedAnswers = [];

export default (repliedAnswers = initialRepliedAnswers, action) => {
  switch (action.type) {
    case actionTypes.SAVE_ANSWER_SUCCESS:
      let { key, isCorrect, score, type } = action.payload;
      if (key === undefined && isCorrect === undefined && score === undefined && type === undefined) return [];

      let beforeAnswer = repliedAnswers.find(answer => answer.key === key);
      let afterAnswer = { key: undefined, isCorrect: undefined, score: undefined, type: undefined };
      if (beforeAnswer && beforeAnswer.isCorrect) {
        afterAnswer = beforeAnswer;
      } else {
        afterAnswer = action.payload;
      }

      let answerResults = [
        ...repliedAnswers.filter(answer => answer.key !== key),
        afterAnswer
      ];

      return answerResults;

    case actionTypes.SAVE_ANSWER_FAILURE:
      return repliedAnswers;
    case actionTypes.RESET_SAVE_ANSWER:
      return [];
    default:
      return repliedAnswers;
  }
};
