/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
// import { openNotificationWithIcon } from 'src/components/Notifications';
import CryptoJS from "crypto-js";
import { postLoginToApi, postResetPassApi } from "./api";
import { dynamicApiAxios } from "configs/api";
import * as actionTypes from "../actions/types";
import Notifications from "components/Notification";
import axios from "axios";

function* postlogin(action) {

 
  const { username, password } = action.values;
  const history = action.history;
 

  try {
    const { data } = yield postLoginToApi({ username, password });
   
    if (data.message === "ERROR") {
      Notifications("danger", "Thông báo", data.description);
      return;
    }
    if (data.message === "OK") {
      const { user } = data;
     
      yield localStorage.setItem("loggedInUserSGK", JSON.stringify(user));
      yield put({
        type: actionTypes.POST_LOGIN_SUCCESS,
        payload: user,
      });
      history.push('/sgk/trang-chu')
    }
  } catch (error) {
    yield put({
      type: actionTypes.POST_LOGIN_FAILURE,
      payload: {
        code: 200,
        message: "Đăng nhập không thành công.\nVui lòng thử lại!",
      },
    });
  }
}

function* onRegister(action) {
  const password = CryptoJS.MD5(action.values.password).toString();
  const history = action.history;
  action.values.password = password;
  // console.log('function*onRegister -> action', action);
  delete action.values.repassword;
  // action.values.classname = '';
  try {
    //Student Register
    if (action.values.studentForm) {
      if (action.values.classname === undefined) {
        action.values.classname = "";
      }
      delete action.values.studentForm;
      const body = {
        ...action.values,
      };
      yield axios
        .post(
          "https://cloud.softech.cloud/mobile/SGK/api/StudentRegister",
          body
        )
        .then((response) => {
          if (response.data.message === "OK") {
            put({
              type: actionTypes.POST_REGISTER_SUCCESS,
              payload: action.values,
            });
            Notifications(
              "success",
              "Thông báo",
              "Đăng ký thành công. Vui lòng đăng nhập"
            );
            history.push("/auth/login");
          }
          if (response.data.message === "ERROR") {
            Notifications("danger", "Thông báo", response.data.description);
          }
        })
        .catch((error) => {
          Notifications(
            "danger",
            "Thông báo",
            "Có lỗi xảy ra vui lòng thử lại",
            5
          );
          console.log(error);
        })
        .finally(function () {});

      //Teacher Register
    } else {
      yield dynamicApiAxios.execute.post("", {
        sqlCommand: "p_SGK_TEACHER_Register",
        parameters: action.values,
      });
      yield put({
        type: actionTypes.POST_REGISTER_SUCCESS,
        payload: action.values,
      });
      Notifications(
        "success",
        "Thông báo",
        "Đăng ký thành công. Vui lòng đăng nhập"
      );
      history.push("/auth/login");
    }
  } catch (error) {
    yield put({ type: actionTypes.POST_REGISTER_FAILURE, payload: error });
    Notifications(
      "danger",
      "Thông báo",
      "Username hoặc E-mail đã được đăng ký. Vui lòng thử lại"
    );
  }
}

function* fetchResetPass(action) {
  try {
    yield put({ type: actionTypes.POST_RESET_PASS_REQUEST });

    const data = action.payload;
    const activeResult = yield postResetPassApi(data);
    yield put({
      type: actionTypes.POST_RESET_PASS_SUCCESS,
      payload: activeResult,
    });
    if (activeResult.message === "OK") {
      action.onSuccess(activeResult.message);
    }
    if (activeResult.message === "ERROR") {
      // openNotificationWithIcon('error', 'Lỗi', activeResult.description, 4);
    }
  } catch (error) {
    yield put({
      type: actionTypes.POST_RESET_PASS_FAILURE,
      payload: error,
    });
    // openNotificationWithIcon('error', 'Đăng ký thất bại', error, 4);
  }
}

export default function* loginSaga() {
  yield takeLatest(actionTypes.POST_LOGIN_REQUEST, postlogin);
  yield takeLatest(actionTypes.POST_RESET_PASS, fetchResetPass);
  yield takeLatest(actionTypes.POST_REGISTER_REQUEST, onRegister);
}
