import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/types";

function* saveAnswers(action) {
  try {
    yield put({
      type: actionTypes.SAVE_ANSWER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    console.log("SAVE_ANSWER_FAILURE", error);
    yield put({
      type: actionTypes.SAVE_ANSWER_FAILURE,
      payload: error,
    });
  }
}

export default function* saveAnswersSaga() {
  yield takeEvery(actionTypes.SAVE_ANSWER, saveAnswers);
}
