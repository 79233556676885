import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import * as __typeText from "../text";
import { default as functions } from "components/functions";

import queryString from "query-string";
import { Rate } from "antd";
import { CardTitle, Card, Row, Col } from "reactstrap";
import moment from "moment";
import * as ActionTypes from "../actions/types";
import Listen from "components/Listening";
import AMES247Loading from "components/Error/Loading";
import Recorder from "components/Recording/react_mic";
import CountdownTimer from "components/countdownTimer";
import openNotificationWithIcon from "components/Notification";
import { DictionaryText } from "components/Dictionary";
import NotData from "components/Error/NotData";
// import PropTypes from 'props-types'

class Type12 extends React.Component {
  constructor(props) {
    super(props);
    this.soundUrl = "";
    this.arrayQuestion = "";
    this.state = {
      question: {},
      resultRecord: undefined,
      questions: props.questions,
      timeStart: null,
    };

    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
    //this.recordUrlFromApi = null;
    this.refRecorder = React.createRef();
    this.refCountdownTimer = React.createRef();
  }

  getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  UNSAFE_componentWillMount = () => {
    let questions = functions.randomTextAnswersFromAPI(this.props.questions);

    questions = functions.randomFourAnswersOneWay(questions);
    this.setState({
      questions,
      loading: false,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });

    if (this.state.questions[0].examplesFormat) {
      const sentences = JSON.parse(this.state.questions[0].examplesFormat)
        .sentences;
      let ques = sentences[this.getRandomInt(0, sentences.length - 1)];
      this.soundUrl = ques.soundUrl;
      this.arrayQuestion = ques.text;
    }
  };

  onRecording = () => {
    this.setState({ disabledNext: true }, () => {
      if (typeof this.props.startRecord === "function") {
        this.props.startRecord();
      }
      this.refCountdownTimer.current.startTimer();
    });
  };

  onStopRecording = (result) => {
    this.setState({ resultRecord: result, disabledNext: false });
  };

  onStopTimer = () => {
    if (typeof this.refCountdownTimer.current.stopTimer == "function") {
      this.refCountdownTimer.current.stopTimer();
    }
  };

  onNext = async (type, _) => {
    const questionParent = this.state.question;
    const originalSentence = this.arrayQuestion;
    const soundUrl = this.soundUrl;

    // check answer true or false
    this.checkAnswer(type);

    await this._postAnswerToAPI(
      this.state.resultRecord,
      questionParent,
      originalSentence,
      soundUrl
    );

    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
    // if (this.questionIndex !== 0) {
    //   this.setState({ disabledBack: false, resultRecordWithAzure: undefined });
    // }
  };

  checkAnswer = async (type) => {
    let { saveAnswers } = this.props;
    console.log("this.state.resultRecord.score", this.state.resultRecord.score);
    if (
      type === "NEXT" &&
      this.state.resultRecord.score > functions.satisfactoryResults
    ) {
      saveAnswers({
        key: this.questionIndex,
        isCorrect: true,
        score: this.state.resultRecord.score,
        type: "record",
      });
      openNotificationWithIcon("success", "CORRECT");
    } else {
      saveAnswers({
        key: this.questionIndex,
        isCorrect: false,
        score: this.state.resultRecord.score,
        type: "record",
      });
      openNotificationWithIcon("error", "INCORRECT");
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  moveOnToAnotherQuestion = (ix) => {
    this.setState({
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });
    if (this.state.questions[ix].examplesFormat === null) {
      this.questionIndex++;
      this.moveOnToAnotherQuestion(this.questionIndex);
    } else {
      const sentences = JSON.parse(this.state.questions[ix].examplesFormat)
        .sentences;
      let ques = sentences[this.getRandomInt(0, sentences.length - 1)];
      this.soundUrl = ques.soundUrl;
      this.arrayQuestion = ques.text;
    }
  };

  _postAnswerToAPI = (
    resultRecord,
    questionParent,
    originalSentence,
    soundUrl
  ) => {
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: questionParent.id,
      questionGuid: "", // empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type12),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        score: resultRecord.score || 0,
        originalSentence,
        recordUrl: resultRecord.recordUrl || "",
        example: { soundUrl },
      }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((_) => {
        this.setState({ resultRecord: undefined });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { question, resultRecord } = this.state;
    const { loggedInUserSGK, questions } = this.props;

    const recordParams = {
      questionId: question.id,
      questionText: this.arrayQuestion,
      studentId: loggedInUserSGK.studentId,
      takeExamTime: this.takeExamTime,
      // user: {
      //   mode: functions.getUser().Mode,
      // },
    };

    if (
      questions.length === 0 ||
      (question.examplesFormat &&
        JSON.parse(question.examplesFormat).sentences < 0)
    ) {
      return <NotData />;
    }

    if (!question) {
      return <AMES247Loading />;
    }
    return (
      <React.Fragment>
        <Row  style={{paddingTop:50}} className={classNames(["question-type"])}>
          <Col  
           
            // className={classNames(["question-type__left"])}
            xs={24}
            sm={24}
            lg={12}
            md={12}
          >
            <Card className="bg-primary text-white text-center p-4">
              <CardTitle>
                <Listen audioURL={this.soundUrl} onAuto={true} />
              </CardTitle>
              <blockquote className="blockquote mb-0">
                <p style={{fontSize:20}}>
                  <DictionaryText text={this.arrayQuestion} />
                </p>
                <footer className="blockquote-footer">
                  <small>Nhấp vào để tra cứu nghĩa từng từ </small>
                </footer>
              </blockquote>
            </Card>
            {/* <h2 className={classNames(["question-type__grammar_text"])}></h2> */}
       
            <Recorder
              ref={this.refRecorder}
              __custom
              __className={"question-type__recordType02"}
              __onRecording={this.onRecording}
              __onStopRecording={this.onStopRecording}
              recordParams={recordParams}
              onStopTimer={this.onStopTimer}
            >
              <div className={classNames(["mt-15"])}>
                <CountdownTimer
                  seconds={15}
                  ref={this.refCountdownTimer}
                  onStopRecording={this.onStopRecording}
                >
                  <span>Recorder in: </span>
                </CountdownTimer>
              </div>
              <br />
              {resultRecord && (
                <div>
                  {resultRecord.wordShows.map((t, i) => (
                    <span
                      key={i}
                      style={{ color: t.color }}
                      className={classNames(["question-type__textReply"])}
                    >{`${t.word} `}</span>
                  ))}
                  <br />
                  <div className="record--result">
                    {resultRecord && (
                      <div className={""}>
                        <Listen
                          custom
                          className={"record--content record--result__listen"}
                          audioURL={resultRecord.recordUrl}
                        >
                          <i className="fas fa-volume-up"></i>
                        </Listen>
                      </div>
                    )}
                    <div
                      className={classNames([
                        "record--result__rate",
                        "record--content",
                      ])}
                    >
                      <Rate
                        allowHalf
                        disabled
                        value={functions.getStarRecord(resultRecord.score)}
                      />
                    </div>
                    <div
                      className={classNames([
                        "record--result__btn-score",
                        "record--content",
                      ])}
                    >
                      <strong>{`${parseInt(resultRecord.score)}%`}</strong>
                    </div>
                    <div
                      className={classNames([
                        "record--result__btn-next",
                        "record--content",
                      ])}
                      onClick={this.onNext.bind(this, "NEXT")}
                    >
                      <i className="fas fa-arrow-circle-right" />
                    </div>
                  </div>
                </div>
              )}
            </Recorder>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type12);
