import React from "react";
import * as ActionTypes from "../actions/types";
import { Rate } from "antd";
// import { default as Title } from "components/Title";
import Loading from "components/Error/Loading";
import NotData from "components/Error/NotData";
import { default as functions } from "components/functions";
import RdIcon from "components/functions/rdIcons";
import { connect } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  Media,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  BreadcrumbItem,
  Breadcrumb,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

class AssignmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.takeExamTime = functions.uuid();
    this.state = {
      visible: false,
      data: null,
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading,
        data: props.data,
      };
    }
    return null;
  };

  componentDidMount = () => {
    this.getAssinments();
  };

  getAssinments = () => {
    let { match, fetchAssignments } = this.props;
    const { loggedInUserSGK } = this.state;
    if (loggedInUserSGK) {
      let { classId, sessionId } = match.params;
      let { studentId } = loggedInUserSGK;
      fetchAssignments(studentId, classId, sessionId);
    } else {
      this.props.history.push("/auth/login");
    }
  };

  renderIcons = (item) => {
    let { questionType } = item;
    let iconArray = RdIcon(questionType);
    return iconArray?.map((item, index) => {
      return (
        <>
          <a
            className="avatar avatar-sm rounded-circle"
            href="#pablo"
            id="tooltip160923422"
            onClick={(e) => e.preventDefault()}
          >
            <img alt="..." src={item} />
          </a>
          {/* <UncontrolledTooltip delay={0} target="tooltip160923422">
            Ryan Tompson
          </UncontrolledTooltip> */}
        </>
      );
    });
  };

  renderItem = () => {
    const { data } = this.state;
    const { match } = this.props;
    const { classId, sessionId } = match.params;

    return data.map((item, index) => {
      const linkStart = `/sgk/classes/${classId}/session/${sessionId}/assignment/${item.id}/questions`;
      return (
        <tr
          key={index}
          onClick={() =>
            this.props.history.push({
              pathname: linkStart,
              search: `?type=${item.questionType}&asrId=${item.asrId}&takeExamTime=${this.takeExamTime}`,
            })
          }
        >
          <th scope="row">
            <Media className="align-items-center">
              <a
                className="avatar rounded-circle mr-3"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img alt="..." src={require("assets/icon/L3.jpg")} />
              </a>
              <Media style={{ flexDirection: "column" }}>
                <span  style={{ fontSize: 13 }}>{item.questionTitle}</span>
                <span
                  style={{ fontSize: 10, color: "gray", fontStyle: "italic" }}
                >
                  {item.description}
                </span>
              </Media>
            </Media>
          </th>

          {/* <td className="budget"> <span className="budget">{item.description}</span></td> */}
          <td>
            <div className="avatar-group">{this.renderIcons(item)}</div>
          </td>
          <td>
            <Rate disabled allowHalf defaultValue={item.star * 0.05} />
          </td>

          <td>
            <div className="d-flex align-items-center">
              <span className="completion mr-2">{item.completedPercent}%</span>
              <div>
                <Progress
                  max="100"
                  value={item.completedPercent}
                  color={item.completedPercent < 100 ? "warning" : "success"}
                />
              </div>
            </div>
          </td>
          <td className="text-right">
            <Button
              size="sm"
              className="btn-icon"
              color="info"
              type="button"
              onClick={() =>
                this.props.history.push({
                  pathname: linkStart,
                  search: `?type=${item.questionType}&asrId=${item.asrId}&takeExamTime=${this.takeExamTime}`,
                })
              }
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-pen-square"></i>
              </span>
              <span className="btn-inner--text">Làm bài</span>
            </Button>
          </td>
        </tr>
      );
    });
  };

  render = () => {
    const { data, loading } = this.state;
    const { match } = this.props;
    const { classId } = match.params;
    const linkBackSession = `/sgk/classes/${classId}/sessions`;

    if (loading) {
      return <Loading />;
    }

    if (!loading && data.length === 0) {
      return <NotData />;
    }

    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    ASSIGNMENTS
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-book-open" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        onClick={() =>
                          this.props.history.push("/sgk/trang-chu")
                        }
                      >
                        Classes
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        onClick={() => this.props.history.push(linkBackSession)}
                      >
                        Sessions
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      Assignments
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Danh sách bài học</h3>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" scope="col">
                        Tên bài học
                      </th>

                      <th scope="col"> Kỹ năng</th>
                      <th className="sort" scope="col">
                        Đánh giá
                      </th>
                      <th scope="col">Hoàn thành</th>
                      <th className="sort" scope="col"></th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">{this.renderItem()}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state.assignmentReducer.data,
  loading: state.assignmentReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAssignments: (studentId, classId, sessionId) =>
    dispatch({
      type: ActionTypes.FETCH_ASSIGNMENT,
      studentId,
      classId,
      sessionId,
    }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentComponent);
