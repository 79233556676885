import React from "react";
import Loading from "components/Error/Loading";
import { default as NotData } from "components/Error/NotData";
import * as ActionTypes from "../actions/types";
import { connect } from "react-redux";
import {
  Button,
  Progress,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter
} from "reactstrap";
import { Avatar, Rate } from "antd";

class SessionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading,
        data: props.data,
      };
    }

    return null;
  };

  componentDidMount = async () => {
    this.getSessions();
  };

  getSessions = () => {
    let { fetchSessions, match } = this.props;
    const { loggedInUserSGK } = this.state;

    if (loggedInUserSGK) {
      let classId = match.params.classId;

      let studentId = loggedInUserSGK.studentId;

      fetchSessions(classId, studentId);
    }
  };

  selectOneSesion = (item) => {
    this.props.history.push({ state: { selectSession: item } });
    this.props.selectSession(item);
  };

  renderItem = () => {
    const { data } = this.state;
    const render = data.map((item, index) => {
      let classId = this.props.match.params.classId;
      let sessionId = item.id;
      let hrefVideo = `/sgk/classes/${classId}/session/${sessionId}/video`;
      let hrefAudio = `/sgk/classes/${classId}/session/${sessionId}/audio`;
      let hrefassignments = `/sgk/classes/${classId}/session/${sessionId}/assignments`;
      const imageUrl =
        'https://image.freepik.com/free-photo/english-lettering-blue-wooden-background_23-2148293461.jpg';
      return (
        <Col xs={12} sm={6} md={4} lg={4} xl={4} xxl={4} key={index}>
          <Card>
            <CardImg alt="..." src={imageUrl} top />

            <CardBody>
              <CardTitle
                className="mb-3 text-info"
                style={{ fontWeight: '700', fontSize: 15 }}
              >
                <Avatar
                  style={{
                    backgroundColor: '#11cdef',
                    fontWeight: '700',
                    marginRight: 10,
                  }}
                >
                  {index < 9 ? `0${index + 1}` : `${index + 1}`}
                </Avatar>
                {item.title}
              </CardTitle>
              <span style={{ fontWeight: '500', fontSize: 13 }}>
                Đánh giá:
                <Rate
                  style={{ marginLeft: 15 }}
                  disabled
                  allowHalf
                  value={item.star / 20}
                />
              </span>
              <div className="progress-wrapper">
                <div className="progress-info">
                  <div className="progress-label">
                    <span>Hoàn thành</span>
                  </div>
                  <div className="progress-percentage">
                    <span>{item.completedPercent}%</span>
                  </div>
                </div>
                <Progress
                  max="100"
                  value={item.completedPercent}
                  color="info"
                />
              </div>
            </CardBody>
            <CardFooter className='text-center'>
              {/* <Col >
                <Button onClick={() => this.props.history.push(hrefVideo)} block color="info" type="button">
                  Video
                </Button>
              </Col>
              <Col className='mt-3'>
                <Button onClick={() => this.props.history.push(hrefAudio)} block color="info" type="button">
                  Audio
                </Button>
              </Col> */}
              {/* <Col className='mt-3'>
                <Button onClick={() => this.props.history.push(hrefassignments)} block color="info" type="button">
                  Luyện tập với AI
                </Button>
              </Col>
              <Col className='mt-3'>
                <a target='_blank' href='http://sachdientu.softech.cloud/'>
                  <Button className="mt-2" block color="info" type="button">
                    Sách điện tử
                  </Button>
                </a>
              </Col> */}
              <Row>
                <Col className='col-6 mt-2' xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Button block onClick={() => this.props.history.push(hrefassignments)} color="info" type="button">
                    Luyện tập với AI
                  </Button>
                </Col >
                <Col className='col-6 mt-2'  xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <a target='_blank' href='http://sachdientu.softech.cloud/'>
                    <Button block className="btn-icon btn-3" color="info" type="button">
                      {/* <span className="btn-inner--icon">
                        <i className="ni ni-bag-17" />
                      </span> */}
                      <span className="btn-inner--text">Sách điện tử</span>
                    </Button>
                  </a>
                </Col>
                </Row>
            </CardFooter>
         
          </Card>
        </Col>
      );
    });
    return render;
  };

  render = () => {
    let { data, loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!loading && data.length === 0) {
      return <NotData />;
    }

    return (
      <>
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                  <h6 className="h2 text-white d-inline-block mb-0">SESSION</h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-book-open" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        onClick={() =>
                          this.props.history.push("/sgk/trang-chu")
                        }
                      >
                        Classes
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      Sessions
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    onClick={() => this.props.history.push("/sgk/trang-chu")}
                    size="sm"
                  >
                    Quay lại
                  </Button>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
        {/* <CardsHeader name="Cards" parentName="Components" /> */}
        <Container
          style={{ backgroundColor: "#f1f2f6", minHeight: 800 }}
          className="mt--6"
          fluid
        >
          <Row>
            {this.renderItem()}
          </Row>

        </Container>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state.sessionReducer.data,
  loading: state.sessionReducer.loading,
});
const mapDispatchToProps = (dispatch) => ({
  fetchSessions: (classId, studentId) =>
    dispatch({ type: ActionTypes.FETCH_SESSION, classId, studentId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionComponent);
