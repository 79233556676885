const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 12,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 3,
    span: 15,
  },
  labelCol: {
    span: 5,
  },
};

export { layout, tailLayout };
