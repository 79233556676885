import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Tooltip from "antd/es/tooltip";

import { captureUserMedia } from "./utils";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactMic } from "@cleandersonlobo/react-mic";
import axios from "axios";
import { notification } from "antd";

class Recorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      src: null,
      recognizer: null,
      recordAudio: undefined,
      statusRecord: "start",
      classNames__btnRecord: null,
      disabled: true,
      statusText: "Thu âm bị lỗi",
      isRecord: false,
    };
  }

  componentDidMount() {
    this.initialRecord();
  }

  componentWillReceiveProps = (nextProps) => {
    const { recordParams } = this.props;
    if (recordParams.questionId !== nextProps.recordParams.questionId) {
      this.setState({ isRecord: false });
    }
  };

  initialRecord = () => {
    captureUserMedia((stream) => {
      try {
        this.setState({ disabled: false, statusText: "Bắt đầu ghi âm" });
      } catch (error) {}
    });
  };

  onRecord = () => {
    if (this.state.statusRecord === "start") {
      this.setState(
        {
          statusRecord: "stop",
          classNames__btnRecord: ["pulse", "infinite"],
          statusText: "Dừng lại",
        },
        () => {
          // record
          this.recordStart();
        }
      );
    } else {
      this.setState(
        {
          statusRecord: "cal",
          classNames__btnRecord: null,
        },
        () => {
          this.recordStop();
        }
      );
    }
  };

  recordStart = () => {
    this.setState({
      record: true,
    });
    if (this.props.__onRecording) {
      this.props.__onRecording();
    }
  };

  recordStop = () => {
    this.setState({
      record: false,
      classNames__btnRecord: null,
      isRecord: true,
    });
  };

  onData(recordedBlob) {
    //console.log('chunk of real-time data is: ', recordedBlob);
  }

  onStop = async (recordedBlob) => {
    const { SpeakPractice, recordParams, onStopTimer } = this.props;
    const { questionId, studentId, takeExamTime, questionText } = recordParams;

    if (onStopTimer) {
      onStopTimer();
    }
    const scope = this;

    var reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob);
    reader.onloadend = async () => {
      var base64data = reader.result;

      if (SpeakPractice) {
        scope.setState({ statusRecord: "start", statusText: "Thu âm lại" });
        this.props.__onStopRecording({ recordedBlob, base64data });
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("Device", "WEB");
        bodyFormData.append("mode", "Dictation");
        bodyFormData.append("questionId", questionId);
        bodyFormData.append("readingText", questionText);
        bodyFormData.append("studentID", studentId);
        bodyFormData.append("takeExamTime", takeExamTime);
        bodyFormData.append("extensionInput", "wav");
        bodyFormData.append("input", base64data);
        bodyFormData.append("speechRecognitionAPI", "A");

        try {
          const response = await axios({
            method: "POST",
            url:
              "https://ames.edu.vn/ames/api/amesApi/SaveFileAndCalculateScore",
            data: bodyFormData,
            config: { headers: { "Content-Type": "multipart/form-data" } },
          })
            .then((response) => {
              return response;
            })
            .catch(function (error) {
              scope.setState({
                statusRecord: "start",
                statusText: "Thu âm lại",
              });
              this.props.__onStopRecording(undefined);

              notification.error({
                message: "Thông báo",
                description: `Thu âm bị lỗi!", "Bạn vui lòng thu âm lại!`,
              });
            });
          scope.setState({ statusRecord: "start", statusText: "Thu âm lại" });
          if (response.statusText === "OK") {
            if (this.props.__onStopRecording) {
              this.props.__onStopRecording(response.data);
            }
          }
        } catch (error) {
          console.log(error);
          this.props.__onStopRecording(undefined);
          notification.error({
            message: "Thông báo",
            description: `Thu âm bị lỗi!", "Bạn vui lòng thu âm lại!`,
          });
        }
      }
    };
  };

  render() {
    let {
      src,
      statusRecord,
      classNames__btnRecord,
      record,
      disabled,
      statusText,
      isRecord,
    } = this.state;
    if (this.props.__custom) {
      let { __className, __icon } = this.props;
      return (
        <div>
          <Tooltip style={{ top: 200 }} placement="bottom" title={statusText}>
            <button
              className={classNames([
                "animated",
                classNames__btnRecord,
                __className,
              ])}
              onClick={disabled ? null : this.onRecord}
            >
              {disabled ? (
                <i
                  style={{ fontSize: "25px" }}
                  className="fas fa-microphone-slash"
                ></i>
              ) : statusRecord === "cal" ? (
                <LoadingOutlined style={{ fontSize: 30 }} />
              ) : statusRecord === "start" ? (
                __icon ? (
                  __icon
                ) : isRecord ? (
                  <i style={{ fontSize: "25px" }} className="fas fa-undo"></i>
                ) : (
                  <i
                    style={{ fontSize: "25px" }}
                    className="fas fa-microphone"
                  />
                )
              ) : (
                <i style={{ fontSize: "25px" }} className="fas fa-stop"></i>
              )}
            </button>
          </Tooltip>
          {this.props.children}
          <br />
          {this.props.__listenAgain && (
            <audio
              controls
              src={src}
              className={classNames(["btn-record__soundClips", "mt-10"])}
            />
          )}
          <div
            style={{
              height: 5,
              width: 5,
              display: "flex",
              "flex-wrap": "wrap",
              zIndex: -1,
            }}
          >
            <ReactMic
              record={this.state.record}
              visualSetting="none"
              className="sound-wave"
              onStop={this.onStop}
              onData={this.onData}
              audioBitsPerSecond={128000}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

Recorder.propTypes = {
  __custom: PropTypes.bool,
  __className: PropTypes.string,
  __listenAgain: PropTypes.bool,
  __onRecording: PropTypes.func,
  __onStopRecording: PropTypes.func,
};

export default Recorder;
