import {  put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/types";

import { ames247Axios } from "configs/api";

function* fetchAssignments(action) {

  try {
    yield put({ type: actionTypes.FETCH_ASSIGNMENT_REQUEST });
    let { studentId, classId, sessionId } = action;
    const response = yield ames247Axios.get(
      `/api/GetStudentAssignmentBySession/${studentId}/${classId}/${sessionId}`
    );

    response.data.results.sessionTitle = response.data.sessionTitle;
    const receivedAssignments = response.data.results;

    yield put({
      type: actionTypes.FETCH_ASSIGNMENT_SUCCESS,
      payload: receivedAssignments,
    });
  } catch (error) {
    // alert("error load data");
    console.log("FETCH_ASSIGNMENT_FAILURE", error);
    yield put({
      type: actionTypes.FETCH_ASSIGNMENT_FAILURE,
      payload: error,
    });
  }
}

export default function* assignmentSaga(){
    yield takeLatest(actionTypes.FETCH_ASSIGNMENT, fetchAssignments);
};


