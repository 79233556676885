import { put, takeLatest } from 'redux-saga/effects';
import { getQuestionsFromApi, getAudioApi, getVideoApi } from './api';
import * as actionTypes from '../actions/types';

function* fetchQuestions(action) {
  
  try {
    yield put({ type: actionTypes.FETCH_QUESTIONS_REQUEST });
    let { sessionId, assignmentId, takeExamTime, userId, asrId } = action;
    const receivedQuestions = yield getQuestionsFromApi({ sessionId, assignmentId, takeExamTime, userId, asrId });
   
    yield put({
      type: actionTypes.FETCH_QUESTIONS_SUCCESS,
      payload: receivedQuestions,
    });
  } catch (error) {
    console.log('FETCH_QUESTIONS_FAILURE', error);
    yield put({
      type: actionTypes.FETCH_QUESTIONS_FAILURE,
      payload: error,
    });
  }
}

function* getAudioSaga(action) {
  try {
    const { results } = yield getAudioApi(action.sessionId);
    yield put({
      type: actionTypes.GET_AUDIO_SUCCESS,
      payload: results,
    });
  } catch (error) {
    // yield put({
    //   type: actionTypes.FETCH_QUESTIONS_TYPE35_FAILURE,
    //   payload: error
    // });
  }
}

function* getVideo(action) {
  try {
    const results = yield getVideoApi(action.sessionId);
    yield put({
      type: actionTypes.GET_VIDEO_SUCCESS,
      payload: results,
    });
  } catch (error) {
    console.log('function*getVideoSaga -> error', error);
    // yield put({
    //   type: actionTypes.FETCH_QUESTIONS_TYPE35_FAILURE,
    //   payload: error
    // });
  }
}

export default function* questionSaga() {
    yield takeLatest(actionTypes.FETCH_QUESTIONS, fetchQuestions);
    yield takeLatest(actionTypes.GET_AUDIO, getAudioSaga);
    yield takeLatest(actionTypes.GET_VIDEO, getVideo);
};
;
