import React from 'react';
import './index.css';
import spinWheelAudio from 'assets/audioGame/spinWheel.mp3'

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      listItems: [],
      index: 0,
    };
  }

  componentDidMount = () => {
    this.setState({ listItems: this.props.items })
  }


  selectItem = () => {
    const { onSelectItem } = this.props;
    const { listItems, index } = this.state;
    if (this.state.selectedItem === null) {
      const selectedItem = listItems[index].value;
      const audioPlayer = new Audio(spinWheelAudio);
      audioPlayer.play();
      if (this.props.onSelectItem) {
        setTimeout(() => {
          onSelectItem(selectedItem);
        }, 4000);
      }
      this.setState({ selectedItem, index: index + 1, });
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { items, isDisable } = this.props;

    const wheelVars = {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    };
    const spinning = selectedItem !== null ? 'spinning' : '';

    return (
      <div className="wheel-container wheel-root">
        <div className={`wheel ${spinning}`} style={wheelVars} onClick={() => !isDisable ? this.selectItem() : null}>
          {items.map((item, index) => (
            <div className="wheel-item" key={index} style={{ '--item-nb': index }}>
              <img
                src={item.label}
                style={{ width: 110, marginLeft: 50 }}
                onLoad={this.onLoadImage}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}