import React, { useState, useCallback, useEffect } from 'react'
import Dustbin from './Dustbin'
import Box from './Box'
import update from 'immutability-helper'
export const Container = props => {
  const { arrMove, arrText, handleCheckAnswer } = props;
  // const arrAccepts = arrText.map(item => {
  //   return item.text;
  // })
  // const dustbin = arrText.map(item => {
  //   return { accepts: arrAccepts, lastDroppedItem: null }
  // })
  const [dustbins, setDustbins] = useState([])
  const [boxes, setBoxes] = useState([])
  const [droppedBoxNames, setDroppedBoxNames] = useState([])

  useEffect(() => {
    setBoxes(arrMove);
  }, [arrMove])

  useEffect(() => {
    const arrAccepts = arrText.map(item => {
      return item.text;
    })
    const dustbin = arrText.map(item => {
      return { accepts: arrAccepts, lastDroppedItem: null }
    })
    setTimeout(() => setDustbins(dustbin));
  }, [arrText])

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1
  }

  const isDoneAnswer = (text) => {
    handleCheckAnswer(text);
  }

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
        )
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
          },
        }),
      )
    },
    [droppedBoxNames, dustbins],
  )
  return (
    <div>
      <div >
        {boxes.map(({ text, imageUrl }, index) => (
          <Box
            name={imageUrl}
            type={text}
            isDropped={isDropped(text)}
            key={index}
          />
        ))}
      </div>

      <div style={{ overflow: 'hidden', clear: 'both' }}>
        {dustbins.map(({ accepts, lastDroppedItem }, index) => (
          <Dustbin
            accepts={accepts}
            lastDroppedItem={lastDroppedItem}
            onDrop={(item) => handleDrop(index, item)}
            key={index}
            text={arrText[index]}
            isDoneAnswer={isDoneAnswer}
          />
        ))}
      </div>
    </div>
  )
}
