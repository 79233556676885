import React from "react";
// import routes from 'src/configs/routes.json';
import PropTypes from "prop-types";
// import { Row, Col, Modal,Button } from "antd";
import { Badge, Card, CardBody, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// import { openNotificationWithIcon } from "src/components/Notifications";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
// const { success } = Modal;
// const imgSrc = require('src/assets/images/thaphoctap2019.png');

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
 
  }

  render = () => {
    return (
      <>
        <IndexNavbar {...this.props} />
        <div className="main-content">
          {/* <IndexHeader /> */}
          <section className="py-6 pb-9 bg-default">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-white">
                    SÁCH GIÁO KHOA TIẾNG ANH
                  </h2>
                  <p className="lead text-white">
                    Áp dụng phương pháp học tập khoa học mới hệ thống học trực
                    tuyến thông minh, ứng dụng công nghệ 4.0 với AI. Việc áp
                    dụng phương pháp mới này không những mang lại hiệu quả cao,
                    tiết kiệm thời gian mà còn mang đến tính sáng tạo, tư duy
                    độc lập, sự tìm tòi hay tư duy độc lập của học sinh.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 mt--7">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                            <i className="fas fa-brain" />
                          </div>
                          <h4 className="h3 text-info text-uppercase">
                            Chấm điểm AI tự động
                          </h4>
                          <p className="description mt-3">
                            Việc tích hợp hệ thống AI chấm điểm giúp hạn chế
                            việc lấy đi một khoảng thời gian đáng kể trong khi
                            thời gian đó có thể được sử dụng để trao đổi với học
                            sinh, chuẩn bị trang bị lớp học hay các công việc
                            khác phục vụ thiết yếu phục vụ cho công tác dạy và học.
                          </p>
                          <div>
                            <Badge color="info" pill>
                              Trắc nghiệm
                            </Badge>
                            <Badge color="info" pill>
                              Bài nghe
                            </Badge>
                            <Badge color="info" pill>
                              Điền vào chỗ trống
                            </Badge>
                            {/* <Badge color="info" pill>
                              template
                            </Badge> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                            <i class="fas fa-school" />
                          </div>
                          <h4 className="h3 text-success text-uppercase">
                            Giáo viên quản lý học sinh
                          </h4>
                          <p className="description mt-3">
                            Xây dựng hệ thống quản lý lớp học từ xa. Là nơi giáo
                            viên có thể chủ động theo dõi việc học và làm bài
                            của học sinh từ đó giúp giáo viên có thể quản lý lớp
                            một cách bao quát hơn. Bên cạnh đó luôn đòi hỏi học
                            sinh làm việc khẩn trương theo đúng thời gian giáo
                            viên đã đặt ra...
                          </p>
                          <div>
                            <Badge color="success" pill>
                              Quản lý lớp
                            </Badge>
                            {/* <Badge color="success" pill>
                              
                            </Badge> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                            <i class="fas fa-chalkboard-teacher" />
                          </div>
                          <h4 className="h3 text-warning text-uppercase">
                            Phụ huynh theo dõi học sinh
                          </h4>
                          <p className="description mt-3">
                            Nhu cầu theo dõi việc học tập của con cái đối với
                            phụ huynh là một nhu cầu hàng đầu. Hệ thống được xây
                            dựng với mục đích đáp ứng nhu cầu trên và tạo sự kết
                            nối chặt chẽ hơn giữa phụ huynh học sinh với nhà
                            trường để kịp thời nắm bắt và theo dõi ...
                          </p>
                          <div>
                            <Badge color="warning" pill>
                              Lịch học
                            </Badge>
                            <Badge color="warning" pill>
                              Điểm số
                            </Badge>
                            <Badge color="warning" pill>
                              Thông báo
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <section className="py-6">
                <Container>
                  <Row className="row-grid align-items-center">
                    <Col className="order-md-2" md="6">
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/brand/thaphoctap2019.png")}
                      />
                    </Col>
                    <Col className="order-md-1" md="6">
                      <div className="pr-md-5">
                        <h1 style={{fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>
                          Phương pháp học tiếng anh dựa theo "Tháp học tập"
                        </h1>
                       
                        <ul className="list-unstyled mt-5">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-settings-gear-65" />
                                </Badge>
                              </div>
                              <div>
                                <h4 className="mb-0">
                                  Hệ thống bài học được thiết kế công phu, khoa
                                  học và hấp dẫn, giúp học viên học sâu qua các
                                  chủ đề giao tiếp thông dụng
                                </h4>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-html5" />
                                </Badge>
                              </div>
                              <div>
                                <h4 className="mb-0">
                                  Giúp học viên ghi nhớ lâu những kiến thức được
                                  học thông qua trải nghiệm trực tiếp và quá
                                  trình lặp đi lặp lại nhiều lần
                                </h4>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-satisfied" />
                                </Badge>
                              </div>
                              <div>
                                <h4 className="mb-0">
                                  Bằng việc ứng dụng hình ảnh, âm thanh, hành
                                  động và cảm xúc vào việc học từ vựng, học viên
                                  sẽ ghi nhớ từ vựng lâu hơn, hiệu quả hơn và có
                                  hứng thú hơn khi học
                                </h4>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Container>
          </section>
          <AuthFooter />
        </div>
      </>
    );
  };
}


export default LoginComponent;
