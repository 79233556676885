import React, { useState, useEffect } from 'react';
import { DropTarget } from 'react-dnd';
import classNames from "classnames";
import { resultAudio } from "components/functions";
const style = {
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  // padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  border: '3px solid gray',
  width: 200,
  height: 200,
}
export const Dustbin = ({
  accepts,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem,
  text,
  isDoneAnswer,
}) => {
  const [isTrue, setIsTrue] = useState(false);
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (lastDroppedItem && (text.imageUrl === lastDroppedItem.name)) {
      setIsTrue(true);
      isDoneAnswer(text.text);
      resultAudio(1);
      setUrl(lastDroppedItem.name);
    }
    if (lastDroppedItem && (text.imageUrl !== lastDroppedItem.name)) {
      resultAudio(2);
    }
  }, [lastDroppedItem])
  useEffect(() => {
    setUrl('')
  }, [accepts])
  const isActive = isOver && canDrop
  let backgroundColor = 'white'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }
  return connectDropTarget(
    <div style={{ ...style, backgroundColor: url ? null : backgroundColor }}>
      {lastDroppedItem && isTrue && url ? (
        <img
          alt=''
          src={url}
          className={classNames(["question-type__boxImage"])}
          style={{ margin: 0, width: 194, height: 194 }}
        />
      ) :
        <div style={{ color: 'black', margin: '65px auto', fontWeight: "bold" }}>{text.text}</div>
      }
    </div>,
  )
}
export default DropTarget(
  (props) => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)
