import React from "react";

import { connect } from "react-redux";
import { resultAudio } from "components/functions";
import * as __typeText from "../text";
import { default as functions } from "components/functions";
import moment from "moment";

import Button from "components/Button";
import queryString from "query-string";

import { ListGroupItem, Card, ListGroup, Row, Col } from "reactstrap";
import * as ActionTypes from "../actions/types";
import AMES247Loading from "components/Error/Loading";
// import { Container } from "reactstrap";
import openNotificationWithIcon from "components/Notification";
import { default as NotData } from "components/Error/NotData";
import "./Type05.css";

class Type05 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      question: {},
      questions: [],
      timeStart: null,
    };
    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;

    props.saveAnswers();
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.loading !== state.loading) {
  //     return {
  //       loading: props.loading,
  //     };
  //   }
  //   return null;
  // }

  UNSAFE_componentWillMount = () => {
    let questions = functions.randomTextAnswersFromAPI(this.props.questions);
    questions = functions.randomFourAnswersOneWay(questions);

    this.setState({
      questions,
      loading: false,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });
  };

  onNext = (answer, _) => {
    // check answer true or false
    this.checkAnswer(answer);
    // postAnswerToAPI()
    this._postAnswerToAPI(answer);

    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
    if (this.questionIndex !== 0) {
      this.setState({ disabledBack: false });
    }
  };

  checkAnswer = (answer) => {
    let { saveAnswers } = this.props;
    let { question } = this.state;
    if (answer.id === question.id) {
      const payload = {
        key: this.questionIndex,
        isCorrect: true,
        score: 100,
        type: "",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "success",
        "Chính xác",
        "Chúc mừng bạn đã trả lời đúng"
      );
      resultAudio(1);
    } else {
      const payload = {
        key: this.questionIndex,
        isCorrect: false,
        score: 0,
        type: "",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "danger",
        "Không chính xác",
        "Vui lòng kiểm tra lại kết quả"
      );
      resultAudio(2);
    }
  };

  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  _postAnswerToAPI = (answer) => {
    let { question } = this.state;
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: question.id,
      questionGuid: "", //empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type05),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        id: answer ? answer.id : question.id,
        text: answer ? answer.text : "",
      }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((v) => { })
      .catch((err) => {
        console.log(err);
      });
  };

  moveOnToAnotherQuestion = (ix) => {
    this.setState({
      choosedAnswer: undefined,
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });
  };

  render = () => {
    let { loading, question, choosedAnswer } = this.state;
    if (this.props.questions.length === 0) {
      return <NotData />;
    }

    if (loading) {
      return <AMES247Loading />;
    } else {
      return (
        <Row className="justify-content-md-center text-center">
          <Col lg="8">
            <Card>
              <ListGroup>
                <ListGroupItem
                  style={{
                    // backgroundColor: "#F5365C",
                    borderWidth: 0,
                    fontSize: 20,
                    fontWeight: "500",
                  }}
                  className="active bg-gradient-danger text-center"
                >
                  Từ "{question.text_VN}" trong tiếng anh là:
              </ListGroupItem>
                <ListGroupItem>
                  {question.answers.map((answer) => {
                    return (
                      <Button
                        key={answer.id}
                        value={answer.text}
                        style={{
                          fontWeight: "700",
                          width: "70%",
                          margin: 10,
                        }}
                        onClick={this.onNext.bind(this, answer)}
                      />
                    );
                  })}
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      );
    }
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type05);
