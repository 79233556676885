/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Transfer, Table, message } from "antd";
import difference from "lodash/difference";
import { query, execute } from "helpers/QueryHelper";

import { connect } from "react-redux";
import NotData from "components/Error/NotData";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import Notifications from "components/Notification";

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  {
    dataIndex: "id",
    title: "Mã",
    align: "center",
    width: "1%",
  },
  {
    dataIndex: "fullName",
    title: "Tên học sinh",
  },
  {
    dataIndex: "email",
    title: "Email",
  },
  {
    dataIndex: "phone",
    title: "Điện thoại",
    align: "center",
    width: "15%",
  },
  {
    dataIndex: "sex",
    title: "Giới tính",
    align: "center",
    width: "15%",
  },
  {
    dataIndex: "note",
    title: "Ghi chú",
    align: "center",
  },
];
const rightTableColumns = [
  {
    dataIndex: "id",
    title: "Mã",
    align: "center",
    width: "1%",
  },
  {
    dataIndex: "fullName",
    title: "Tên học sinh",
  },
  {
    dataIndex: "email",
    title: "Email",
  },
  {
    dataIndex: "phone",
    title: "Điện thoại",
    align: "center",
  },
  {
    dataIndex: "sex",
    title: "Giới tính",
    align: "center",
    width: "10%",
  },
  {
    dataIndex: "note",
    title: "Ghi chú",
    align: "center",
  },
];

class AddStudentsToClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // studentNotInClass: [],
      // studentInClass: [],
      targetKeys: [],
      disabled: false,
      showSearch: false,
      mockData: [],
      classId: this.props.match.params.classId,
      loggedInUserSGK: JSON.parse(localStorage.getItem("loggedInUserSGK")),
      className: "",
    };
  }

  componentDidMount = () => {
    this.getStudents();
    this.getClassName();

    // getClass(classId);
  };

  getStudents = async () => {
    const { classId } = this.state;
    const studentNotInClass = [];
    const studentInClass = [];
    await query(
      "p_SGK_TEACHER_Get_Students_Not_In_Class",
      { classId },
      "Sgk"
    ).then((users) => {
      users.forEach((item) => {
        item.sex ? (item.sex = "Nam") : (item.sex = "Nữ");
      });
      // this.setState({ studentNotInClass: users }, () => this.getStudentsInClass);
      studentNotInClass.push(users);
    });
    await query("p_SGK_TEACHER_Get_Students_In_Class", { classId }, "Sgk").then(
      (users) => {
        users.forEach((item) => {
          return item.sex ? (item.sex = "Nam") : (item.sex = "Nữ");
        });
        studentInClass.push(users);
      }
    );
    const targetKeys = studentInClass[0].map((item) => item.key);
    const mockData = studentInClass[0].concat(studentNotInClass[0]);
    this.setState({ targetKeys, mockData });
  };

  getClassName = () => {
    const { classId, loggedInUserSGK } = this.state;

    query(
      "p_SGK_TEACHER_Classes_Get_ByTeacherId",
      { TeacherId: loggedInUserSGK.userId },
      "Sgk"
    ).then((result) => {
      const indexClassId = result.findIndex((item) => item.id == classId);
      this.setState({ className: result[indexClassId]?.className });
    });
  };

  onChange = (nextTargetKeys, direction, moveKeys) => {
    const { classId } = this.state;
    if (direction === "left") {
      moveKeys.map((item) => {
        return execute(
          "p_SGK_TEACHER_Classes_RemoveStudentFromClass",
          { classId, studentId: item },
          "Sgk"
        ).then((result) => {});
      });
      Notifications("warning", "Thông báo", "Xóa học sinh khỏi lớp thành công");
    } else {
      moveKeys.map((item) => {
        return execute(
          "p_SGK_TEACHER_Classes_AddStudentToClass",
          { classId, studentId: item },
          "Sgk"
        ).then((result) => {});
      });
      Notifications("success", "Thông báo", "Thêm học sinh vào lớp thành công");
    }
    this.setState({ targetKeys: nextTargetKeys });
  };

  triggerDisable = (disabled) => {
    this.setState({ disabled });
  };

  triggerShowSearch = (showSearch) => {
    this.setState({ showSearch });
  };

  filterValue = (inputValue, item) => {
    return (
      item.id.toString().indexOf(inputValue) !== -1 ||
      item.email.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
      item.fullName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
      item.phone.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    );
  };

  // onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
  //   console.log('onSelectChange -> targetSelectedKeys', targetSelectedKeys);
  //   console.log('onSelectChange -> sourceSelectedKeys', sourceSelectedKeys);
  // };

  render() {
    const {
      disabled,
      showSearch,
      mockData,
      targetKeys,
      className,
      loggedInUserSGK,
    } = this.state;
    // console.log('render -> targetKeys', targetKeys);
    const checkRole = loggedInUserSGK.userRoles.includes("Student");
    return checkRole ? (
      <NotData />
    ) : (
      <>
        <Container style={{ backgroundColor: "#11CDEF" }} fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
                <a onClick={() => this.props.history.push("/sgk/trang-chu")}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Trang chủ
                  </h6>
                </a>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark "
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i class="fas fa-chalkboard-teacher" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem className="text-black">
                    <a
                      onClick={() =>
                        this.props.history.push("/sgk/teacher/create-class")
                      }
                    >
                      Danh sách lớp
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    aria-current="page"
                    className="active text-black"
                  >
                    Thêm học sinh
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {/* <Col className="text-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral"
                    color="default"
                    onClick={() => this.props.history.push("/sgk/trang-chu")}
                    size="sm"
                  >
                    Quay lại
                  </Button>
                </Col> */}
            </Row>
          </div>
        </Container>
        <TableTransfer
          className="mt-5"
          titles={[
            <span style={{ fontWeight: "700", fontSize: 18, color: "#34495e" }}>
              Danh sách học sinh
            </span>,
            <span style={{ fontWeight: "700", fontSize: 18, color: "#34495e" }}>
              Danh sách học sinh trong lớp {className}
            </span>,
          ]}
          dataSource={mockData}
          targetKeys={targetKeys}
          showSearch
          bordered
          onChange={this.onChange}
          filterOption={this.filterValue}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          // onSelectChange={this.onSelectChange}
        />
        {/* <Switch unCheckedChildren='disabled' checkedChildren='disabled' checked={disabled} onChange={this.triggerDisable} style={{ marginTop: 16 }} /> */}
        {/* <Switch unCheckedChildren='Mở tìm kiếm' checkedChildren='Đóng tìm kiếm' checked={showSearch} onChange={this.triggerShowSearch} style={{ marginTop: 16 }} /> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loggedInUserSGK: state.loginReducer.loggedInUserSGK,
});

export default connect(mapStateToProps)(AddStudentsToClass);
