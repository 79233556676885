import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import * as __typeText from "../text";
import { default as functions } from "components/functions";
import moment from "moment";

import queryString from "query-string";
import { Rate, Card, Row, Col } from "antd";
import { resultAudio } from "components/functions";

import * as ActionTypes from "../actions/types";
import Listen from "components/Listening";
import AMES247Loading from "components/Error/Loading";
import Recorder from "components/Recording/react_mic";
import CountdownTimer from "components/countdownTimer";
import openNotificationWithIcon from "components/Notification";
import NotData from "components/Error/NotData";

class Type07 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {},
      resultRecord: undefined,
      questions: props.questions,
      resultRecordWithAzure: undefined,
      disabledBack: true,
      disabledNext: false,
      timeStart: null,
    };


    this.questionIndex = 0;
    this.takeExamTime = this.props.takeExamTime;
    //this.recordUrlFromApi = null;
    this.refRecorder = React.createRef();
    this.refCountdownTimer = React.createRef();
  }

  UNSAFE_componentWillMount = () => {
    let questions = functions.randomTextAnswersFromAPI(this.props.questions);
    questions = functions.randomFourAnswersOneWay(questions);
    // console.log("Type07 -> UNSAFE_componentWillMount -> questions", questions)
    this.setState({
      questions,
      loading: false,
      question: questions[this.questionIndex],
      timeStart: moment().format(),
    });
  };

  onLoadImage = ({ target: img }) => {
    if (img.offsetWidth > 490) {
      this.setState({
        styleImage: {
          width: "400px",
        },
      });
    }
  };

  onRecording = () => {
    this.setState({ disabledNext: true }, () => {
      if (typeof this.props.startRecord === "function") {
        this.props.startRecord();
      }
      this.refCountdownTimer.current.startTimer();
    });
  };

  onStopRecording = (result) => {
    if (typeof this.refCountdownTimer.current.stopTimer == "function") {
      this.refCountdownTimer.current.stopTimer();
      this.setState({ resultRecord: result, disabledNext: false });
    }
  };

  onNext = (type, _) => {
    const questionParent = this.state.question;

    // check answer true or false
    this.checkAnswer(type);

    this._postAnswerToAPI(this.state.resultRecord, questionParent);

    if (this.checkCompletedOrNo()) return;

    // nextQuestion
    this.questionIndex++;
    this.moveOnToAnotherQuestion(this.questionIndex);
    // if (this.questionIndex !== 0) {
    //   this.setState({ disabledBack: false, resultRecordWithAzure: undefined });
    // }
  };

  checkAnswer = async (type) => {
    let { saveAnswers } = this.props;

    if (
      type === "NEXT" &&
      this.state.resultRecord.score > functions.satisfactoryResults
    ) {
      const payload = {
        key: this.questionIndex,
        isCorrect: true,
        score: this.state.resultRecord.score,
        type: "record",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "success",
        "Chính xác",
        "Chúc mừng bạn đã trả lời đúng"
      );
      resultAudio(1);
    } else {
      const payload = {
        key: this.questionIndex,
        isCorrect: true,
        score: this.state.resultRecord.score,
        type: "record",
      };
      saveAnswers(payload);
      openNotificationWithIcon(
        "danger",
        "Không chính xác",
        "Vui lòng kiểm tra lại kết quả"
      );
      resultAudio(2);
    }
  };
  checkCompletedOrNo = () => {
    let { questions, allProps, takeExamTime } = this.props;

    if (this.questionIndex + 1 === questions.length) {
      let { assignmentId, sessionId, classId } = allProps.match.params;
      let { type } = queryString.parse(window.location.search);

      allProps.history.push(
        `/sgk/classes/${classId}/session/${sessionId}/assignment/${assignmentId}/results?length=${questions.length}&type=${type}&takeExamtime=${takeExamTime}`
      );
      return true;
    }
    return false;
  };

  moveOnToAnotherQuestion = (ix) => {
    this.setState({
      question: this.state.questions[ix],
      timeStart: moment().format(),
    });
  };

  _postAnswerToAPI = (resultRecord, questionParent) => {
    let { allProps, loggedInUserSGK } = this.props;
    let duration = moment().diff(this.state.timeStart);
    let answerModel = {
      studentId: loggedInUserSGK.studentId,
      sessionId: allProps.match.params.sessionId,
      assignmentId: allProps.match.params.assignmentId,
      questionEntityName: allProps.results.data.questionEntityName,
      questionId: questionParent.id,
      questionGuid: "", // empty if question type is not GRAMMAR
      answerType: functions.getAnswerType(__typeText.Type07),
      notes: "",
      takeExamTime: this.takeExamTime,
      studentChoice: JSON.stringify({
        score: resultRecord.score || 0,
        wordShows: resultRecord.wordShows || [],
        recordUrl: resultRecord.recordUrl || "",
      }),
      duration,
    };
    functions
      .postAnswerToAPI(answerModel)
      .then((_) => {
        this.setState({ resultRecord: undefined });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { question, styleImage, resultRecord } = this.state;
    const { loggedInUserSGK } = this.props;
    const recordParams = question
      ? {
          questionId: question.id,
          questionText: question.text,
          studentId: loggedInUserSGK.studentId,
          takeExamTime: this.takeExamTime,
        }
      : null;
    if (this.props.questions.length === 0) {
      return <NotData />;
    }
    if (!question) {
      return <AMES247Loading />;
    } else {
      return (
        <React.Fragment>
          <Card>
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
               backgroundColor:'#EBECF1'
              }}
            >
              <Col
                className={classNames(["question-type__left"])}
                xs={24}
                sm={24}
                lg={12}
                md={12}
              >
                <p />
                <img
                  src={question.imageUrl}
                  alt="..."
                  style={styleImage}
                  onLoad={this.onLoadImage}
                  className={classNames(["question-type__image"])}
                />
                {/* <Listen
                audioURL={question.soundUrl}
                onListened={this.onListened}
                onAuto={true}
              /> */}
                <h2 style={{paddingTop: 10,fontWeight:'700',fontSize:'80'}}>
                  {question.text}
                </h2>
              </Col>
              <Col
                
                className={classNames(["question-type__info"])}
                xs={24}
                sm={24}
                lg={12}
                md={12}
              >
                <Recorder
                  ref={this.refRecorder}
                  __custom
                  __className={"question-type__recordType02"}
                  __onRecording={this.onRecording}
                  __onStopRecording={this.onStopRecording}
                  recordParams={recordParams}
                >
                  <div className={classNames(["mt-15"])}>
                    <CountdownTimer
                      seconds={120}
                      ref={this.refCountdownTimer}
                      onStopRecording={this.onStopRecording}
                    >
                      <span>Recorder in: </span>
                    </CountdownTimer>
                  </div>
                  <br />
                  {resultRecord && (
                    <div>
                      {resultRecord.wordShows.map((t, i) => (
                        <span
                          key={i}
                          style={{ color: t.color }}
                          className={classNames(["question-type__textReply"])}
                        >{`${t.word} `}</span>
                      ))}
                      <br />
                      <div className="record--result">
                        {resultRecord && (
                          <div className={""}>
                            <Listen
                              custom
                              className={
                                "record--content record--result__listen"
                              }
                              audioURL={resultRecord.recordUrl}
                            >
                              <i class="fas fa-volume-up"></i>
                            </Listen>
                          </div>
                        )}
                        {/* <Recorder
                        ref={this.refRecorder}
                        __custom
                        __icon={"redo"}
                        __className={
                          "record--content record--result__btn-reset"
                        }
                        __onRecording={this.onRecording}
                        __onStopRecording={this.onStopRecording}
                        recordParams={recordParams}
                      /> */}
                        <div
                          className={classNames([
                            "record--result__rate",
                            "record--content",
                          ])}
                        >
                          <Rate
                            allowHalf
                            disabled
                            value={functions.getStarRecord(resultRecord.score)}
                          />
                        </div>
                        <div
                          className={classNames([
                            "record--result__btn-score",
                            "record--content",
                          ])}
                        >
                          <strong>{`${parseInt(resultRecord.score)}%`}</strong>
                        </div>
                        <div
                          className={classNames([
                            "record--result__btn-next",
                            "record--content",
                          ])}
                          onClick={this.onNext.bind(this, "NEXT")}
                        >
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  )}
                </Recorder>
              </Col>
            </Row>
          </Card>
          {/* <Row className={classNames(["question-type__actions"])}>
            
          </Row> */}
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveAnswers: (payload) => {
    dispatch({ type: ActionTypes.SAVE_ANSWER, payload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Type07);
