import * as actionTypes from "./types";

export const saveAnswerAction = ({ key, isCorrect, score, type }) => ({
  type: actionTypes.SAVE_ANSWER,
  payload: { key, isCorrect, score, type}
});

export const resetAnswerAction = () => ({
  type: actionTypes.RESET_SAVE_ANSWER,
});
