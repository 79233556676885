import { ames247Axios } from "configs/api";

export function* getSessionsMyames({ classId: courseId, studentId }) {
  const res = yield ames247Axios.get(`/api/GetClassSession/${courseId}/${studentId}`);
  if (res.data.message === "OK") {
    res.data.results.className = res.data.className;
    return res.data.results;
  };

  if(res.data.message === "Error"){
    return "Error"
  }
}
